import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import _ from "lodash";
import moment from "moment";
import { GET_DEPARTMENT_EVENTS, THROW_ERROR, GET_DEPARTMENT, GET_ALL_DEPARTMENTS } from 'utils/types.utils';
import Input from "common_components/ui/input_box/input_box.ui";
import Button from "common_components/ui/button/button.ui";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import SelectBox from "common_components/ui/select_box/select_box.ui"
import UploadBox from "common_components/ui/upload_box/upload_box.ui";
import { uploadMedia } from "models/advertisment.model";
import { departmentNavData, departmentEventsTableHeading, departmentEventsBody, csiUserSuggessionData, departmentUserData } from "helper/constants.helper";
import { getAllEvents, addEvents, editEvents, getEventsById, deleteEvents } from "models/event.model";
import { getAllDepartment, getAllDepartments } from "models/department.model";
import TextArea from "common_components/ui/text_area/text_area.ui";
import Spinner from "common_components/ui/spinner/spinner.ui";
import { toast } from "react-toastify";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import Table from "../../components/table/table.component";
import { uploadMediaFiles, useSetState } from "../../utils/common.utils";
import "screens/department/department_events.screen.scss";
import PlusIcon from "../../assets/icons/Plus_White.svg";
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";

export default function DepartmentEvents(props) {
  const events = useSelector((state: any) => state.department.events);
  const departments = useSelector((state: any) => state.department.allDepartments);
  const departmentUser = localStorage.getItem('department');
  const [state, setState] = useSetState(
    {
      selectedData: "department_events",
      edit: false,
      openModal: false,
      checkedList: [],
      isChecked: false,
      confirmModal: "",
      user_name: "",
      limit: 20,
      page: 1,
      search: "",
      events: [],
      id: "",
      department: "",
      title: "",
      description: "",
      fromDate: "",
      toDate: "",
      url: "",
      thumbnail: "",
      departmentData: [],
      loading: true,
      fileUploading: false,
      uploaded: false
    });
  const dispatch = useDispatch();
  const handleGetAllDepartmentEvents = async ({ limit, page, search }) => {
    try {
      const data = {
        limit,
        page,
        search: {
          department: search
        },
        type: "departments",
        admin: true,
        department: departmentUser ? [departmentUser] : ""
      }
      const event: any = await getAllEvents(data);
      const doc = event.data.docs.map((docs) => {
        docs.department_name = docs.departments.name;
        docs.author = docs.author.user_name;
        docs.date = moment(docs.from_date).format('DD-MM-YYYY')
        return docs;
      })
      event.data.docs = doc;
      setState({ events: doc });
      dispatch({
        type: GET_DEPARTMENT_EVENTS,
        payload: event.data
      })
      setState({ loading: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get department events");
    }
  }
  interface DepartmentArray {
    label: string,
    value: string,
  }
  const deptData: DepartmentArray[] = [];
  const dept = departments && departments.length !== 0
    && departments.map((department) => {
      deptData.push({ label: department.name, value: department._id });
      return "";
    });

    const getDepartments = async () => {
      try {
        const res: any = await getAllDepartments()
        dispatch({
          type: GET_ALL_DEPARTMENTS,
          payload: res.data
        })
      } catch (err:any) {
        props.throwError(err.data ? err.data.desc : "Failed to Get Departments");
      }
    }

  useEffect(() => {
    handleGetAllDepartmentEvents(state);
    getDepartments();
  }, [state.search])

  const handleAddEvents = async () => {
    try {
      const data = {
        department: departmentUser ? departmentUser : state.department,
        name: state.title,
        description: state.description,
        url: state.url,
        thumbnail: state.thumbnail,
        type: "departments",
        from_date: new Date(state.fromDate),
        to_date: new Date(state.toDate)
      };
      if (state.thumbnail === "" || state.toDate === '' || state.fromDate === '') {
        toast.error(state.thumbnail ? "Please add date" : "Please upload thumbnail")
      } else {
        const event = await addEvents(data);
        await handleGetAllDepartmentEvents({
          page: 1,
          limit: state.page * state.limit,
          search: state.search
        });
        setState({ openModal: false })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to add department news");
    }
  }

  const handleUpdateDepartmentEvents = async () => {
    try {
      const data = {
        user_type: "department",
        department: departmentUser ? departmentUser : state.department,
        name: state.title,
        description: state.description,
        url: state.url,
        from_date: new Date(state.fromDate),
        to_date: new Date(state.toDate),
        thumbnail: state.thumbnail
      };
      if (state.thumbnail === "" || state.fromDate === '' || state.toDate === '') {
        toast.error(state.thumbnail ? "Please add Date" : "Please upload thumbnail")
      } else {
        const event = await editEvents(state.id, data);
        await handleGetAllDepartmentEvents({
          page: 1,
          limit: state.page * state.limit,
          search: state.search
        });
        setState({ openModal: false })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to update department news");
    }
  }

  const handleOnDeleteEvent = async () => {
    try {
      const event = await deleteEvents(state.id);
      await handleGetAllDepartmentEvents({
        page: 1,
          limit: state.page * state.limit,
          search: state.search
      });
      setState({ confirmModal: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete department news");
    }
  }

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = departmentEventsBody.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleEditEvents = async (id) => {
    try {
      setState({ openModal: true, edit: true, id });
      const event: any = await getEventsById(id);
      const date = new Date(event.data.from_date);
      if (event !== undefined && event.data !== undefined) {
        setState({
          department: event.data.department,
          title: event.data.name,
          description: event.data.description,
          url: event.data.url,
          thumbnail: event.data.thumbnail,
          fromDate: moment(event.data.from_date).format("YYYY-MM-DDTHH:MM:SS"),
          toDate: moment(event.data.to_date).format("YYYY-MM-DDTHH:MM:SS"),
          uploaded: event.data.thumbnail !== "" ? true : false
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to edit department news");
    }
  }

  const handleUploadThumbnail = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true })
          const thumbnail: any = await uploadMediaFiles(e);
          if (thumbnail) {
            setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif',)
        }
      } else {
        setState({ fileUploading: true })
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Thumbnail");
    }
  }

  const handleDeleteEvents = () => {
    // Api call
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const loadMore = async ({ limit, search, page }) => {
    try {
      const data = {
        limit,
        page,
        search: {
          department: search
        },
        type: "departments",
        department: departmentUser ? [departmentUser] : ""
      }
      const event: any = await getAllEvents(data);
      const doc = event.data.docs.map((docs) => {
        docs.department_name = docs.departments.name;
        docs.author = docs.author.user_name;
        docs.from_date = moment(docs.from_date).format('DD-MM-YYYY')
        return docs;
      })
      event.data.docs = doc;
      event.data.docs = [...departments.docs, ...event.data.docs]
      setState({ events: doc });
      dispatch({
        type: GET_DEPARTMENT_EVENTS,
        payload: event.data
      })
      setState({ loading: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get department events");
    }
  }
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 })
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    })
  }
  const searchChange = async (e) => {
    setState({ search: e.target.value, page: 1 })
    // try {
    //   const data = {
    //     limit: state.limit,
    //     page: state.page,
    //     search: {
    //       department: e.target.value
    //     },
    //     type: "departments",
    //     department: departmentUser ? [departmentUser] : ""
    //   }
    //   const event: any = await getAllEvents(data);
    //   const doc = event.data.docs.map((docs) => {
    //     docs.department_name = docs.departments.name;
    //     docs.author = docs.author.user_name;
    //     return docs;
    //   })
    //   event.data.docs = doc;
    //   setState({ events: doc });
    //   dispatch({
    //     type: GET_DEPARTMENT_EVENTS,
    //     payload: event.data
    //   })
    // } catch (err:any) {
    //   props.throwError(err.data ? err.data.desc : "Failed to load Books");
    // }
  }

  const handleConfirm = (id) => {
    setState({ confirmModal: true, id })
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit Event",
      onClick: handleEditEvents,
      icon: Edit
    },
    {
      type: "secondary",
      text: "Delete Event",
      onClick: handleConfirm,
      icon: Delete
    }
  ]

  const handleOnChangeInSelection = (selectedOption) => {
    setState({ department: selectedOption.value });
  }

  return (
    <div className="department_events_screen">
      <div className="department_events_container">
        <div className="department_events_navbar_wrapper">
          <CustomNavBar
            data={departmentNavData}
            onTapClick={handleChangeClick}
            selectedItem={state.selectedData}
            buttonText="Add Event"
            buttonType="primary"
            buttonIcon={PlusIcon}
            searchText="Search..."
            searchType="secondary"
            suggession={csiUserSuggessionData}
            buttonOnClick={() => {
 setState(
              {
                openModal: true,
                uploaded: false,
                edit: false,
                id: "",
                department: "",
                title: "",
                description: "",
                date: "",
                url: "",
                thumbnail: "",
                fromDate: "",
                toDate: ""
              });
}}
            button
            search
            onSearchChange={searchChange}
          />
        </div>
        <div className="department_events_table_wrapper">
          {
            state.loading ? <Spinner />
              : <Table
                  header={departmentEventsTableHeading}
                  body={events ? events.docs : []}
                  isChecked={state.isChecked}
                  checkAll={checkAll}
                  checkOne={checkOne}
                  checkedData={state.checkedList}
                  loadMore={loadMoreInitial}
                  hasMore={events && events.hasNextPage ? events.hasNextPage : false}
                  actionButton={actionArray}
                  error="No Department Events are Found"
              />
          }
        </div>
        <div>
          <Modal
            open={state.openModal}
            onClose={() => setState({ openModal: false })}
            center
            classNames={{
              overlay: 'customOverlay', modal: 'department_events_modal', closeButton: 'close_button'
            }}
            showCloseIcon
          >
            <div className="department_events_modal_title_wrapper">
              <div className="department_events_modal_title h4">{state.edit !== true ? "Add Events" : "Edit Events"}</div>
            </div>
            <div className="department_events_modal_line_wrapper"> </div>
            <div className="department_events_modal_input_wrapper">
              <div className="department_events_modal_input">
                <div className="department_events_input">
                  {departmentUser ? null : <SelectBox data={deptData ? deptData : []} name="Select Department" selectedItem={state.department} onChange={handleOnChangeInSelection} />}
                </div>
                <div className="department_events_input">
                  <Input name="Title" onChange={handleOnChange} inputKey="title" value={state.title} />
                </div>
                <div className="department_events_input">
                  <TextArea name="Description" onChange={handleOnChange} inputKey="description" value={state.description} />
                </div>
                <div className="department_events_input">
                  <Input name="URL" onChange={handleOnChange} inputKey="url" value={state.url} />
                </div>
              </div>
              <div className="department_events_modal_input">
                <div className="department_events_input_from_date">
                  <Input name="From" type="datetime-local" onChange={handleOnChange} inputKey="fromDate" value={state.fromDate} />
                </div>
                <div className="department_events_input_to_date">
                  <Input name="To" type="datetime-local" onChange={handleOnChange} inputKey="toDate" value={state.toDate} />
                </div>
                <div className="department_events_thumbnail_wrapper">Thumbnail</div>
                <div className="department-events_modal_uploadbox">
                  <UploadBox name="thumbnail" className="uploadThumbnail" loading={state.fileUploading} uploaded={state.uploaded} accept="image/x-png,image/gif,image/jpeg" onChange={handleUploadThumbnail} text="Drag and Drop" />
                </div>
              </div>
            </div>
            <div className="department_events_button_wrapper">
              <div className="department_events_button">
                <Button text={state.edit ? "Update Events" : "Add Event"} type="primary" onClick={state.edit ? handleUpdateDepartmentEvents : handleAddEvents} />
              </div>
            </div>
          </Modal>
        </div>
        <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete the Department News ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteEvent} />
      </div>
    </div>
  )
}
