import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SynodEventCard from 'components/csi_synod_event_card/csi_synod_event_card.component';
import Footer from 'components/footer/footer.component';
import Navbar from 'components/navbar/navbar.component';
import Header from 'components/header/header.component';
import moment from 'moment';
import { getAllEvents } from 'models/public.model';
import Pagination from 'react-js-pagination';
import { GET_DIOCESE_EVENTS } from 'utils/types.utils';
import { getType, throwError, useSetState } from 'utils/common.utils';
import 'screens/user_screens/event.screen.scss';

export default function Event(props) {
  const { match } = props;
  const events = useSelector((state: any) => state.diocese.events);
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    selected_filter: '',
    limit: 20,
    page: 1,
    search: '',
    activated: 'all',
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };
  const handleGetAllEvents = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        type: match.params.type,
        [match.params.type.slice(0, -1)]: match.params.id
          ? [match.params.id]
          : '',
      };
      const res: any = await getAllEvents(data);
      getType(match.params.type, res.data.docs);
      dispatch({
        type: GET_DIOCESE_EVENTS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : 'Failed to get events');
    }
  };

  useEffect(() => {
    handleGetAllEvents();
  }, [state.page]);

  return (
    <div className="event_screen">
      <div className="event_container">
        <div className="event_wrapper">
          <div className="event_header_wrapper">
            <Header />
          </div>
          <div className="event_navbar_wrapper">
            <Navbar {...props} />
          </div>
          <div className="event_title_wrapper">
            <div className="event_title h1">CSI Synod Events</div>
            <div className="event_line_wrapper"> </div>
          </div>
          <div className="event_content_wrapper">
            <div className="event_content">
              {events && events.docs && events.docs.map((m) => (
                  <div className="event_content_event_card">
                    <SynodEventCard
                      date={`${moment(m.from_date).format('DD')}`}
                      toDate={`${moment(m.to_date).format('DD')}`}
                      month={`${moment(m.from_date).format('MMM')}`}
                      endMonth={`${moment(m.to_date).format('MMM')}`}
                      endTime={`${moment(
                        m.to_date,
                      ).format('hh a')}`}
                      time={`${moment(m.from_date).format('hh a')}`}
                      eventName={m.name}
                      eventDescription={m.description}
                      name={
                        match.params.type === 'departments'
                          ? m.department_name
                          : m.diocese_name
                      }
                      thumbnail={m.thumbnail}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="pagination_wrapper">
            <div className="pagination">
              <Pagination
                activePage={state.activePage}
                activeLinkClass="link_active"
                itemsCountPerPage={state.limit}
                totalItemsCount={
                  events && events.totalDocs ? events.totalDocs : 20
                }
                pageRangeDisplayed={
                 6
                }
                prevPageText="<"
                nextPageText=">"
                itemClassFirst="firstElement"
                hideFirstLastPages
                onChange={(e) => handlePageChange(e)}
              />
            </div>
          </div>
          <div className="event_footer_wrapper">
            <div className="event_footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
