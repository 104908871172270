import React from "react";
import "components/officer_card/officer_card.component.scss";

interface OfficerCardProps {
    name: string,
    profession?: string,
    profilePicture?: string,
    location?: string,
    mobile?: string,
    phoneFax?: string,
    email?: string
    types?: string,
    className?: string
}

export default function OfficerCard(props: OfficerCardProps) {
    const {
        name,
        profession,
        location,
        mobile,
        profilePicture,
        phoneFax,
        email,
        className,
        types
          } = props;
        // const phoneNumber = mobile?.split(",")
        const phoneNumber = mobile?.replace(",", " |")
        const mail = email?.replace(",", " ")
    return (
        <div className="officer_card_container">
            <div className="officer_card_wrapper">
                <div className="officer_image_wrapper">
                    <img src={profilePicture} alt="personal_image" className={`officer_image_wrapper ${className}`} />
                </div>
                {types === "sec_staff"
                && <div className="officer_personal_information_wrapper sec_staff_wrapper">
                <div className="officer_name h4">{name}</div>
                   </div>}
                {types === 'officers'
                 && <div className="officer_personal_information_wrapper">
                    <div className="officer_name h4">{name}</div>
                    {profession && <div className="officers_proffession h6">{profession}</div>}
                    {location && <div className="officers_location h6">{location}</div>}
                    {mobile && <div className="officers_mobile h6">Mobile: {phoneNumber}</div>}
                    {/* {mobile && phoneNumber?.map((number) => {
                        return <div className="officers_mobile h6">Mobile: {number}</div>
                    })} */}
                    {phoneFax && <div className="officers_fax h6">Phone/Fax: {phoneFax}</div>}
                    {email && <div className="officers_email h6">Email: {mail}</div>}
                    </div>}
            </div>
        </div>
    )
}

OfficerCard.defaultProps = {
    profession: "",
    types: '',
    location: "",
    profilePicture: "",
    mobile: "",
    phoneFax: "",
    email: "",
    className: ""
}
