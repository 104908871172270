import React, { useEffect } from 'react';
import StatementCard from 'components/statement_card/statement_card.component';
import Footer from 'components/footer/footer.component';
import Header from 'components/header/header.component';
import Button from 'components/button/button.component';
import 'screens/user_screens/statement.screen.scss';
import Navbar from 'components/navbar/navbar.component';
import { getAllDetails } from "models/public.model";
import Pagination from 'react-js-pagination';
import { GET_STATEMENTS } from 'utils/types.utils';
import { throwError, useSetState } from 'utils/common.utils';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

export default function Statement(props) {
  const dispatch = useDispatch();
  const statements = useSelector(
    (state: any) => state.announcements.statements,
  );
  const [state, setState] = useSetState({
    search: '',
    loading: true,
    page: 1,
    limit: 4,
    title: '',
    description: '',
    url: '',
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };
  const handleGetAllDetails = async () => {
    try {
      const data = {
        page: state.page,
        search: '',
        type: 'statement',
        limit: state.limit,
      };
      const res: any = await getAllDetails(data);
      const details = res.data.docs.map((document) => {
        document.fileName = document.pdf[0].split('/').pop();
        document.date = moment(document.date).format('DD-MM-YYYY');
        return document;
      });
      res.data.docs = details;
      dispatch({
        type: GET_STATEMENTS,
        payload: res.data,
      });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Statement details")
    }
  };

  useEffect(() => {
    handleGetAllDetails();
  }, [state.page]);
  return (
    <div className="statement_screen">
      <div className="statement_container">
        <div className="statement_wrapper">
          <div className="statement_header_wrapper">
            <Header />
          </div>
          <div className="statement_navbar_wrapper">
            <Navbar {...props} />
          </div>
          <div className="statement_title_wrapper">
            <div className="statement_title h2">Statements</div>
            <div className="statement_line_wrapper"> </div>
          </div>
          <div className="statement_content_wrapper">
            <div className="statement_content">
              {statements && statements.docs.map((statement) => {
                  return (
                    <div className="statement_content_event_card">
                      <StatementCard
                        title={statement.title}
                        date={statement.date}
                        description={statement.description.length > 150 ? `${statement.description.substring(0, 235)}` : statement.description}
                        pdfName={statement.pdf}
                      />
                    </div>
                  );
                })}
            </div>
            <div className="pagination_wrapper">
              <div className="pagination">
                <Pagination
                  activePage={state.activePage}
                  activeLinkClass="link_active"
                  itemsCountPerPage={state.limit}
                  totalItemsCount={
                    statements && statements.totalDocs
                      ? statements.totalDocs
                      : 10
                  }
                  pageRangeDisplayed={6}
                  firstPageText="<<"
                  lastPageText=">>"
                  prevPageText="<"
                  nextPageText=">"
                  itemClassFirst="firstElement"
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="statement_footer_wrapper">
            <div className="statement_footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
