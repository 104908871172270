import React, { useEffect } from 'react';
import './header.component.scss';
import Newscard2 from 'components/news_card_2/news_card_2.component';
import Button from 'components/button/button.component';
import { globalSearch } from 'models/public.model';
import { throwError, useSetState } from 'utils/common.utils';
import Input from 'common_components/ui/input_box/input_box.ui';
import Inactive from 'assets/icons/Inactive_black.svg';
import moment from 'moment';
import InactivePink from 'assets/icons/Inactive_Pink.svg';
import Logo from '../../assets/images/CSI_Logo.png';
import FacebookFill from '../../assets/icons/Group 1.svg';
import Instagram from '../../assets/icons/instagram 2.svg';
import YoutubeFill from '../../assets/icons/Group 2.svg';
import SearchFill from '../../assets/icons/Tools/Violet/Search.svg';
import HamberFill from '../../assets/icons/Tools/Violet/Ham_Menu.svg';
import Whatsapp from '../../assets/icons/Group 4.svg';

export default function Header(props) {
  const [state, setState] = useSetState({
    search: false,
    globalSearch: '',
    globalNews: [],
    page: 1,
    limit: 4,
    hamber: false,
  });

  const searchNews = async () => {
    try {
      const data = {
        page: state.page,
        limit: state.limit,
        search: state.globalSearch,
      };
        const res: any = await globalSearch(data);
        const docs = res.data.docs.map((doc) => {
          if (doc.department) {
            doc.department_name = doc.department.name
          } else if (doc.diocese) {
            doc.diocese_name = doc.diocese.name
          }
          return doc
        })
        setState({ globalNews: res.data.docs });
    } catch (error:any) {
      throwError(
        'Failed to search news',
      );
    }
  };

  useEffect(() => {
    searchNews();
  }, [state.globalSearch, state.page]);
  return (
    <div className="header_component">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="icon_container">
            <img
              src={FacebookFill}
              alt="social_icons"
              className="header_social_icon"
              onClick={() =>
                window.open(
                  'https://facebook.com/groups/195181353850157/',
                  '_blank',
                )}
            />
          </div>
          <div className="icon_container">
            <img
              src={Instagram}
              alt="social_icons"
              className="header_social_icon_instagram"
              onClick={() =>
                window.open(
                  'https://www.instagram.com/csi_synod_official/',
                  '_blank',
                )}
            />
          </div>
          <div id="icon_container" className="icon_container">
            <img
              src={YoutubeFill}
              alt="social_icons"
              className="header_social_icon"
              onClick={() =>
                window.open(
                  'https://www.youtube.com/@csilife',
                  '_blank',
                )}
            />
          </div>
          <div id="icon_container" className="icon_container">
            <img
              src={Whatsapp}
              alt="social_icons"
              className="header_social_icon whatsapp_icon"
              onClick={() =>
                window.open(
                  'https://wa.me/+919840577404',
                  '_blank',
                )}
            />
          </div>
        </div>
        <div className="header_wrapper">
          <div className="csi_logo_container">
            <div className="csi_logo">
              <a href="https://csi1947.com/home">
                <img src={Logo} alt="csi_logo_home" className="csi_logo_home" />
              </a>
            </div>
            <div className="csi_title h3">
              {' '}
              <a href="https://csi1947.com/home">Church of South India - SYNOD</a>
            </div>
          </div>
        </div>
        <div className="header_wrapper">
          <div className="icon_container header_action_icons">
            {state.search ? (
              <img
                id="header_search_icon"
                src={InactivePink}
                alt="social_icons"
                className="header_close_icon"
                onClick={() => setState({ search: false, hamber: false })}
              />
            ) : (
              <img
                id="header_search_icon"
                src={SearchFill}
                alt="social_icons"
                className="header_search_icon"
                onClick={() => setState({ search: true, hamber: false })}
              />
            )}
          </div>
          <div className="icon_container">
            <img
              id="header_hamber_icon"
              className="header_hamber_icon"
              src={state.hamber ? InactivePink : HamberFill}
              alt="social_icons"
              onClick={() => setState({ hamber: !state.hamber, search: false })}
            />
          </div>
        </div>
      </div>
      {state.search && (
        <div id="search_input_wrapper" className="search_input_wrapper">
        <div className="search_input_content">
            <div className="input_search_icon_wrapper">
              <img src={SearchFill} alt="input_search_icon" />
              {/* <Button
                text="Search"
                onClick={(e) => {
                  setState({ globalSearch: e.target.value });
                }}
                className="input_search_button"
              /> */}
            </div>
            <input
              name=""
              type="text"
              className="search_input_field"
              value={state.globalSearch}
              onChange={(e) => setState({ globalSearch: e.target.value })}
            />
        </div>
          {/* <div className="search_input_button_wrapper">
            <Button
              text="Close"
              onClick={() => setState({ search: false })}
              className="search_button"
              icon={Inactive}
            />
          </div> */}
          <div className="global_news_wrapper">
            {state.globalNews.length > 0 ? (
              state.globalNews.map((news) => {
                return (
                  <div className="global_news_card">

                  <Newscard2
                    title={news.title}
                    description={moment(news.created_at).format(
                      'dddd, MMM D, YYYY',
                      )}
                    name={news.department_name ? news.department_name : news.diocese_name}
                    link={news.thumbnail}
                    onClick={() => { window.open(`/news_screen/${news.department_name ? 'departments' : 'dioceses'}/${news._id}`) }}
                    type="news_card"
                    text={news.heading}
                  />
                  </div>
                );
              })
            ) : (
              <div className="h3">No news found</div>
            )}
          </div>
          <div className="global_search_button_wrapper">
            <Button
              text="Load more"
              onClick={() => setState({ page: state.page + 1 })}
              className="global_search_button search_button"
            />
          </div>
        </div>
      )}
      {state.hamber && (
        <div id="header_menu_wrapper" className="header_menu_wrapper">
          <div className="header_menu_content">
            <div className="header_menu_content_name h4">
              <a href="/about/contact_us">Get in Touch</a>
            </div>
            <div className="header_menu_content_name h4">
              <a href="/about/contact_us">Find Us</a>
            </div>
            <div className="header_menu_content_name h4">
              <a href="/about/contact_us#queries">Have any Queries?</a>
            </div>
          </div>
          {/* <div className="header_menu_button_wrapper">
            <img
              src={Inactive}
              alt="close_icon"
              className="header_menu_close_icon"
              onClick={() => setState({ hamber: false })}
            />
          </div> */}
        </div>
      )}
    </div>
  );
}
