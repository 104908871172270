import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from 'components/footer/footer.component';
import Header from 'components/header/header.component';
import Navbar from 'components/navbar/navbar.component';
import Pagination from 'react-js-pagination';
import 'screens/user_screens/csi_video.screen.scss';
import NewsCard3 from 'components/news_card_3/news_card_3.component';
import { getAllVideo } from "models/public.model";
import { GET_VIDEOS } from 'utils/types.utils';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import moment from 'moment';
import { throwError, useSetState } from '../../utils/common.utils';

export default function CsiVideos(props) {
  const videoData = useSelector((state: any) => state.video);
  const dispatch = useDispatch();

  const [state, setState] = useSetState({
    title: '',
    description: '',
    youtube_url: '',
    video_url: '',
    thumbnail: '',
    loading: true,
    page: 1,
    limit: 21,
    search: '',
    activated: 'all',
    filterPage: 1,
    id: '',
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };
  const getVideos = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        activated: state.activated,
      };
      const res: any = await getAllVideo(data);
      const videoDocs = res.data.docs.map((video) => {
        if (video.youtube_url.includes('=')) {
          video.url = video.youtube_url.split('=').pop()
        } else if (video.youtube_url.includes('/')) {
          video.url = video.youtube_url.split('/').pop()
        } else {
          video.url = video.youtube_url
        }
        return video
      })
      res.data.docs = videoDocs
      dispatch({
        type: GET_VIDEOS,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get CSI Videos")
    }
  };
  useEffect(() => {
    getVideos();
  }, [state.page]);
  return (
    <div className="csi_video_screen">
      <div className="csi_video_container">
        <div className="csi_video_user_wrapper">
          <div className="csi_video_header_wrapper">
            <Header />
          </div>
          <div className="csi_video_navbar_wrapper">
          <Navbar {...props} />
          </div>
          <div className="csi_video_body_wrapper">
            <div className="csi_video_body">
              <div className="csi_video_title_wrapper">
                <div className="csi_video_title h2">CSI Videos</div>
                <div className="csi_video_line_wrapper"> </div>
              </div>
              <div className="csi_video_content_wrapper">
                {state.loading ? (
                  <Spinner />
                ) : (
                  videoData.docs.length > 0 ? videoData.docs.map((video) => {
                    return (
                      <div className="csi_video_content">
                        <NewsCard3
                          link={video.youtube_url ? video.youtube_url : video.video_url}
                          title={video.title}
                          text={moment(video.created_at).format('dddd, MMM D, YYYY')}
                          description=""
                          onClick={() => {}}
                          type={video.url ? 'youtube' : 'video'}
                          embedId={video.url ? video.url : video.video_url}
                          className="csi_video_image"
                        />
                      </div>
                    );
                  })
                  : "No CSI videos found")}
              </div>
            </div>
          </div>
    <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            videoData && videoData.totalDocs ? videoData.totalDocs : 21
          }
          pageRangeDisplayed={6}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
        />
      </div>
    </div>
          <div className="csi_video_footer_wrapper">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
