import React, { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import 'screens/workship_resources/csi_lectionary.screen.scss';
import PlusIcon from 'assets/icons/Plus_White.svg';
import Button from 'common_components/ui/button/button.ui';
import _ from 'lodash';
import Table from 'components/table/table.component';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Input from 'common_components/ui/input_box/input_box.ui';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import EditIcon from 'assets/icons/Edit.svg';
import DeleteIcon from 'assets/icons/Delete.svg';
import InactiveIcon from 'assets/icons/Inactive_Pink.svg';
import {
  workshipAndResourcesNavData,
  lectionaryTableHeading,
  csiUserSuggessionData,
} from 'helper/constants.helper';
import SearchBar from 'common_components/ui/search_bar/search_bar.ui';
import { useDispatch, useSelector } from 'react-redux';
import { GET_LECTIONARY_LIST } from 'utils/types.utils';
import { toast } from 'react-toastify';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import CustomNavBar from '../../components/custom_nav/custom_nav.component';
import { getYear, uploadMediaFiles, useSetState } from '../../utils/common.utils';
import {
  getAllPublication,
  getPublicationById,
  addPublication,
  editPublication,
  deletePublication,
  uploadMedia,
} from '../../models/publication.model';

export default function CSILectionary(props) {
  const { throwError } = props;
  const dispatch = useDispatch();
  const lectionaryList = useSelector(
    (state: any) => state.worship_resources.csi_lectionary_list,
  );
  const [state, setState] = useSetState({
    selectedData: 'csi_lectionary',
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    title: '',
    file: '',
    thumbnail: '',
    pdf: [],
    resourceName: '',
    year: '',
    loading: true,
    uploaded: false,
    fileUploading: false,
    pdfUploaded: false,
    pdfLoading: false,
    page: 1,
    user_id: '',
    limit: 20,
    search: '',
    type: 'csi_lectionary',
  });
  const fetchLectionaryList = async ({ page, limit, search }) => {
    try {
      const data = {
        page,
        limit,
        search,
        type: state.type,
      };
      const res: any = await getAllPublication(data);
      dispatch({
        type: GET_LECTIONARY_LIST,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : 'Unable to fetch details');
      setState({
        loading: true,
      });
    }
  };

  useEffect(() => {
    fetchLectionaryList(state);
  }, [state.search]);

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleAddLectionary = async () => {
    try {
      const data = {
        thumbnail: state.thumbnail,
        pdf: state.pdf,
        title: state.title,
        type: state.type,
        year: Number(state.year),
        resource_name: state.resourceName,
      };
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        await addPublication(data);
        fetchLectionaryList({
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
        });
        setState({
          openModal: false,
          edit: false
        })
      }
    } catch (err:any) {
      throwError(err.data ? err.data.desc : 'Unable to add details');
    }
  };

  const handleEditLectionary = async (id) => {
    setState({ openModal: true, edit: true });
    try {
      const res: any = await getPublicationById(id);
      setState({
        title: res.data.title,
        user_id: res.data._id,
        edit: true,
        resourceName: res.data.resource_name,
        pdf: res.data.pdf,
        year: res.data.year,
        thumbnail: res.data.thumbnail,
        uploaded: res.data.thumbnail ? true : false,
        pdfUploaded: res.data.pdf ? true : false,
      });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : 'Unable to get details');
    }
  };
  const handleUpdateLectionary = async () => {
    try {
      const data = {
        title: state.title,
        type: state.type,
        pdf: state.pdf,
        year: Number(state.year),
        resource_name: state.uploaded_file,
        thumbnail: state.thumbnail,
      };
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        await editPublication(state.user_id, data);
        fetchLectionaryList({
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
        });
        setState({
          openModal: false,
          edit: false
        })
      }
    } catch (err:any) {
      throwError(err.data ? err.data.desc : 'Unable to update details');
    }
  };
  const handleDeleteLectionary = async () => {
    setState({ confirmModal: true });
    try {
      await deletePublication(state.user_id);
      fetchLectionaryList({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
      });
      setState({
        confirmModal: false,
      });
      toast.error('Details deleted');
    } catch (err:any) {
      throwError(err.data ? err.data.desc : 'Unable to delete details');
    }
  };

  const searchChange = async (e) => {
    setState({
      search: e.target.value,
      page: 1
    })
  };
  const handleUploadPDF = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/pdf)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ pdfLoading: true });
          const data = new FormData();
          const ext = e.dataTransfer.files[0].name.split('.').pop();
          const filename = e.dataTransfer.files[0].name
            .split('.')[0]
            .replace(/ /g, '');
          const newFileName = `${filename}${Date.now()}.${ext}`;
          const newFile = new File([e.dataTransfer.files[0]], newFileName);
          data.append('file', newFile);
          const pdf: any = await uploadMedia(data);
          if (pdf) {
            setState({ pdf: pdf.url });
            const fileName = pdf.url.split('/')[4];
            setState({
              resourceName: fileName,
              pdfUploaded: true,
              pdfLoading: false,
            });
          }
        } else {
          props.throwError('File is not supported! Please Upload Pdf');
        }
      } else {
        const data = new FormData();
        setState({ pdfLoading: true });
        const ext = e.target.files[0].name.split('.').pop();
        const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '');
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.target.files[0]], newFileName);
        data.append('file', newFile);
        const pdf: any = await uploadMedia(data);
        if (pdf) {
          setState({ pdf: pdf.url });
          const fileName = pdf.url.split('/')[4];
          setState({
            resourceName: fileName,
            pdfUploaded: true,
            pdfLoading: false,
          });
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };

  const handleUploadThumbnail = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const thumbnail: any = await uploadMediaFiles(e);
          if (thumbnail) {
            setState({
              thumbnail: thumbnail.url,
              uploaded: true,
              fileUploading: false,
            });
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif');
        }
      } else {
        setState({ fileUploading: true });
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({
            thumbnail: thumbnail.url,
            uploaded: true,
            fileUploading: false,
          });
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };
  const handleOnDelete = (id) => {
    setState({ confirmModal: true, user_id: id });
  };

  const loadMore = async () => {
    try {
      const data = {
        page: state.page + 1,
        limit: state.limit,
        search: state.search,
        type: state.type,
      };
      const res: any = await getAllPublication(data);
      res.data.docs = [...lectionaryList.docs, ...res.data.docs]
      dispatch({
        type: GET_LECTIONARY_LIST,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to get users');
    }
  };

  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore();
  };
  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEditLectionary,
      icon: EditIcon,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleOnDelete,
      icon: DeleteIcon,
    },
  ];

  return (
    <div className="lectionary_screen">
      <div className="lectionary_container">
        <div className="lectionary_wrapper">
          <div className="lectionary_header_wrapper">
          <div className="lectionary_title_wrapper">
          <div className="officers_name_wrapper h2">
              Worship Resources
          </div>
          </div>
          <div className="lectionary_action_wrapper">
            <div className="lectionary_search_box_wrapper">
              <SearchBar
                suggession=""
                type="secondary"
                suggessionData={csiUserSuggessionData}
                onChange={searchChange}
              />
            </div>
            <div className="lectionary_button_wrapper">
              <Button
                type="primary"
                text="Add CSI Lectionary"
                onClick={() =>
                  setState({
                    openModal: true,
                    edit: false,
                    title: '',
                    thumbnail: '',
                    pdfUploaded: false,
                    pdf: '',
                    year: '',
                    fileUploading: false,
                    uploaded: false,
                    pdfLoading: false,
                  })}
                icon={PlusIcon}
              />
            </div>
          </div>
          </div>
          <div className="lectionary_custom_navbar_wrapper">
            <CustomNavBar
              data={workshipAndResourcesNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          {state.loading ? (
            <Spinner />
          ) : (
            <>
              <div className="lectionary_table-wrapper">
                <Table
                  header={lectionaryTableHeading}
                  body={lectionaryList ? lectionaryList.docs : []}
                  isChecked={state.isChecked}
                  checkAll={checkAll}
                  checkOne={checkOne}
                  checkedData={state.checkedList}
                  loadMore={loadMoreInitial}
                  hasMore={lectionaryList.hasNextPage}
                  actionButton={actionArray}
                  error="No details found"
                />
              </div>
              <div className="lectionary_modal_container">
                <Modal
                  open={state.openModal}
                  onClose={() => setState({ openModal: false })}
                  center
                  classNames={{
                    overlay: 'customOverlay',
                    modal: 'lectionary_customModal',
                    closeButton: 'close_button',
                  }}
                  showCloseIcon
                >
                  <div className="lectionary_modal_title_wrapper">
                    <div className="lectionary_modal_title h4">
                      {state.edit !== true
                        ? 'Add Lectionary'
                        : 'Edit Lectionary'}
                    </div>
                  </div>
                  <div className="lectionary_modal_line_wrapper"> </div>
                  <div className="lectionary_modal_input_wrapper">
                    <div className="lectionary_modal_input">
                      <Input
                        name="Title"
                        onChange={handleOnChange}
                        type="text"
                        inputKey="title"
                        value={state.title}
                      />
                    </div>
                    <div className="lectionary_modal_input">
                      <SelectBox
                        data={getYear(1947)}
                        name=""
                        selectedItem={state.year}
                        onChange={(selectedOption) => {
                          setState({ year: selectedOption.value })
                        }}
                      />
                    </div>
                    <div className="lectionary_modal_input">
                      <div className="upload_box_wrapper">
                        Image Thumbnail
                        <div className="upload_box">
                          <UploadBox
                            className="thumbnail"
                            loading={state.fileUploading}
                            uploaded={state.uploaded}
                            name="image_thumbnail"
                            accept="image/x-png,image/gif,image/jpeg"
                            text="Drag and Drop or"
                            onChange={handleUploadThumbnail}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="lectionary_modal_input">
                      <div className="upload_box_wrapper">
                        Upload PDF
                        <div className="upload_box">
                          <UploadBox
                            className="pdf"
                            name="upload_pdf"
                            loading={state.pdfLoading}
                            uploaded={state.pdfUploaded}
                            accept=".pdf"
                            text="Drag and Drop or"
                            onChange={handleUploadPDF}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="lectionary_button_wrapper">
                    <div className="lectionary_button">
                      <Button
                        text={state.edit ? 'Update' : 'Add'}
                        type="primary"
                        onClick={
                          state.edit
                            ? handleUpdateLectionary
                            : handleAddLectionary
                        }
                      />
                    </div>
                  </div>
                </Modal>
              </div>
              <ConfirmModal
                openModal={state.confirmModal}
                description="Are you sure want to delete this ?"
                closeModal={() => setState({ confirmModal: false })}
                deleteConfirm={handleDeleteLectionary}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
