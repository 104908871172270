import React, { useEffect } from 'react';
import 'screens/diocese/banner_images.screen.scss';
import { bannerImageData, personnelsBody } from 'helper/constants.helper';
import { Modal } from 'react-responsive-modal';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import CustomNavBar from 'components/custom_nav/custom_nav.component';
import { uploadMediaFiles, useSetState } from 'utils/common.utils';
import { editDiocese, getDioceseById, removeMedia } from 'models/diocese.model';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import Table from 'components/table/table.component';
import _ from 'lodash';
import Button from 'common_components/ui/button/button.ui';
import { useDispatch } from 'react-redux';
import { THROW_ERROR } from 'utils/types.utils';
import { uploadMedia } from 'models/news.model';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import moment from 'moment';
import ActionButton from '../../common_components/ui/action_button/action_button.ui';
import PlusIcon from '../../assets/icons/Plus_White.svg';
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";
import Arrowleft from '../../assets/icons/Arrow.svg';

export default function BannerImages(props) {
  const dispatch = useDispatch();
  const { match } = props;
  const [state, setState] = useSetState({
    selectedData: 'banner_images',
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    loading: true,
    imageUploaded: false,
    imageUrl: '',
    newBanner: '',
    confirmModal: '',
    name: '',
    email: '',
    uploaded: false,
    fileUploading: false,
    mobile: '',
    id: '',
    gallery: [],
    dioceseName: '',
    uploaded_banner_image: '',
    removeBanner: '',
    thumbnail: '',
    banner: [],
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const suggessionData = [
    {
      icon: PlusIcon,
      name: 'sunil',
    },
    {
      icon: PlusIcon,
      name: 'prakash',
    },
    {
      icon: PlusIcon,
      name: 'Mukunthan',
    },
  ];
  const handleGetDiocese = async () => {
    try {
      const res: any = await getDioceseById(match.params.id);
      setState({
        dioceseName: res.data.name,
        banner: res.data.banner,
        loading: false,
      });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get details")
    }
  };
  useEffect(() => {
    handleGetDiocese();
  }, []);
  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = personnelsBody.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };
  const addbanner = async () => {
    try {
      const data = {
        banner: [...state.banner, ...state.newBanner],
      };
      const res = await editDiocese(match.params.id, data);
      handleGetDiocese();
      setState({ openModal: false });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to add banner image")
    }
    setState({ openModal: false });
  };
  const handleUpdateBanner = async (banner) => {
    try {
      const data = {
        banner,
      };
      const res = await editDiocese(match.params.id, data);
      handleGetDiocese();
      setState({ openModal: false });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
    setState({ openModal: false });
  };
  const handleDeleteBanner = async () => {
    try {
    const banner = state.banner.filter((gal, index) => index !== state.id);
    const removeImage = state.banner.filter((gal, index) => index === state.id);
    setState({ banner, removeBanner: removeImage[0].url });
    const data = {
      resource_url: state.removeBanner
    }
    setState({ confirmModal: false });
    handleUpdateBanner(banner);
    const res: any = await removeMedia(data)
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete banner image")
    }
  };
  const loadMore = () => {
    // Api Call
  };

  const searchChange = () => { };

  const handleConfirm = (index) => {
    setState({ confirmModal: true, id: index });
  };
  const handleUploadThumbnail = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const banner: any = await uploadMediaFiles(e);
          const obj = [
            {
              url: banner.url,
              date: moment(new Date()).format('DD-MM-YYYY HH:MM A'),
            },
          ];
          if (banner) {
            setState({
              newBanner: obj,
              imageUrl: banner.url,
              uploaded: true,
              fileUploading: false,
              imageUploaded: true,
            });
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif');
        }
      } else {
        setState({ fileUploading: true });
        const banner: any = await uploadMediaFiles(e);
        const obj = [
          {
            url: banner.url,
            date: moment(new Date()).format('DD-MM-YYYY HH:MM A'),
          },
        ];
        if (banner) {
          setState({
            newBanner: obj,
            imageUrl: banner.url,
            uploaded: true,
            fileUploading: false,
            imageUploaded: true,
          });
        }
      }
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Unable to Upload Banner image',
      );
    }
  };

  const actionArray = [
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleConfirm,
      icon: Delete,
    },
  ];

  const dioceseData = [
    {
      navName: 'Address',
      navValue: 'address',
      navUrl: `/dioceses/diocese_information/address/${match.params.id}`,
    },
    {
      navName: 'Banner Images',
      navValue: 'banner_images',
      navUrl: `/dioceses/diocese_information/banner_images/${match.params.id}`,
    },
    {
      navName: 'Gallery',
      navValue: 'gallery',
      navUrl: `/dioceses/diocese_information/gallery/${match.params.id}`,
    },
    {
      navName: 'Personnels',
      navValue: 'personnels',
      navUrl: `/dioceses/diocese_information/personnels/${match.params.id}`,
    },
    {
      navName: 'Description',
      navValue: 'description',
      navUrl: `/dioceses/diocese_information/description/${match.params.id}`,
    },
    {
      navName: 'Ministerial Roll',
      navValue: 'ministerial_roll',
      navUrl: `/dioceses/diocese_information/ministerial_roll/${match.params.id}`,
    },
  ];

  return (
    <div className="banner_image_screen">
      <div className="banner_image_container">
        <div className="banner_image_wrapper">
          <div className="banner_image_header_wrapper">
            <div className="banner_image_navigate_icon_wrapper">
              <div className="banner_image_navigate_icon">
                <img
                  className="navigate_icon"
                  alt=""
                  src={Arrowleft}
                  onClick={() =>
                    props.history.push('/dioceses/diocese_information')}
                />
              </div>
            </div>
            <div className="banner_image_header_name_container">
              <div className="banner_image_header_name h3">
                {state.dioceseName}
              </div>
              <div className="banner_image_header_button_wrapper">
                <Button
                  text="Add Banner"
                  type="primary"
                  icon={PlusIcon}
                  onClick={() =>
                    setState({
                      openModal: true,
                      edit: false,
                      fileUploading: false,
                      uploaded: false,
                      imageUploaded: false,
                      imageUrl: '',
                    })}
                />
              </div>
            </div>
          </div>
          <div className="banner_image_navbar_wrapper">
            <CustomNavBar
              data={dioceseData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add Personnels"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={suggessionData}
              buttonOnClick={() => setState({ openModal: true, edit: false })}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="banner_image_table_wrapper">
            {state.loading ? (
              <Spinner />
            ) : (
                <Table
                  header={bannerImageData}
                  body={state.banner ? state.banner : []}
                  isChecked={state.isChecked}
                  checkAll={checkAll}
                  checkOne={checkOne}
                  checkedData={state.checkedList}
                  loadMore={loadMore}
                  actionButton={actionArray}
                  error="No banner images found"
                />
              )}
          </div>
          <div className="">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={
                state.uploaded_banner_image
                  ? { overlay: 'customOverlay', modal: 'banner_no_image_modal' }
                  : { overlay: 'customOverlay', modal: 'banner_image_modal' }
              }
              showCloseIcon
            >
              <div className="banner_image_modal_title_wrapper">
                <div className="banner_image_modal_title h4">
                  {state.edit !== true ? 'Add Banner' : 'Edit Banner'}
                </div>
              </div>
              <div className="banner_image_modal_line_wrapper"> </div>
              <div className="banner_image_modal_input_wrapper">
                <div className="banner_outline_modal_input">
                  <div className="upload_box_wrapper">
                    Banner image
                    <div className="upload_box">
                      <UploadBox
                        className="image"
                        uploaded={state.uploaded}
                        loading={state.fileUploading}
                        name="banner"
                        accept="image/x-png,image/gif,image/jpeg"
                        text="Drag and Drop or"
                        onChange={handleUploadThumbnail}
                      />
                    </div>
                    {state.imageUploaded ? (
                      <div className="uploaded_banner_image">
                        <img
                          className="banner_image"
                          src={state.imageUrl}
                          alt=""
                        />
                      </div>
                    ) : (
                        ''
                      )}
                  </div>
                </div>
              </div>
              <div className="banner_image_button_wrapper">
                <div className="banner_image_button banner_button_wrapper">
                  <Button text="Add" type="primary" onClick={addbanner} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete Banner ?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleDeleteBanner}
          />
        </div>
      </div>
    </div>
  );
}
