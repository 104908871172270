import React, { useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './partners.screen.scss';
import '../../index.scss';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import Button from 'components/button/button.component';
import ResourcesCard from 'components/resources_card/resources_card';
import { getYear, throwError, useSetState } from 'utils/common.utils';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import { csiMonthSelectionData } from 'helper/constants.helper';
import Navbar from 'components/navbar/navbar.component';
import { GET_PARTNER_AND_NETWORK_DETAILS } from 'utils/types.utils';
import PartnersCard from 'components/partners_card/partners_card';
import { getAllPartnerAndNetworkDetails } from "models/public.model";
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import Eye from '../../assets/icons/Eye.svg'

export default function PartnersScreen(props) {
  const dispatch = useDispatch();
  const partnerDetails = useSelector(
    (state: any) => state.partner_and_network.partner_network,
  );
  const [state, setState] = useSetState(
    {
      limit: 21,
      page: 1,
      search: "",
      id: "",
      department: "",
      loading: true,
    });
    const handlePageChange = (pageNumber) => {
      setState({ activePage: pageNumber, page: pageNumber });
    };
  const handleGetAllPartnerAndNetworkDetails = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: '',
        category: 'partner',
      };
      const res: any = await getAllPartnerAndNetworkDetails(data);
      const url = res.data.docs.map((docs) => {
        if (docs.url.includes('http')) {
        docs.link = docs.url
        } else {
          docs.link = `https://${docs.url}`
        }
        return docs;
      })
      res.data.docs = url
      dispatch({
        type: GET_PARTNER_AND_NETWORK_DETAILS,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (error:any) {
      throwError(error.data ? error.data.desc : "Failed to get Partner details")
    }
  };
  useEffect(() => {
    handleGetAllPartnerAndNetworkDetails();
  }, [state.page]);
  return (
    <div className="screen">
      <div className="header_wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="navbar_wrapper">
          <Navbar {...props} />
        </div>
      </div>
      <div className="partners_screen_container">
        <div className="partners_screen_header_wrapper">
          <div className="partners_screen_header h1">
            Partners
            <div className="header_line" />
          </div>
        </div>
        <div className="partners_screen_body_wrapper">
          {partnerDetails && partnerDetails.docs.map((partners) => {
              return (
                <div className="card_wrapper">
                  <PartnersCard
                    title={partners.name}
                    url={partners.image}
                    type="publications"
                    hoverText="Visit"
                    icon={Eye}
                    pdfLink={partners.link}
                    onClick={() => {}}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            partnerDetails && partnerDetails.totalDocs ? partnerDetails.totalDocs : 20
          }
          pageRangeDisplayed={6}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
        />
      </div>
      </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
