import React, { useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './worship_resource.scss';
import '../../index.scss';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import Button from 'components/button/button.component';
import { getYear, throwError, useSetState } from 'utils/common.utils';
import Calendar from 'react-awesome-calendar';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Navbar from 'components/navbar/navbar.component';
import { getPrayerCalendarList } from "models/public.model";
import moment from 'moment';
import { GET_PRAYER_CALENDAR_LIST } from 'utils/types.utils';
import { useDispatch, useSelector } from 'react-redux';

export default function WorshipResource(props) {
  const dispatch = useDispatch()
  const prayerList = useSelector((state: any) => state.worship_resources.prayer_calendar_list)
  const events = prayerList && prayerList.map((prayer) => {
    return ({
      id: prayer._id,
      color: '#B5316A',
      from: prayer && prayer.date,
      to: prayer && prayer.date,
      title: prayer && prayer.description
    })
  })
  const [state, setState] = useSetState(
    {
      selectedData: "prayer_calendar",
      edit: false,
      openModal: false,
      checkedList: [],
      isChecked: false,
      confirmModal: false,
      loading: true,
      date: "",
      description: "",
      limit: 1,
      search: '',
      page: 1,
      user_id: ''
    });

  const fetchPrayerDetails = async () => {
    try {
      const data = {
        page: state.page,
        limit: state.limit,
        search: state.search,
      }
      const res: any = await getPrayerCalendarList(data);
      const userList = res.data.map((user) => {
        user.dates = moment(user.date).format('DD-MM-YYYY');
        return user
      });
      res.data.docs = userList
      dispatch({
        type: GET_PRAYER_CALENDAR_LIST,
        payload: res.data
      })
      setState({
        loading: false
      })
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Prayer calendar details")
    }
  }

  useEffect(() => {
    fetchPrayerDetails()
  }, [])
  return (
    <div className="worship_screen">
      <div className="header_wrapper">
      <div className="header">
        <Header />
      </div>
        <div className="navbar_wrapper" style={{ position: 'sticky', top: 0, zIndex: 9999 }}>
          <Navbar {...props} />
        </div>
      </div>
      <div className="worship_screen_container">
        <div className="worship_header_wrapper">
          <div className="worship_heading h2">Worship Resources</div>
          <div className="worship_header_line" />
        </div>
        <div className="worship_screen_title_wrapper">
          <div className="worship_screen_title h1">
            <div className="title_links_wrapper">
              <div className="link h3">
                <NavLink
                  to="/resources/worship_resource/prayer_calendar"
                  className="title_link"
                  activeClassName="selected"
                >
                  Prayer Calendar
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink
                  to="/resources/worship_resource/csi_lectionary"
                  className="title_link"
                >
                  CSI Lectionary
                </NavLink>
              </div>
              <div className="link h3">
                <NavLink
                  to="/resources/worship_resource/sermon_outlines"
                  className="title_link"
                >
                  Sermon Outlines
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="worship_resource_body_wrapper">
          <Calendar
            events={events}
          />
        </div>
      </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
