import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import { useDispatch, useSelector } from 'react-redux';
import { useSetState, uploadMediaFiles, uploadMultipleFiles, imageMultiple } from 'utils/common.utils';
import Input from "common_components/ui/input_box/input_box.ui";
import Button from 'common_components/ui/button/button.ui';
import { GET_DEPARTMENT_EVENTS, THROW_ERROR, GET_DEPARTMENT, GET_ALL_DEPARTMENTS } from 'utils/types.utils';
import { getAllDepartment, getAllDepartments } from "models/department.model";
import { addNews, editNews, getNews, uploadMedia, removeMedia } from "models/news.model";
import Select from 'common_components/ui/select_box/select_box.ui';
import AddNewsComponent from 'components/add_news/add_news.component';
import './add_news.screen.scss';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import { toast } from 'react-toastify';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import Arrowleft from '../../assets/icons/Arrow.svg';

const AddNews = (props) => {
  const { match } = props;
  const imgRef = useRef(null);
  const [upImg, setUpImg] = useState();
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", height: 50, width: 100, x: 0, y: 0, maxHeight: 50 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  const dispatch = useDispatch();
  const departments = useSelector((state: any) => state.department.allDepartments);
  const departmentUser = localStorage.getItem('department');
  const [state, setState] = useSetState({
    text: '',
    selectedData: "",
    thumbnail: "",
    gallery: [],
    department: "",
    limit: 20,
    date: '',
    page: 1,
    pdf: [],
    uploadFile: '',
    fileUploading: false,
    heading: '',
    uploaded: false,
    galleries: [],
    title: ""
  });
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader: any = new FileReader();
      setState({ uploadFile: e.target.files[0].name })
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getNewsById = async () => {
    try {
      const news: any = await getNews(match.params.id);
      if (news !== undefined) {
        setState({
          text: news.data.description,
          thumbnail: news.data.thumbnail,
          gallery: news.data.gallery,
          department: news.data.department,
          title: news.data.title,
          heading: news.data.heading,
          pdf: [...state.pdf, news.data.pdf],
          date: news.data.date,
          uploaded: news.data.pdf ? true : false
        })
      }
    } catch (err:any) {
      console.log(err)
      props.throwError(err.data ? err.data.desc : "Failed to get department news");
    }
  }

  interface DepartmentArray {
    label: string,
    value: string,
  }
  const deptData: DepartmentArray[] = [];
  const dept = departments && departments.length !== 0
    && departments.map((department) => {
      deptData.push({ label: department.name, value: department._id });
      return "";
    });

  const handleGetAllDepartment = async () => {
    try {
      const departmentData = [];
      const department: any = await getAllDepartments();
      if (department) {
        dispatch({
          type: GET_ALL_DEPARTMENTS,
          payload: department.data
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get department");
    }
  }

  useEffect(() => {
    if (match.params.id) {
      getNewsById()
    }
    handleGetAllDepartment();
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
const image: any = imgRef.current;
const canvas: any = previewCanvasRef.current;
const newCrop: any = completedCrop;

const scaleX = image.naturalWidth / image.width;
const scaleY = image.naturalHeight / image.height;
const ctx = canvas.getContext("2d");
const pixelRatio = window.devicePixelRatio;

canvas.width = newCrop.width * pixelRatio;
canvas.height = newCrop.height * pixelRatio;

ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
ctx.imageSmoothingQuality = "high";

ctx.drawImage(
image,
crop.x * scaleX,
crop.y * scaleY,
crop.width * scaleX,
crop.height * scaleY,
0,
0,
crop.width,
crop.height
);
  }, [completedCrop]);

  const handleInputChange = (value) => {
    setState({ text: value });
  }

  const handleDepartmentChange = (selectedOption) => {
    setState({ department: selectedOption.value });
  }
  const handleUploadPDF = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/pdf)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ pdfLoading: true });
          const data = new FormData();
          const ext = e.dataTransfer.files[0].name.split('.').pop();
          const filename = e.dataTransfer.files[0].name
            .split('.')[0]
            .replace(/ /g, '');
          const newFileName = `${filename}${Date.now()}.${ext}`;
          const newFile = new File([e.dataTransfer.files[0]], newFileName);
          data.append('file', newFile);
          const pdf: any = await uploadMedia(data);
          if (pdf) {
            setState({ pdf: [...state.pdf, pdf.url] });
            const fileName = pdf.url.split('/')[4];
            setState({
              resourceName: fileName,
              uploaded: true,
              loadded: false,
            });
          }
        } else {
          props.throwError('File is not supported! Please Upload Pdf');
        }
      } else {
        const data = new FormData();
        setState({ pdfLoading: true });
        const ext = e.target.files[0].name.split('.').pop();
        const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '');
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.target.files[0]], newFileName);
        data.append('file', newFile);
        const pdf: any = await uploadMedia(data);
        if (pdf) {
          setState({ pdf: [...state.pdf, pdf.url] });
          const fileName = pdf.url.split('/')[4];
          setState({
            resourceName: fileName,
            uploaded: true,
            loading: false,
          });
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    }
  };
  const uploadThumbnail = () => {
    setState({ cropModal: true, thumbnail: '' })
    // try {
    //   if (e.dataTransfer) {
    //     const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
    //     if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
    //       setState({ fileUploading: true })
    //       const thumbnail: any = await uploadMediaFiles(e);
    //       if (thumbnail) {
    //         setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
    //       }
    //     } else {
    //       props.throwError('Upload Image Format Jpg, Png, Gif');
    //     }
    //   } else {
    //     setState({ fileUploading: true })
    //     const thumbnail: any = await uploadMediaFiles(e);
    //     if (thumbnail) {
    //       setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
    //     }
    //   }
    // } catch (err:any) {
    //   props.throwError(err.data ? err.data.desc : "Failed to Upload Thumbnail");
    // }
  }

  const uploadGallery = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ galleryUploading: true });
          const res: any = await uploadMultipleFiles(e);
          const datas = [...state.gallery, ...res.data];
          setState({ gallery: datas });
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif');
        }
      } else {
        setState({ galleryUploading: true });
        const gallery: any = [];
        const res: any = await uploadMultipleFiles(e);
        const datas = [...state.gallery, ...res.data];
        setState({ gallery: datas });
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload gallery');
    }
  }

  const handleAddNews = async () => {
    try {
      const data = {
        title: state.title,
        description: state.text,
        department: departmentUser ? departmentUser : state.department,
        thumbnail: state.thumbnail,
        gallery: state.gallery,
        heading: state.heading,
        date: new Date(state.date),
        type: "departments",
        pdf: state.pdf
      }
      if (state.thumbnail === "") {
        toast.error("Please add thumbnail")
      } else {
        const result = await addNews(data);
        props.history.push('/departments')
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to add departmentNews");
    }
  }

  const handleUpdateNews = async () => {
    try {
      const data = {
        title: state.title,
        description: state.text,
        department: departmentUser ? departmentUser : state.department,
        thumbnail: state.thumbnail,
        heading: state.heading,
        gallery: state.gallery,
        date: new Date(state.date),
        pdf: state.pdf
      }
      if (state.thumbnail === "") {
        toast.error("Please add thumbnail")
      } else {
        const result = await editNews(match.params.id, data)
        props.history.push('/departments')
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to update department news");
    }
  }

  const removeThumbnail = async () => {
    try {
      const data = {
        resource_url: state.thumbnail,
      };
      await removeMedia(data);
      setState({ thumbnail: '' });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to remove gallery image',
      );
    }
  }
  const generateDownload = async (canvas, img) => {
    // if (!crop || !canvas) {
    //   return;
    // }
    canvas.toBlob(
      async (blob) => {
        const file: any = new File([blob], state.uploadFile, { type: 'image/jpeg' })
        const data = new FormData();
        const ext = file.name.split('.').pop();
        const filename = file.name.split('.')[0].replace(/ /g, '')
        const newFileName = `${filename}.${ext}`;
        data.append('file', file, newFileName);
      const image: any = await uploadMedia(data);
      setState({ thumbnail: image.url })
      },
      "image/jpeg",
      1
      );
      setState({ cropModal: false })
  }
  const removeGallery = async (id) => {
    try {
      const galleries = state.gallery.splice(id, 1);
      const data = { resource_url: galleries[0] };
      const removeGalleryImage = await removeMedia(data);
      setState({ gallery: state.gallery });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to remove gallery image");
    }
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  }

  return (
    <div className="add_news_screen">
      <div className="add_news_content">
        <div className="add_news_head">
        <div className="department_view_navigate_icon_wrapper">
            <div className="department_view_navigate_icon">
              <img
                className="navigate_icon"
                alt=""
                src={Arrowleft}
                onClick={() => props.history.push('/departments/department_news')}
              />
            </div>
        </div>
          <div className="add_news_head_text h3">{match.params.id ? "Edit News" : "Add News"}</div>
          <div className="add_news_btn_container">
            <div className="add_news_select">
              {departmentUser ? null : <Select selectedItem={state.department} name="Select Department" data={deptData} onChange={handleDepartmentChange} />}
            </div>
            <div className="add_news_btn">
              <Button type="primary" text={match.params.id ? "Update" : "Add"} onClick={match.params.id ? handleUpdateNews : handleAddNews} />
            </div>
          </div>
        </div>
        <div className="news_content">
          <div className="news_content_date_wrapper">
          <Input name="Date" onChange={handleOnChange} type="date" inputKey="date" value={moment(state.date).format("YYYY-MM-DD")} />
          </div>
          <div className="news_content_title_wrapper">
          <TextArea name="Title" onChange={handleOnChange} inputKey="title" value={state.title} maxLength={90} />
          </div>
          <div className="news_content_heading_wrapper">
          <TextArea name="Sub-heading" onChange={handleOnChange} inputKey="heading" value={state.heading} maxLength={130} />
          </div>
          <AddNewsComponent
            text={state.text}
            thumbnail={state.thumbnail}
            gallery={state.gallery}
            textAreaOnchange={handleInputChange}
            uploadThumbnail={uploadThumbnail}
            uploadGallery={uploadGallery}
            removeThumbnail={removeThumbnail}
            loading={state.fileUploading}
            uploaded={state.uploaded}
            removeGallery={removeGallery}
            pdfUpload={handleUploadPDF}
            type="news"
          />
        <Modal
          open={state.cropModal}
          onClose={() => setState({ cropModal: false })}
          center
          classNames={{
            overlay: 'customOverlay',
            modal: 'modal_container_crop',
            closeButton: 'closeButton',
          }}
          showCloseIcon
        >
          <div className="crop_wrapper">
          <div>
        <input type="file" multiple accept="image/x-png,image/gif,image/jpeg" onChange={onSelectFile} />
          </div>
          <div className="react_image_crop">

          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
          </div>
          <canvas
            ref={previewCanvasRef}
            className="canvas_crop"
          //   style={{
          //   width: '100%',
          //   height: '100%'
          // }}
          />
          <div className="crop_button_wrapper">

          <Button
            type="primary"
            text="Add thumbnail"
            className="crop_btn"
            onClick={() =>
              generateDownload(previewCanvasRef.current, completedCrop)}
          />
          </div>
          </div>
        </Modal>
        </div>
      </div>
    </div>
  )
}

export default AddNews;
