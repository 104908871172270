import React, { useRef, useEffect } from 'react';
import './campaign_list.screen.scss';
import '../../index.scss';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import NewsCard2 from 'components/news_card_2/news_card_2.component';
import PaginationComponent from 'components/pagination/pagination.component';
import { getCampaignDetails } from "models/public.model";
import { GET_CAMPAIGNS } from 'utils/types.utils';
import Pagination from 'react-js-pagination';
import { throwError, useSetState } from 'utils/common.utils';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Navbar from 'components/navbar/navbar.component';
import Spinner from 'common_components/ui/spinner/spinner.ui';

export default function CampaignListScreen(props) {
  const dispatch = useDispatch();
  const campaignDetails = useSelector((state: any) => state.campaigns);
  const [state, setState] = useSetState({
    limit: 20,
    search: '',
    loading: true,
    page: 1,
    activated: 'all',
    activePage: 1,
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };

  const handleGetAllCampaignDetails = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: '',
        activated: true,
      };
      const res: any = await getCampaignDetails(data);
      const details = res.data.docs.map((doc) => {
        doc.description = doc.description.replace(/(<([^>]+)>)/gi, '');
        doc.date = moment(doc.created_at).format('dddd DD MMMM YYYY')
        return doc
      });
      res.data.docs = details
      dispatch({
        type: GET_CAMPAIGNS,
        payload: res.data,
      });
      setState({ loading: false })
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Campaign list")
    }
  };

  useEffect(() => {
    handleGetAllCampaignDetails();
  }, [state.page]);
  return (
    <div className="screen">
      <div className="header_wrapper">
      <div className="header">
        <Header />
      </div>
        <div className="navbar_wrapper">
          <Navbar {...props} />
        </div>
      </div>
      <div className="campaign_list_screen_container">
        <div className="campaign_list_screen_header_wrapper">
          <div className="campaign_list_screen_header h1">
            Campaigns
            <div className="header_line" />
          </div>
        </div>
        <div className="campaign_list_screen_body_wrapper">
          {state.loading ? <Spinner /> : campaignDetails.docs.length > 0
          ? campaignDetails && campaignDetails.docs.map((campaign) => {
            return (
          <div className="campaign_card_wrapper">
            <NewsCard2
              reverse={false}
              type="event"
              text={campaign.description}
              title={campaign.title}
              description={campaign.date}
              onClick={() => { props.history.push(`/campaign_screen/${campaign._id}`) }}
              link={campaign.thumbnail}
            />
          </div>
            )
          }) : "No campaings found"}
        </div>
      </div>
    <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            campaignDetails && campaignDetails.totalDocs ? campaignDetails.totalDocs : 10
          }
          pageRangeDisplayed={6}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
        />
      </div>
    </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
