import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import 'react-responsive-modal/styles.css'
import { Modal } from "react-responsive-modal";
import moment from "moment";
import Spinner from "common_components/ui/spinner/spinner.ui";
import SelectBox from "common_components/ui/select_box/select_box.ui";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import { getAllAdvertisment, deleteAdvertisment, addAdvertisement, uploadMedia, getAdvertisementById, editAdvertisement } from "models/advertisment.model";
import Button from "common_components/ui/button/button.ui";
import _ from "lodash";
import { GET_ADVERTISEMENT, THROW_ERROR } from "utils/types.utils";
import { ad } from "interfaces/common.interface";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import UploadBox from "common_components/ui/upload_box/upload_box.ui";
import Table from "components/table/table.component";
import PlusIcon from "assets/icons/Plus_White.svg";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import Active from "assets/icons/Tick_White.svg";
import Inactive from "assets/icons/Inactive_Pink.svg";
import InactiveIcon from "assets/icons/Inactive_black.svg";
import Input from "common_components/ui/input_box/input_box.ui";
import { advertismentTableHeading, csiUserSuggessionData, advertisementSelectBoxData, advertisementOrder } from "helper/constants.helper";
import "screens/advertisement/advertisement.screen.scss";
import { uploadMediaFiles, useSetState } from "../../utils/common.utils";

export default function Advertisement(props) {
  const advertisementData = useSelector((state: any) => state.advertisement)
  const [state, setState] = useSetState({
    edit: false,
    openModal: false,
    active: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    title: "",
    url: "",
    order: "",
    advertisement: [],
    id: "",
    limit: 20,
    page: 1,
    search: "",
    thumbnail: "",
    banner: "",
    uploaded: false,
    bannerUploaded: false,
    fileUploading: false,
    bannerUploading: false,
    activated: "all"
  });
  const dispatch = useDispatch();
  const handleGetAllAdvertisment = async ({ page, limit, search, activated }) => {
    try {
      const data = {
        limit,
        page,
        search,
        activated: state.activated
      }
      const advertisement: any = await getAllAdvertisment(data);
      if (advertisement) {
        setState({ advertisement: advertisement.data.docs });
        const doc = advertisement.data.docs.map((docs) => {
          docs.author = docs.created_by.user_name;
          return docs;
        });
        advertisement.data.docs = doc;
        dispatch({
          type: GET_ADVERTISEMENT,
          payload: advertisement.data
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get advertisments");
    }
  }

  const loadMore = async () => {
    try {
      setState({ page: state.page + 1 });
      const initial = {
        limit: state.limit,
        page: state.page + 1,
        search: state.search,
        activated: state.activated
      };
      const advertisement: any = await getAllAdvertisment(initial);
      advertisement.data.docs = [...advertisementData.docs
        ? advertisementData.docs : [], ...advertisement.data.docs];
      dispatch({
        type: GET_ADVERTISEMENT,
        payload: advertisement.data
      })
    } catch (err:any) {
      props.throwError('Data loaded is failed')
    }
  }

  useEffect(() => {
    handleGetAllAdvertisment(state);
  }, []);

  useEffect(() => {
    handleGetAllAdvertisment(state);
  }, [state.activated, state.search]);

  const handleUpdateAdvertisement = async () => {
    try {
      const data = {
        title: state.title,
        url: state.url,
        type: state.order,
        thumbnail: state.thumbnail,
        banner_image: state.banner
      }
      const advertisement: any = await editAdvertisement(state.id, data);
      await handleGetAllAdvertisment(
        {
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
          activated: state.activated
        });
      setState({ openModal: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to update advertisments");
    }
  }

  const handleAddAdvertisment = async () => {
    try {
      const data = {
        title: state.title,
        description: state.description,
        url: state.url,
        type: state.order,
        thumbnail: state.thumbnail,
        banner_image: state.banner
      }
      const advertisement = await addAdvertisement(data);
      await handleGetAllAdvertisment(
        {
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
          activated: state.activated
        });
      setState({ openModal: false });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to add advertisments");
    }
  }

  const handleEditAdvertisement = async (id) => {
    try {
      setState({ openModal: true, edit: true, id });
      const advertisement: any = await getAdvertisementById(id);
      if (advertisement.data !== undefined) {
        setState({
          title: advertisement.data.title,
          url: advertisement.data.url,
          order: advertisement.data.type,
          thumbnail: advertisement.data.thumbnail,
          banner: advertisement.data.banner_image
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get advertisments");
    }
  }

  const handleOnDeleteAdvertisement = async () => {
    try {
      const advertisement = await deleteAdvertisment(state.id);
      await handleGetAllAdvertisment(
        {
          page: 1,
          limit: state.limit * state.page,
          search: state.search,
          activated: state.activated
        }
      );
      setState({ confirmModal: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete advertisments");
    }
  }

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  }

  const handleDeleteAdvertisment = (id) => {
    setState({ confirmModal: true, id })
  }

  const searchChange = () => { }

  const handleMakeActiveAndInactive = async (id) => {
    try {
      const advertisement: any = await getAdvertisementById(id);
      const data = {
        activated: !advertisement.data.activated
      }
      const add: any = await editAdvertisement(id, data);
      await handleGetAllAdvertisment(
        {
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
          activated: state.activated
        });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get advertisments");
    }
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditAdvertisement,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteAdvertisment,
      icon: DeleteIcon
    },
  ];

  const handleUploadThumbnail = async (e) => {
    try {
      if (e.dataTransfer) {
        setState({ fileUploading: true })
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
        }
      } else {
        setState({ fileUploading: true })
        const thumbnail: any = await uploadMediaFiles(e);
        if (thumbnail) {
          setState({ thumbnail: thumbnail.url, uploaded: true, fileUploading: false })
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Thumbnail");
    }
  }

  const handleOnChangeInSearch = async (e) => {
    setState({ search: e.target.value, page: 1 });
  }

  const handleOnChangeInSelectBox = (selectedOption) => {
    setState({ order: selectedOption.value, page: 1 });
  };

  const handleUploadBanner = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ bannerUploading: true })
          const banner: any = await uploadMediaFiles(e);
          if (banner) {
            setState({ banner: banner.url, bannerUploaded: true, bannerUploading: false })
          }
        } else {
          props.throwError("File is not supported! Please Upload Pdf");
        }
      } else {
        setState({ bannerUploading: true });
        const banner: any = await uploadMediaFiles(e);
        if (banner) {
          setState({ banner: banner.url, bannerUploaded: true, bannerUploading: false })
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Banner");
    }
  }

  const handleOnChangeInSelection = async (selectedOptions) => {
    setState({ activated: selectedOptions.value, page: 1 });
  }

  return (
    <div className="advertisement_screen">
      <div className="advertisment_container">
        <div className="advertisment_wrapper">
          <div className="advertisment_header_wrapper">
          <div className="advertisement_title_wrapper">
          <div className="officers_name_wrapper h2">
             Advertisements
          </div>
          </div>
          <div className="advertisement_action_wrapper">
            <div className="advertisement_search_bar_wrapper">
              <SearchBar placeholder="Search" suggession="" suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
            </div>
            <div className="advertisment_select_box_wrapper">
              <SelectBox data={advertisementSelectBoxData} name="" selectedItem="" onChange={handleOnChangeInSelection} />
            </div>
            <div className="advertisement_button_wrapper">
              <Button type="primary" text="Add AD" icon={PlusIcon} onClick={() => setState({ openModal: true, edit: false, title: "", url: "", order: "", uploaded: false, fileUploading: false, bannerUploaded: false, bannerUploading: false })} />
            </div>
          </div>
          </div>
          <div className="advertisment_table_wrapper">
            {
              state.loading ? <Spinner />
                : <Table
                    header={advertismentTableHeading}
                    body={advertisementData && advertisementData.docs ? advertisementData.docs : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    checkedData={state.checkedList}
                    loadMore={loadMore}
                    actionButton={actionArray}
                    active
                    onClick={handleMakeActiveAndInactive}
                    hasMore={advertisementData && advertisementData.hasNextPage ? true : false}
                    error="No advertisement is found"
                />
            }
          </div>
          <div className="advertisement_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'advertisement_customModal', closeButton: 'close_button'
              }}
              showCloseIcon
            >
              <div className="advertisement_modal_header_wrapper">
                <div className="advertisement_modal_title_wrapper">
                  <div className="advertisement_modal_title h4">{state.edit !== true ? "Add Advertisment" : "Edit Advertisment"}</div>
                  <div className="advertisement_modal_icon_wrapper">
                    <img className="advertisement_modal_icon" src={InactiveIcon} alt="close_icon" onClick={() => setState({ openModal: false })} />
                  </div>
                </div>
                <div className="advertisement_modal_line_wrapper"> </div>
              </div>
              <div className="advertisement_modal_input_wrapper">
                <div className="advertisement_modal_input">
                  <Input name="Title" onChange={handleOnChange} inputKey="title" value={state.title} />
                </div>
                <div className="advertisement_modal_input">
                  Upload Thumbnail
                    <div className="upload_box_wrapper">
                    <UploadBox className="uploadThumbnail" name="thumbnail" multiple={false} loading={state.fileUploading} uploaded={state.uploaded} accept="image/x-png,image/gif,image/jpeg" text="Drag and Drop or" onChange={handleUploadThumbnail} />
                    </div>
                </div>
                <div className="advertisement_modal_input">
                  <div className="advertisement_modal_select_box">
                  <SelectBox
                    data={advertisementOrder}
                    name="Select Order"
                    selectedItem={state.order}
                    onChange={handleOnChangeInSelectBox}
                  />
                  </div>
                </div>
                <div className="advertisement_modal_input">
                  Banner Image
                  <div className="upload_box_wrapper">
                    <UploadBox className="uploadBanner" name="banner_image" multiple={false} loading={state.bannerUploading} uploaded={state.bannerUploaded} accept="image/x-png,image/gif,image/jpeg" text="Drag and Drop or" onChange={handleUploadBanner} />
                  </div>
                </div>
                <div className="advertisement_modal_input">
                  <Input name="URL" onChange={handleOnChange} inputKey="url" value={state.url} />
                </div>
              </div>
              <div className="advertisement_button_wrapper">
                <div className="advertisement_button">
                  <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={state.edit ? handleUpdateAdvertisement : handleAddAdvertisment} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete this ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteAdvertisement} />
        </div>
      </div>
    </div>
  )
}
