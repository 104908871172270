/* eslint-disable import/prefer-default-export */
import { instance } from '../helper/axios.helper';

export const getPrayerCalendarList = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('prayer_calendar/get', data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
export const addPrayerCalendarList = (data) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('prayer_calendar/create', data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
export const editPrayerCalendar = (id, data) => {
  const promise = new Promise((resolve, reject) => {
    instance().put(`prayer_calendar/edit/${id}`, data)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
export const getPrayerCalendarDetails = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().get(`prayer_calendar/get/${id}`)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
export const deletePrayerCalendarDetails = (id) => {
  const promise = new Promise((resolve, reject) => {
    instance().delete(`prayer_calendar/delete/${id}`)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
export const searchDetails = async (searchTerm) => {
  const promise = new Promise((resolve, reject) => {
    instance().post('prayer_calendar/get/', searchTerm)
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
  })
  return promise
}
