/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_EXECUTIVE_COMMITTEE, GET_OFFICERS, GET_WORKING_COMMITTEE } from "utils/types.utils";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import Button from "common_components/ui/button/button.ui";
import _ from "lodash";
import Spinner from "common_components/ui/spinner/spinner.ui";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import Table from "components/table/table.component";
import PlusIcon from "assets/icons/Plus_White.svg";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import InactiveIcon from "assets/icons/Inactive_black.svg";
import SelectBox from "common_components/ui/select_box/select_box.ui";
import Input from "common_components/ui/input_box/input_box.ui";
import UploadBox from "common_components/ui/upload_box/upload_box.ui";
import { addDescription, deleteDescription, editDescription, getAllDescription, getDescriptionById } from "models/executive.model";
import { uploadMedia } from "models/upload_media.model";
import { getAllDiocese } from "models/diocese.model";
import { getAllUser, addUser, getUserById, editUser, deleteUser } from "models/user_management.model";
import TextArea from "components/text_area/text_area.component";
import { AboutCsiNavData, workingCommitteeTableHeading, workingCommitteeBody, csiUserSuggessionData, committeeSelectBoxData } from "helper/constants.helper";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import "screens/aboutCSI/working_committee.screen.scss";
import { useSetState, uploadMediaFiles } from "../../utils/common.utils";

export default function WorkingCommittee(props) {
  const dispatch = useDispatch();
  const executive = useSelector((state: any) => state.executive_committee.executive_committee);
  const committees = useSelector((state: any) => state.aboutCsi.workingCommittee);
  const [state, setState] = useSetState({
    selectedData: "working_commitee",
    edit: false,
    openModal: false,
    active: false,
    checkedList: [],
    isChecked: false,
    id: "",
    searchValue: "",
    confirmModal: false,
    limit: 20,
    page: 1,
    userName: "",
    designation: "",
    profile: "",
    category: "",
    dioceses: "",
    sortOrder: "",
    description: '',
    loading: true,
    committeeId: "",
    imageUploading: false,
    uploaded: false,
    diocese: []
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleGetAllCommittee = async () => {
    try {
      const data = {
        type: "working_committee",
      }
      const committee: any = await getAllDescription(data);
      if (committee) {
        // const doc = committee.data.docs.map((docs) => {
        //   docs.diocese_name = docs.diocese.name
        //   docs.author = docs.created_by.user_name
        //   return docs;
        // });
        // committee.data.docs = doc;
        const details = committee.data.map((doc) => {
          doc.desc = doc.description.replace(/(<([^>]+)>)/gi, "");
          return doc
        })
        setState({ loading: false, description: committee.description });
        dispatch({
          type: GET_EXECUTIVE_COMMITTEE,
          payload: details
        })
      }
    } catch (err:any) {
      props.throwError("Failed to get Committee");
    }
  }

  const handleGetAllDiocese = async () => {
    try {
      const data = {
        limit: 30,
        search: state.search,
        page: state.page,
      }
      const diocese: any = await getAllDiocese(data);
      setState({ diocese: diocese.data })
    } catch (err:any) {
      props.throwError("Failed to get diocese");
    }
  }

  const deptData: any = [];
  const dept = state.diocese.docs !== undefined && state.diocese.docs.length !== 0
    && state.diocese.docs.map((diocese) => {
      deptData.push({ label: diocese.name, value: diocese._id });
      return "";
    });

  const handleAddCommittee = async () => {
    try {
      const data = {
        description: state.description,
        // user_name: state.userName,
        // email: state.email,
        // profile_picture: state.profile,
        // designation: state.designation,
        // role: state.category,
        // diocese: state.dioceses,
        // user_sort: true,
        // sort: Number(state.sortOrder),
          type: "working_committee"
      }
      const committee: any = await addDescription(data);
      await handleGetAllCommittee();
      setState({ openModal: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.message || err.data.desc : "Failed to add Working committee");
    }
  }

  const handleEditCommittee = async (id) => {
    // try {
    //   setState({ openModal: true, edit: true, committeeId: id })
    //   const committee: any = await getUserById(id);
    //   if (committee) {
    //     setState({
    //       userName: committee.data.user_name,
    //       designation: committee.data.designation,
    //       profile: committee.data.profile_picture,
    //       role: committee.data.role,
    //       email: committee.data.email,
    //       dioceses: committee.data.diocese,
    //       sortOrder: committee.data.sort,
    //       uploaded: committee.data.profile_picture ? true : false
    //     })
    //   }
    //   handleGetAllCommittee()
    // } catch (err:any) {
    //   props.throwError("Failed to add Working committee");
    // }
    setState({ openModal: true, edit: true, id })
    try {
      const res: any = await getDescriptionById(id)
      setState({
        description: res.data.description
      })
    } catch (err:any) {
      props.throwError('Failed to get details')
    }
  }
  const handleUpdateCommittee = async () => {
    // try {
    //   const data = {
    //     user_name: state.userName,
    //     profile_picture: state.profile,
    //     designation: state.designation,
    //     role: state.category,
    //     dioceses: state.dioceses,
    //     user_sort: true,
    //     sort: Number(state.sortOrder),
    //   }
    //   const committee: any = await editUser(state.committeeId, data);
    //   await handleGetAllCommittee({
    //     page: 1, limit: state.page * state.limit, search: state.search
    //   });
    //   setState({ openModal: false })
    // } catch (err:any) {
    //   props.throwError(err.data ? err.data.desc || err.data.message : "Failed to Update Committee");
    // }
    try {
      const data = {
        description: state.description,
        type: "working_committee"
      };
      await editDescription(state.id, data)
      await handleGetAllCommittee();
      setState({ openModal: false })
    } catch (err:any) {
      props.throwError("Failed to add working committee");
    }
  }

  const handleOnDeleteCommittee = async () => {
    try {
      const officer: any = await deleteDescription(state.committeeId);
      console.log(officer)
       handleGetAllCommittee();
      setState({ confirmModal: false })
    } catch (err:any) {
      props.throwError("Failed to delete Committee");
    }
  }

  const handleDeleteCommittee = (id) => {
    console.log(id)
    setState({ confirmModal: true, committeeId: id })
  }

  useEffect(() => {
    handleGetAllCommittee();
    handleGetAllDiocese();
  }, [])
  // useEffect(() => {
  //   handleGetAllCommittee();
  //   handleGetAllDiocese();
  // }, [state.search])

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }
  const handleOnText = (value) => {
    setState({ description: value });
  }
  const loadMore = async ({ page, limit, search }) => {
    // try {
    //   const data = {
    //     limit,
    //     search,
    //     user_sort: true,
    //     page,
    //     type: "working_committee"
    //   }
    //   const committee: any = await getAllUser(data);
    //   if (committee) {
    //     const doc = committee.data.docs.map((docs) => {
    //       docs.diocese_name = docs.diocese.name
    //       docs.author = docs.created_by.user_name
    //       return docs;
    //     });
    //     committee.data.docs = doc;
    //     committee.data.docs = [...committees.docs, ...committee.data.docs]
    //     dispatch({
    //       type: GET_WORKING_COMMITTEE,
    //       payload: committee.data
    //     })
    //   }
    // } catch (err:any) {
    //   props.throwError("Failed to get Committee");
    // }
  }
  const loadMoreInitial = () => {
    // setState({ page: state.page + 1 });
    // loadMore({
    //   page: state.page + 1,
    //   limit: state.limit,
    //   search: state.search
    // })
  }
  const searchChange = () => { }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditCommittee,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteCommittee,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSelectBox = (selectedOption) => {
    setState({ category: selectedOption.value });
  }

  const handleOnChangeDioceses = (selectedOption) => {
    setState({ dioceses: selectedOption.value })
  }

  const handleOnChangeInSearch = async (e) => {
    setState({ search: e.target.value })
    // try {
    //   const data = {
    //     limit: state.limit,
    //     search: e.target.value,
    //     page: state.page,
    //     type: "working_committee"
    //   }
    //   const committee: any = await getAllUser(data);
    //   if (committee) {
    //     const doc = committee.data.docs.map((docs) => {
    //       docs.diocese_name = docs.diocese.name
    //       docs.author = docs.created_by.user_name
    //       return docs;
    //     });
    //     dispatch({
    //       type: GET_WORKING_COMMITTEE,
    //       payload: committee.data
    //     })
    //     setState({ loading: false });
    //   }
    // } catch (err:any) {
    //   props.throwError("Failed to search Committee");
    // }
  }

  const handleUploadImage = async (e, key) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ imageUploading: true });
          const profile = await uploadMediaFiles(e);
          if (profile) {
            setState({ profile: profile.url, uploaded: true, imageUploading: false })
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif',)
        }
      } else {
        setState({ imageUploading: true })
        const profile: any = await uploadMediaFiles(e);
        if (profile) {
          setState({ profile: profile.url, uploaded: true, imageUploading: false })
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Personal Image");
    }
  }

  return (
    <div className="working_committee_screen">
      <div className="working_committee_container">
        <div className="working_committee_wrapper">
          <div className="working_committee_header_wrapper">
            <div className="working_committee_name_wrapper h2">
              Administration
            </div>
            <div className="working_committee_action_wrapper">
              {/* <div className="working_committee_search_box_wrapper">
                <SearchBar suggession="" placeholder="Search..." type="secondary" suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
              </div> */}
              {executive && executive.length > 0 ? ''
             : <div className="executive_committee_button_wrapper">
                  <Button className="executive_committee_button" type="primary" text="Add Committee" onClick={() => setState({ openModal: true, edit: false })} icon={PlusIcon} />
               </div>}
            </div>
          </div>
          <div className="working_committee_custom_navbar_wrapper">
            <CustomNavBar
              data={AboutCsiNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddCommittee}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="working_committee_table-wrapper">
            {
              state.loading ? <Spinner />
                : <Table
                    header={workingCommitteeTableHeading}
                    // body={committees ? committees.docs : []}
                    body={executive ? executive : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    checkedData={state.checkedList}
                    loadMore={loadMoreInitial}
                    hasMore={committees && committees.hasNextPage ? committees.hasNextPage : ''}
                    actionButton={actionArray}
                    error="Working Committee is not found"
                />
            }

          </div>
          <div className="executive_committee_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'executive_committee_customModal', closeButton: "close_button"
              }}
              showCloseIcon
            >
              <div className="executive_committee_modal_title_wrapper">
                <div className="executive_committee_modal_title h4">{state.edit !== true ? "Description" : "Edit Committee"}</div>
              </div>
              <div className="executive_committee_modal_line_wrapper"> </div>
              <div className="executive_committee_modal_text_area_wrapper">
                <TextArea text={state.description} onChange={handleOnText} />
              </div>
              <div className="executive_committee_button_wrapper">
                <div className="executive_committee_button">
                  <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={state.edit ? handleUpdateCommittee : handleAddCommittee} />
                </div>
              </div>
            </Modal>
          </div>
          {/* <div className="working_committee_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'working_committee_customModal'
              }}
              showCloseIcon
            > */}
              {/* <div className="working_committee_modal_header_wrapper">
                <div className="working_committee_modal_title_wrapper">
                  <div className="working_committee_modal_title h4">{state.edit !== true ? "Add Committee" : "Edit Committee"}</div>
                  <div className="working_committee_modal_icon_wrapper">
                    <img className="working_committee_modal_icon" alt="close_icon" src={InactiveIcon} onClick={() => setState({ openModal: false })} />
                  </div>
                </div>
                <div className="working_committee_modal_line_wrapper"> </div>
              </div>
              <div className="working_committee_modal_input_wrapper">
                <div className="working_committee_modal_input">
                  <Input name="Name" onChange={handleOnChange} inputKey="userName" value={state.userName} />
                </div>
                <div className="working_committee_modal_input">
                  <Input name="Designation" onChange={handleOnChange} inputKey="designation" value={state.designation} />
                </div>
                <div className="working_committee_modal_select_input">
                  <SelectBox data={deptData ? deptData : []} name="Select Dioceses" selectedItem={state.dioceses} onChange={handleOnChangeDioceses} />
                </div>
                <div className="working_committee_modal_input">
                  Personal Image
                  <div className="upload_box_wrapper">
                    <UploadBox name="image" className="thumbnail" accept="image/x-png,image/gif,image/jpeg" text="Drag and Drop or" onChange={handleUploadImage} uploaded={state.uploaded} loading={state.imageUploading} />
                  </div>
                </div>
                <div className="working_committee_modal_select_input">
                  <SelectBox data={committeeSelectBoxData} name="Category" selectedItem={state.category} onChange={handleOnChangeInSelectBox} />
                </div>
                <div className="working_committee_modal_input">
                  <Input name="Sort" onChange={handleOnChange} type="number" inputKey="sortOrder" value={state.sortOrder} />
                </div>
              </div> */}
              {/* <div className="working_committee_button_wrapper">
                <div className="working_committee_button">
                  <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={state.edit ? handleUpdateCommittee : handleAddCommittee} />
                </div>
              </div>
            </Modal>
          </div> */}
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete committee ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteCommittee} />
        </div>
      </div>
    </div>
  )
}
