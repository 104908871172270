import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Footer from 'components/footer/footer.component';
import Header from 'components/header/header.component';
import Navbar from 'components/navbar/navbar.component';
import Input from 'common_components/ui/input_box/input_box.ui';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import Button from 'components/button/button.component';
import { NavLink } from 'react-router-dom';
import { GET_RESOURCES, GET_BOOKS, GET_ONE_BOOK } from 'utils/types.utils';
import { useSetState, getMonth, throwError } from 'utils/common.utils';
import { getPublicationById, getAllPublication, orderPublication } from 'models/public.model';
import OfficerCard from 'components/officer_card/officer_card.component';
import 'screens/user_screens/publication_details.screen.scss';
import Swiper from 'components/swiper/swiper.component';
import Wallpaper from 'assets/images/wallpaper.jpg';
import publicationGirl from '../../assets/images/publication_girl.png';
import publicationBird from '../../assets/images/publication_bird.png';

export default function PublicationDetails(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const books = useSelector((state: any) => state.publication.books);
  const moreBooks = books && books.docs.filter((book) => book._id !== match.params.id)
  const singleBook = useSelector((state: any) => state.publication.book);
  const [state, setState] = useSetState({
    selected_filter: '',
    limit: 20,
    page: 1,
    search: '',
    activated: 'all',
    name: '',
    email: '',
    mobile: '',
    imageUrl: '',
    address: '',
    message: '',
  });

  const handleGetAllBooks = async () => {
    try {
      const data = {
        limit: state.limit,
        page: 1,
        search: state.searchValue,
        activated: true,
        type: 'books',
      };
      const book: any = await getAllPublication(data);
      const doc = book.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      });
      book.data.docs = doc;
      dispatch({
        type: GET_BOOKS,
        payload: book.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : 'Failed to load Publication');
    }
  };

  const getPublicationDetails = async () => {
    try {
      const book: any = await getPublicationById(match.params.id);
      dispatch({
        type: GET_ONE_BOOK,
        payload: book.data,
      });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get publication details")
    }
  };

  const orderBook = async () => {
    if (state.name === '' || state.email === '' || state.address === '' || state.mobile === '') {
      toast.error("Please enter all the details")
    } else {
    try {
      const data = {
        name: state.name,
        email: state.email,
        mobile: state.mobile,
        address: state.address,
        message: state.message,
        product: match.params.id
      }
      const book: any = await orderPublication(data);
      toast.error("Order placed")
      setState({
        name: '',
        email: '',
        mobile: '',
        address: '',
        product: '',
        message: ''
      })
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to place order")
    }
  }
  }
  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  useEffect(() => {
    handleGetAllBooks();
    getPublicationDetails();
    document.getElementById('main')?.scrollIntoView();
  }, [match.params.id]);
  return (
    <div className="publication_details_screen">
      <div className="publication_details_container" id="main">
        <div className="publication_details_wrapper">
          <div className="publication_details_header_wrapper">
            <Header />
          </div>
          <div className="publication_details_navbar_wrapper">
            <Navbar {...props} />
          </div>
          <div className="publication_details_title_wrapper">
            <div className="publication_details_title h2">Publications</div>
            <div className="publication_details_line_wrapper"> </div>
          </div>
          <div className="publication_details_content_wrapper">
            <div className="publication_details_content">
              <div className="publication_details_image_content_wrapper">
                <div className="publication_details_thumbnail_wrapper">
                  <img
                    src={
                      singleBook && singleBook.thumbnail
                        ? singleBook.thumbnail
                        : ''
                    }
                    alt="thumbnail_image"
                    className="publication_details_thumbnail"
                  />
                </div>
                {singleBook && singleBook.content_image.length > 0
               && <div className="publication_details_image_wrapper">
                  <Swiper
                    data={singleBook && singleBook ? singleBook.content_image : []}
                    types=""
                    number={3}
                    orderNow={() => {}}
                    className="publication_details_image"
                  />
                  </div>}
              </div>
              <div className="publication_details_publication_information_wrapper">
                <div className="publication_details_book_name h2">
                  {singleBook && singleBook.title ? singleBook.title : ''}
                </div>
                {/* <div className="publication_details_book_author_name h6">
                  By{' '}
                  {singleBook && singleBook.created_by
                    ? singleBook.created_by.user_name
                    : ''}
                </div> */}
                <div className="publication_details_book_description h5">
                  {singleBook && singleBook.description
                    ? singleBook.description
                    : ''}
                </div>
                <div className="publication_details_input">
                  <Input
                    value={state.name}
                    onChange={handleOnChange}
                    name="Name"
                    inputKey="name"
                    background="#FFF8FB"
                  />
                </div>
                <div className="publication_details_input">
                  <Input
                    value={state.email}
                    onChange={handleOnChange}
                    name="Email Address"
                    inputKey="email"
                    background="#FFF8FB"
                  />
                </div>
                <div className="publication_details_input">
                  <Input
                    value={state.mobile}
                    onChange={handleOnChange}
                    name="Mobile Number"
                    inputKey="mobile"
                    background="#FFF8FB"
                  />
                </div>
                <div className="publication_details_input">
                  <Input
                    value={state.address}
                    onChange={handleOnChange}
                    name="Address"
                    inputKey="address"
                    background="#FFF8FB"
                  />
                </div>
                <div className="publication_details_input">
                  <TextArea
                    value={state.message}
                    onChange={handleOnChange}
                    name="Message"
                    inputKey="message"
                    background="#FFF8FB"
                  />
                </div>
                <div className="publication_details_button">
                  <Button text="Order" className="" onClick={() => orderBook()} />
                </div>
              </div>
            </div>
          </div>
          <div className="publication_details_explore_more_wrapper">
            <div className="publication_details_explore_title_wrapper">
              <div className="publication_details_explore_title h2">
                Explore More
              </div>
              <div className="publication_details_line_wrapper"> </div>
            </div>
            <div className="publication_details_explore_more">
              <Swiper
                data={moreBooks && moreBooks ? moreBooks : []}
                types="explore"
                number={3}
                orderNow={(id) => {
                  props.history.push(`/publication_details/${id}`);
                }}
                className="publication_details_image"
              />
            </div>
          </div>
          <div className="publication_details_footer_wrapper">
            <div className="publication_details_footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
