import React, { useCallback, useEffect, useRef, useState } from 'react';
import { uploadMediaFiles, uploadMultipleFiles, useSetState } from 'utils/common.utils';
import Button from 'common_components/ui/button/button.ui';
import AddNewsComponent from 'components/add_news/add_news.component';
import Input from 'common_components/ui/input_box/input_box.ui';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import {
  addCampaignDetails,
  editCampaignDetails,
  getCampaignDetailsById,
} from 'models/campaign.model';
import 'screens/campaign/add_campaign.screen.scss';
import { removeMedia, uploadMedia } from 'models/news.model';
import Modal from 'react-responsive-modal';
import Arrowleft from '../../assets/icons/Arrow.svg';

export default function AddCampaign(props) {
  const { match } = props;
  const imgRef = useRef(null);
  const [upImg, setUpImg] = useState();
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", height: 50, width: 100, x: 0, y: 0, maxHeight: 50 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  const [state, setState] = useSetState({
    title: '',
    selectedData: '',
    description: '',
    thumbnail: '',
    gallery: [],
    diocese: '',
    limit: 20,
    uploadFile: '',
    page: 1,
    fileUploading: false,
    uploaded: false,
    galleries: [],
  });
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader: any = new FileReader();
      setState({ uploadFile: e.target.files[0].name })
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleInputChange = (value) => {
    setState({ description: value });
  };
  const handleGetCampaign = async () => {
    try {
      const res: any = await getCampaignDetailsById(match.params.id);
      setState({
        description: res.data.description,
        title: res.data.title,
        thumbnail: res.data.thumbnail,
        gallery: res.data.gallery,
      });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to get campaign details',
      );
    }
  };

  useEffect(() => {
    if (match.params.id) {
      handleGetCampaign();
    }
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
const image: any = imgRef.current;
const canvas: any = previewCanvasRef.current;
const newCrop: any = completedCrop;

const scaleX = image.naturalWidth / image.width;
const scaleY = image.naturalHeight / image.height;
const ctx = canvas.getContext("2d");
const pixelRatio = window.devicePixelRatio;

canvas.width = newCrop.width * pixelRatio;
canvas.height = newCrop.height * pixelRatio;

ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
ctx.imageSmoothingQuality = "high";

ctx.drawImage(
image,
crop.x * scaleX,
crop.y * scaleY,
crop.width * scaleX,
crop.height * scaleY,
0,
0,
crop.width,
crop.height
);
  }, [completedCrop]);
  const uploadThumbnail = async (e) => {
    setState({ cropModal: true })
    // try {
    //   if (e.dataTransfer) {
    //     setState({ fileUploading: true });
    //     const thumbnail: any = await uploadMediaFiles(e);
    //     if (thumbnail) {
    //       setState({
    //         thumbnail: thumbnail.url,
    //         uploaded: true,
    //         fileUploading: false,
    //       });
    //     }
    //   } else {
    //     setState({ fileUploading: true });
    //     const thumbnail: any = await uploadMediaFiles(e);
    //     if (thumbnail) {
    //       setState({
    //         thumbnail: thumbnail.url,
    //         uploaded: true,
    //         fileUploading: false,
    //       });
    //     }
    //   }
    // } catch (err:any) {
    //   props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    // }
  };

  const uploadGallery = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ galleryUploading: true });
          const res: any = await uploadMultipleFiles(e);
          const datas = [...state.gallery, ...[res.data]];
          setState({ gallery: datas });
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif');
        }
      } else {
        setState({ galleryUploading: true });
        const gallery: any = [];
        const res: any = await uploadMultipleFiles(e);
        const datas = [...state.gallery, ...res.data];
        setState({ gallery: datas });
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Gallery');
    }
  };

  const handleAddCampaign = async () => {
    try {
      const data = {
        title: state.title,
        description: state.description,
        thumbnail: state.thumbnail,
        gallery: state.gallery,
        activated: true,
      };
      const res: any = await addCampaignDetails(data);
      props.history.push('/campaigns');
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to add campaign');
    }
  };

  const handleUpdateCampaign = async () => {
    try {
      const data = {
        title: state.title,
        description: state.description,
        thumbnail: state.thumbnail,
        gallery: state.gallery,
      };
      await editCampaignDetails(match.params.id, data);
      props.history.push('/campaigns');
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to update campaign');
    }
  };
  const generateDownload = async (canvas, img) => {
    // if (!crop || !canvas) {
    //   return;
    // }
    canvas.toBlob(
      async (blob) => {
        const file: any = new File([blob], state.uploadFile, { type: 'image/jpeg' })
        const data = new FormData();
        const ext = file.name.split('.').pop();
        const filename = file.name.split('.')[0].replace(/ /g, '')
        const newFileName = `${filename}.${ext}`;
        data.append('file', file, newFileName);
      const image: any = await uploadMedia(data);
      setState({ thumbnail: image.url })
      },
      "image/jpeg",
      1
      );
      setState({ cropModal: false })
  }
  const removeThumbnail = async () => {
    try {
      const data = {
        resource_url: state.thumbnail,
      };
      await removeMedia(data);
      setState({ thumbnail: '' });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to remove gallery image',
      );
    }
  };

  const removeGallery = async (id) => {
    try {
      const galleries = state.gallery.splice(id, 1);
      const data = { resource_url: galleries[0] };
      const removeGalleryImage = await removeMedia(data);
      setState({ gallery: state.gallery });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to remove gallery image',
      );
    }
  };

  return (
    <div className="add_campaign_screen">
      <div className="add_campaign_container">
        <div className="add_campaign_wrapper">
          <div className="add_campaign_head">
            <div className="diocese_view_navigate_icon_wrapper">
              <div className="diocese_view_navigate_icon">
                <img
                  className="navigate_icon"
                  alt=""
                  src={Arrowleft}
                  onClick={() => props.history.push('/campaigns')}
                />
              </div>
            </div>
            <div className="add_campaign_head_text h3">
              {match.params.id ? 'Edit campaign' : 'Add campaign'}
            </div>
            <div className="add_campaign_btn_container">
              <div className="add_campaign_btn">
                <Button
                  type="primary"
                  text={match.params.id ? 'Update' : 'Add'}
                  onClick={
                    match.params.id ? handleUpdateCampaign : handleAddCampaign
                  }
                />
              </div>
            </div>
          </div>
          <div className="news_content">
            <div className="input_wrapper">
              <Input
                name="Title"
                inputKey="title"
                onChange={(e) => {
                  setState({ title: e.target.value });
                }}
                value={state.title}
              />
            </div>
            <AddNewsComponent
              text={state.description}
              thumbnail={state.thumbnail}
              gallery={state.gallery}
              textAreaOnchange={handleInputChange}
              uploadThumbnail={uploadThumbnail}
              uploadGallery={uploadGallery}
              removeThumbnail={removeThumbnail}
              removeGallery={removeGallery}
              pdfUpload={() => {}}
            />
            <Modal
              open={state.cropModal}
              onClose={() => setState({ cropModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'modal_container_crop',
                closeButton: 'closeButton',
              }}
              showCloseIcon
            >
          <div className="crop_wrapper">
          <div>
        <input type="file" accept="image/*" onChange={onSelectFile} />
          </div>
          <div className="react_image_crop">

          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
          </div>
          <canvas
            ref={previewCanvasRef}
            className="canvas_crop"
          //   style={{
          //   width: '100%',
          //   height: '100%'
          // }}
          />
          <div className="crop_button_wrapper">

          <Button
            type="primary"
            text="Add thumbnail"
            className="crop_btn"
            onClick={() =>
              generateDownload(previewCanvasRef.current, completedCrop)}
          />
          </div>
          </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
