import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash';
import moment from 'moment';
import Input from 'common_components/ui/input_box/input_box.ui';
import Button from 'common_components/ui/button/button.ui';
import CheckList from 'common_components/ui/checklist/checklist.ui';
import {
  GET_DIOCESE_NEWS_DETAILS,
  GET_DIOCESE_NEWS,
  GET_DIOCESE,
  THROW_ERROR,
  GET_ALL_DIOCESES,
} from 'utils/types.utils';
import {
  dioceseNavData,
  dioceseNewsBody,
  csiUserSuggessionData,
  dioceseData,
  dioceseNewsTableHeading,
} from 'helper/constants.helper';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import {
  deleteNews,
  editNews,
  getAllNews,
  getNews,
  getNewsDetails,
  updateActiveMultiple,
} from 'models/news.model';
import { toast } from 'react-toastify';
import { getAllDiocese, getAllDioceses } from 'models/diocese.model';
import CustomNavBar from '../../components/custom_nav/custom_nav.component';
import InfoCard from '../../common_components/ui/info_card/info_card.ui';
import ActionButton from '../../common_components/ui/action_button/action_button.ui';
import Table from '../../components/table/table.component';
import { useSetState } from '../../utils/common.utils';
import 'screens/diocese/diocese_news.screen.scss';
import PlusIcon from '../../assets/icons/Plus_White.svg';
import Filter from '../../assets/icons/Filter.svg';
import Tick from '../../assets/icons/Tick_White.svg';
import Inactive from '../../assets/icons/Inactive_Pink.svg';
import NewsIcon from '../../assets/icons/News.svg';
import Delete from '../../assets/icons/Delete.svg';
import Edit from '../../assets/icons/Edit.svg';

export default function DioceseNews(props) {
  const dispatch = useDispatch();
  const dioceseNews = useSelector((state: any) => state.diocese.news);
  const dioceseNewsDetails = useSelector(
    (state: any) => state.diocese.news_details,
  );
  const dioceseDetails = useSelector((state: any) => state.diocese.allDioceses);
  const dioceseUser = localStorage.getItem('diocese');
  const [state, setState] = useSetState({
    selectedData: 'diocese_news',
    checkList: [],
    checkedList: [],
    isChecked: false,
    confirmModal: '',
    tableData: dioceseNewsBody,
    page: 1,
    limit: 25,
    search: '',
    filter: '',
    id: '',
    news: [],
    loading: true,
    activated: 'all',
    gallery: [],
  });

  const dioData: any = [];
  const dioDetails = dioceseDetails
    && dioceseDetails.length !== 0 && dioceseDetails.map((diocese) => {
      dioData.push({ name: diocese.name, value: diocese._id });
      return '';
    });

  const handleGetAllDioceseNews = async ({ limit, page, search }) => {
    try {
      const data = {
        limit,
        page,
        search: {
          diocese: search
        },
        activated: 'all',
        type: 'dioceses',
        diocese: dioceseUser ? [dioceseUser] : state.filter
      };
      const res: any = await getAllNews(data);
      const doc = res.data.docs.map((docs) => {
        docs.diocese_name = docs.dioceses.name;
        docs.author = docs.author.user_name;
        docs.date = moment(docs.date).format('ddd, MMM D, YYYY')
        docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
        return docs;
      });

      res.data.docs = doc;
      dispatch({
        type: GET_DIOCESE_NEWS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to load diocese news',
      );
    }
  };
  const handleGetAllDiocese = async () => {
    try {
      const res: any = await getAllDioceses();
        dispatch({
          type: GET_ALL_DIOCESES,
          payload: res.data,
        });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
  };
  const handleGetNewsDetails = async () => {
    try {
      const data = {
        type: 'dioceses',
        diocese: dioceseUser && dioceseUser ? dioceseUser : ''
      };
      const newsDetails: any = await getNewsDetails(data);
      dispatch({
        type: GET_DIOCESE_NEWS_DETAILS,
        payload: newsDetails.data,
      });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to get diocese news details',
      );
    }
  };
  useEffect(() => {
    handleGetNewsDetails();
    handleGetAllDioceseNews(state);
    handleGetAllDiocese();
  }, [state.search]);
  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const handleOnClick = (edit) => {
    if (!edit) {
      setState({ openModal: true });
    } else {
      setState({ openModal: true });
      setState({ edit: true });
    }
  };

  const handleOnChangeList = (selectedData, multiple) => {
    if (multiple === false) {
      setState({ checkList: [selectedData] });
    } else {
      const findIndex = _.findIndex(state.checkList, {
        index: selectedData.index,
      });
      if (findIndex === -1) {
        setState({ checkList: [...state.checkList, ...[selectedData]] });
      } else {
        const indexes: any[] = [];
        state.checkList.forEach((data) => {
          if (data.index !== selectedData.index) {
            indexes.push(data);
          }
        });
        setState({ checkList: indexes });
      }
    }
  };
  const searchChange = async (e) => {
    setState({ search: e.target.value, page: 1 });
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = dioceseNews.docs.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (dioceseNews.docs.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleAddNews = () => {
    props.history.push({
      pathname: '/dioceses/add_news',
    });
  };

  const handleOnDeleteNews = async () => {
    try {
      await deleteNews(state.id);
      handleGetAllDioceseNews({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
      });
      handleGetNewsDetails();
      setState({ confirmModal: false });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to delete diocese news',
      );
    }
  };
  const handleToggleNewsActive = async (id) => {
    try {
      const res: any = await getNews(id);
      const data = {
        activated: !res.data.activated,
      };
      await editNews(id, data);
      handleGetNewsDetails();
      handleGetAllDioceseNews({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
      });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to get diocese news');
    }
  };
  const handleMakeNewsActive = async () => {
    try {
      const news = { news: state.checkedList, activated: true };
      const active = await updateActiveMultiple(news);
      handleGetNewsDetails();
      handleGetAllDioceseNews({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
      });
      setState({ checkedList: [], isChecked: false, page: 1 });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to activate news');
    }
  };
  const handleApplyFilter = async () => {
    try {
      const dioceseFilter: any = [];
      state.checkList.map((d) => {
        dioceseFilter.push(d.value);
        return null;
      });
      setState({ filter: dioceseFilter, page: 1 })
      const data = {
        limit: state.limit,
        page: 1,
        search: {
          diocese: state.search
        },
        type: 'dioceses',
        activated: state.activated,
        diocese: dioceseUser ? [dioceseUser] : dioceseFilter,
      };
      const res: any = await getAllNews(data);
      const doc = res.data.docs.map((docs) => {
        docs.diocese_name = docs.dioceses.name;
        docs.author = docs.author.user_name;
        docs.date = moment(docs.date).format('ddd, MMM D, YYYY')
        docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
        return docs;
      });
      res.data.docs = doc
      dispatch({
        type: GET_DIOCESE_NEWS,
        payload: res.data,
      });
      setState({ checkedList: [], isChecked: false, openModal: false });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to load diocese news',
      );
    }
  };
  const handleEditNews = (id) => {
    props.history.push({
      pathname: `/dioceses/edit_news/${id}`,
    });
  };

  const handleDeleteNews = (id) => {
    setState({ confirmModal: true, id });
  };

  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        page,
        limit,
        search: {
          diocese: search
        },
        activated: state.activated,
        type: 'dioceses',
        diocese: dioceseUser ? [dioceseUser] : state.filter
      };
      const res: any = await getAllNews(data);
      const doc = res.data.docs.map((docs) => {
        docs.diocese_name = docs.dioceses.name;
        docs.author = docs.author.user_name;
        docs.date = moment(docs.date).format('DD/MM/YYYY');
        docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
        return docs;
      });
      res.data.docs = doc
      res.data.docs = [...dioceseNews.docs, ...res.data.docs];
      dispatch({
        type: GET_DIOCESE_NEWS,
        payload: res.data,
      });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to get news');
    }
  };

  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    });
  };
  const handleMakeNewsInactive = async () => {
    try {
      const news = { news: state.checkedList, activated: false };
      const active = await updateActiveMultiple(news);
      handleGetAllDioceseNews({
        page: 1,
        limit: state.page * state.limit,
        search: state.search,
      });
      setState({ checkedList: [], isChecked: false, page: 1 });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to activate news');
    }
  };
  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit News',
      onClick: handleEditNews,
      icon: Edit,
    },
    {
      type: 'secondary',
      text: 'Delete News',
      onClick: handleDeleteNews,
      icon: Delete,
    },
  ];

  return (
    <div className="diocese_news_screen">
      <div className="diocese_news_container">
        <div className="diocese_news_custom_nav_wrapper">
          <CustomNavBar
            data={dioceseNavData}
            onTapClick={handleChangeClick}
            selectedItem={state.selectedData}
            buttonText="filter"
            buttonType="primary"
            buttonIcon={Filter}
            searchText="Search..."
            searchType="secondary"
            suggession={csiUserSuggessionData}
            buttonOnClick={handleOnClick}
            button
            search
            onSearchChange={searchChange}
          />
        </div>
        <div className="diocese_news_info_card_wrapper">
          <div className="diocese_news_info_card">
            <InfoCard
              title="Total No of News"
              text={
                dioceseNewsDetails && dioceseNewsDetails[0]
                  ? dioceseNewsDetails[0].total_news
                  : 0
              }
              icon={NewsIcon}
            />
          </div>
          <div className="diocese_news_info_card">
            <InfoCard
              title="No. of Active News"
              text={
                dioceseNewsDetails && dioceseNewsDetails[0]
                  ? dioceseNewsDetails[0].activate_news
                  : 0
              }
              icon={NewsIcon}
            />
          </div>
          <div className="diocese_news_info_card">
            <InfoCard
              title="No. of Inactive News"
              text={
                dioceseNewsDetails && dioceseNewsDetails[0]
                  ? dioceseNewsDetails[0].in_activate_news
                  : 0
              }
              icon={NewsIcon}
            />
          </div>
          <div className="diocese_news_action_button_wrapper">
            <div className="diocese_news_action_button">
              <ActionButton
                text="Add News"
                icon={PlusIcon}
                type="secondary"
                onClick={handleAddNews}
              />
            </div>
            {!dioceseUser
            && <>
            <div className="diocese_news_action_button">
                  <ActionButton
                    text="Make Active"
                    icon={Tick}
                    type="secondary"
                    onClick={handleMakeNewsActive}
                  />
            </div>
            <div className="diocese_news_action_button">
              <ActionButton
                text="Make Inactive"
                icon={Inactive}
                type="primary"
                onClick={handleMakeNewsInactive}
              />
            </div>
               </>}
          </div>
        </div>
        <div className="diocese_news_table_wrapper">
          {state.loading ? (
            <Spinner />
          ) : (
            <Table
              header={dioceseNewsTableHeading}
              body={dioceseNews ? dioceseNews.docs : []}
              isChecked={state.isChecked}
              checkAll={checkAll}
              checkOne={checkOne}
              checkedData={state.checkedList}
              loadMore={loadMoreInitial}
              hasMore={dioceseNews && dioceseNews.hasNextPage ? dioceseNews.hasNextPage : false}
              actionButton={actionArray}
              error="No news found"
              onClick={handleToggleNewsActive}
              active={!dioceseUser ? true : false}
            />
          )}
        </div>
        <div>
          <Modal
            open={state.openModal}
            onClose={() => setState({ openModal: false })}
            center
            classNames={{
              overlay: 'customOverlay',
              modal: 'diocese_news_modal',
            }}
            showCloseIcon={false}
          >
            <div className="diocese_news_modal_container">
              <div className="diocese_news_modal_wrapper">
                <div className="diocese_news_modal_header_wrapper">
                  <div className="diocese_news_modal_header h4">Status</div>
                  <div
                    className="diocese_news_modal_clear h6"
                    onClick={() =>
                      setState({ checkedList: '', activated: 'all' })}
                  >
                    Clear All
                  </div>
                </div>
                <div className="diocese_news_button_wrapper">
                  <div className="button_active">
                    <Button
                      text="Active"
                      type={state.activated === true ? 'primary' : 'secondary'}
                      onClick={() => setState({ activated: true })}
                    />
                  </div>
                  <div className="button_inactive">
                    <Button
                      text="Inactive"
                      type={state.activated === false ? 'primary' : 'secondary'}
                      onClick={() => setState({ activated: false })}
                    />
                  </div>
                </div>
                {
                dioceseUser ? null
              : <div className="diocese_filter">
                  <div className="diocese_news_modal_header_wrapper">
                    <div className="diocese_news_modal_diocese_heading h4">
                      Diocese
                    </div>
                    <div
                      className="diocese_news_modal_diocese_clear h6"
                      onClick={() => setState({ checkList: [] })}
                    >
                      Clear All
                    </div>
                  </div>
                  <div className="diocese_news_modal_checklist">
                    <CheckList
                      data={dioData ? dioData : []}
                      icon={Tick}
                      multiple
                      selectedData={state.checkList}
                      iconBackground=""
                      onChange={handleOnChangeList}
                      list={state.checkList}
                      multipleList={state.checkList}
                    />
                  </div>
                </div>
                }
                <div className="diocese_news_apply_filter_button_wrapper">
                  <div className="diocese_news_button">
                    <Button
                      text="Apply Filters"
                      type="primary"
                      onClick={handleApplyFilter}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <ConfirmModal
          openModal={state.confirmModal}
          description="Are you sure want to delete the Diocese News ?"
          closeModal={() => setState({ confirmModal: false })}
          deleteConfirm={handleOnDeleteNews}
        />
      </div>
    </div>
  );
}
