import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { GET_CSITA_OFFICER, GET_OFFICERS } from "utils/types.utils";
import Button from "common_components/ui/button/button.ui";
import Spinner from "common_components/ui/spinner/spinner.ui";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import { getAllUser, addUser, getUserById, editUser, deleteUser } from "models/user_management.model";
import { csitaData, csitaOfficersTableHeading, csitaOfficersBody, csiUserSuggessionData } from "helper/constants.helper";
import EditIcon from "assets/icons/Edit.svg";
import PlusIcon from "assets/icons/Plus_White.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import Table from "components/table/table.component";
import Arrow from "assets/icons/Arrow.svg";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import "screens/aboutCSI/csita_officers.screen.scss";
import { useSetState } from "../../utils/common.utils";

export default function CsitaOfficer(props) {
  const dispatch = useDispatch();
  const csitaOfficers = useSelector((state: any) => state.aboutCsi.csitaOfficer);
  const [state, setState] = useSetState({
    selectedData: "csita_officers",
    edit: false,
    openModal: false,
    active: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    searchValue: "",
    description: "",
    csitaOfficerId: "",
    loading: true,
    limit: 20,
    page: 1,
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleGetAllCsitaOfficers = async ({ limit, page, search }) => {
    try {
      const data = {
        limit,
        search,
        page,
        type: "csita_officers",
        user_sort: true
      }
      const csitaOfficer: any = await getAllUser(data);
      if (csitaOfficer) {
        const doc = csitaOfficer.data.docs.map((docs) => {
          docs.phone_fax = `${docs.phone}/${docs.fax}`
          docs.author = docs.created_by.user_name
          return docs;
        });
        csitaOfficer.data.docs = doc;
        dispatch({
          type: GET_CSITA_OFFICER,
          payload: csitaOfficer.data
        })
        setState({ loading: false });
      }
    } catch (err:any) {
      props.throwError("Failed to get Csita Officer");
    }
  }

  useEffect(() => {
    handleGetAllCsitaOfficers(state);
  }, []);
  useEffect(() => {
    handleGetAllCsitaOfficers(state);
  }, [state.search]);

  const handleEditOfficer = (id) => {
    props.history.push(`/about/csita/add_officers/${id}`)
  }

  const handleDeleteOfficer = (id) => {
    setState({ confirmModal: true, csitaOfficerId: id })
  }

  const handleOnDeleteOfficer = async () => {
    try {
      const officer: any = await deleteUser(state.csitaOfficerId);
      await handleGetAllCsitaOfficers({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ confirmModal: false })
    } catch (err:any) {
      props.throwError("Failed to delete Csita Officer");
    }
  }

  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        limit,
        user_sort: true,
        search,
        page,
        type: "csita_officers"
      }
      const csitaOfficer: any = await getAllUser(data);
      if (csitaOfficer) {
        const doc = csitaOfficer.data.docs.map((docs) => {
          docs.phone_fax = `${docs.phone}/${docs.fax}`
          docs.author = docs.created_by.user_name
          return docs;
        });
        csitaOfficer.data.docs = doc;
        csitaOfficer.data.docs = [...csitaOfficers.docs, ...csitaOfficer.data.docs];
        dispatch({
          type: GET_CSITA_OFFICER,
          payload: csitaOfficer.data
        })
        setState({ loading: false });
      }
    } catch (err:any) {
      props.throwError("Failed to get Csita Officer");
    }
  }
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    })
  }
  const searchChange = () => { }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditOfficer,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteOfficer,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSearch = async (e) => {
    setState({ search: e.target.value, page: 1 })
  }

  return (
    <div className="csita_officer_screen">
      <div className="csita_officer_container">
        <div className="csita_officer_wrapper">
          <div className="csita_officer_header_wrapper">
            <div className="csita_officer_name_wrapper h2">
              <div className="csita_officer_nav_icon_wrapper">
                <img className="csita_officer_nav_icon" alt="nav_icon" src={Arrow} onClick={() => props.history.push('/about/executive_commitee')} />
              </div>
              CSITA
            </div>
            <div className="csita_officer_action_wrapper">
              <div className="csita_officer_search_box_wrapper">
                <SearchBar suggession="" placeholder="Search..." type="secondary" suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
              </div>
              <div className="csita_officer_button_wrapper">
                <Button className="csita_officer_button" type="primary" text="Add Officer" onClick={() => props.history.push('/about/csita/add_officers')} icon={PlusIcon} />
              </div>
            </div>
          </div>
          <div className="csita_officer_custom_navbar_wrapper">
            <CustomNavBar
              data={csitaData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick=""
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="csita_officer_table-wrapper">
            {
              state.loading ? <Spinner />
                : <Table
                    header={csitaOfficersTableHeading}
                    body={csitaOfficers ? csitaOfficers.docs : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    checkedData={state.checkedList}
                    loadMore={loadMoreInitial}
                    actionButton={actionArray}
                    hasMore={csitaOfficers && csitaOfficers.hasNextPage ? true : false}
                    error="Csita Officer is not found"
                />
            }
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteOfficer} />
        </div>
      </div>
    </div>
  )
}
