import React, { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addUser,
  editUser,
  getUserById,
  getAllUser,
  deleteUser,
} from 'models/user_management_model';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash';
import Input from 'common_components/ui/input_box/input_box.ui';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Button from 'common_components/ui/button/button.ui';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import {
  userNavData,
  csiUserTableHeading,
  csiUserSuggessionData,
} from 'helper/constants.helper';
import { GET_USERS } from 'utils/types.utils';
import CustomNavBar from '../../components/custom_nav/custom_nav.component';
import Table from '../../components/table/table.component';
import { useSetState } from '../../utils/common.utils';
import PlusIcon from '../../assets/icons/Plus_White.svg';
import DeleteIcon from '../../assets/icons/Delete.svg';
import EditIcon from '../../assets/icons/Edit.svg';
import 'screens/users/users.screen.scss';

export default function CsiUsers(props) {
  const { throwError } = props;
  const dispatch = useDispatch();

  const list = useSelector((state: any) => state.users.user_list);
  const [state, setState] = useSetState({
    selectedData: 'csi_user',
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    user_name: '',
    mobile: '',
    email: '',
    password: '',
    loading: true,
    search: '',
    page: 1,
    limit: 20,
    user_id: '',
    csi_user_id: '',
    user_type: 'csi_user',
  });

  const handleListUsers = async ({ page, limit, search }) => {
    try {
      const data = {
        page,
        limit,
        search,
        type: state.user_type,
      };
      const res: any = await getAllUser(data);
      const userList = res.data.docs.map((user) => {
        user.author = user.created_by.user_name;
        return user;
      });
      res.data.docs = userList;
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
      setState({
        loading: false,
      });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : 'Unable to fetch users');
    }
  };
  useEffect(() => {
    handleListUsers(state);
  }, [state.search]);

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleEditUser = async (id) => {
    setState({ openModal: true, edit: true, password: '' });
    try {
      const res: any = await getUserById(id);
      setState({
        user_name: res.data.user_name,
        email: res.data.email,
        mobile: res.data.mobile,
        edit: true,
        user_id: res.data._id,
      });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : 'Unable to get user details');
    }
  };
  const handleUpdateUser = async () => {
    try {
      if (state.password.length > 0) {
        const data = {
          user_name: state.user_name,
          email: state.email,
          mobile: state.mobile,
          password: state.password,
        };
        await editUser(state.user_id, data);
        handleListUsers({
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
        });
        setState({
          openModal: false,
          edit: true,
        });
      } else {
        const data = {
          user_name: state.user_name,
          email: state.email,
          mobile: state.mobile,
        };
        await editUser(state.user_id, data);
        handleListUsers({
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
        });
        setState({
          openModal: false,
          edit: true,
        });
      }
    } catch (err:any) {
      throwError(err.status === 422 ? err.data.message : 'Unable to add user');
    }
  }
    const handleDeleteUser = async () => {
      try {
        await deleteUser(state.csi_user_id);
        setState({
          confirmModal: false,
        });
        toast.error('User deleted');
        handleListUsers({
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
        });
      } catch (err:any) {
        throwError(err.data ? err.data.desc : 'Unable to delete user');
      }
    };

    const handleAddUser = async () => {
      try {
        const details = {
          user_name: state.user_name,
          email: state.email,
          mobile: state.mobile,
          password: state.password,
          user_type: 'csi_user',
        };
        const res: any = await addUser(details);
        setState({
          user_name: '',
          email: '',
          mobile: '',
          password: '',
          openModal: false,
          user_id: '',
        });
        handleListUsers({
          page: 1,
          limit: state.page * state.limit,
          search: state.search,
        });
      } catch (err:any) {
        throwError(
          err.status === 422 ? err.data.message : 'Unable to add user',
        );
      }
    };

    const loadMore = async ({ limit, page, search }) => {
      try {
        const data = {
          page,
          limit,
          search,
          type: 'csi_user',
        };
        const res: any = await getAllUser(data);
        const userList = res.data.docs.map((user) => {
          user.author = user.created_by.user_name;
          return user;
        });
        res.data.docs = userList;
        res.data.docs = [...list.docs, ...res.data.docs];
        dispatch({
          type: GET_USERS,
          payload: res.data,
        });
      } catch (err:any) {
        props.throwError(err.data ? err.data.desc : 'Failed to get users');
      }
    };

    const loadMoreInitial = () => {
      setState({ page: state.page + 1 });
      loadMore({
        page: state.page,
        limit: state.limit,
        search: state.search
      });
    };
    const searchChange = () => {};

    const handleDelete = (id) => {
      setState({ confirmModal: true, csi_user_id: id });
    };

    const actionArray = [
      {
        type: 'secondary',
        text: 'Edit User',
        onClick: handleEditUser,
        icon: EditIcon,
      },
      {
        type: 'secondary',
        text: 'Delete User',
        onClick: handleDelete,
        icon: DeleteIcon,
      },
    ];

    return (
      <div className="csi_user_screen">
        <div className="csi_user_container">
          <div className="csi_user_wrapper">
            <div className="csi_user_custom_navbar">
              <CustomNavBar
                data={userNavData}
                onTapClick={handleChangeClick}
                selectedItem={state.selectedData}
                buttonText="Add User"
                buttonType="primary"
                buttonIcon={PlusIcon}
                searchText="Search Text"
                suggession={csiUserSuggessionData}
                buttonOnClick={() =>
                  setState({
                    openModal: true,
                    edit: false,
                    user_name: '',
                    email: '',
                    role: '',
                    mobile: '',
                    password: '',
                  })}
                button
                search={false}
                onSearchChange={searchChange}
              />
            </div>
            {state.loading ? (
              <Spinner />
            ) : (
              <>
                <div className="csi_user_table_wrapper">
                  <Table
                    header={csiUserTableHeading}
                    body={list ? list.docs : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    checkedData={state.checkedList}
                    loadMore={loadMoreInitial}
                    hasMore={list && list.hasNextPage ? list.hasNextPage : ''}
                    actionButton={actionArray}
                    error="No users found"
                  />
                </div>
                <div>
                  <Modal
                    open={state.openModal}
                    onClose={() => setState({ openModal: false })}
                    center
                    classNames={{
                      overlay: 'customOverlay',
                      modal: 'customModal',
                      closeButton: 'closeButton',
                    }}
                    showCloseIcon
                  >
                    <div className="modal_title_wrapper">
                      <div className="modal_title h4">
                        {state.edit !== true ? 'Add User' : 'Edit User'}
                      </div>
                    </div>
                    <div className="modal_line_wrapper"> </div>
                    <div className="modal_input_wrapper">
                      <div className="modal_input">
                        <Input
                          name="User name"
                          onChange={handleOnChange}
                          type="text"
                          inputKey="user_name"
                          value={state.user_name}
                        />
                      </div>
                      <div className="modal_input">
                        <Input
                          name="Email"
                          onChange={handleOnChange}
                          type="email"
                          inputKey="email"
                          value={state.email}
                        />
                      </div>
                      <div className="modal_input">
                        <Input
                          name="Mobile"
                          onChange={handleOnChange}
                          type="tel"
                          inputKey="mobile"
                          value={state.mobile}
                        />
                      </div>
                      <div className="modal_input">
                        <Input
                          name="Password"
                          onChange={handleOnChange}
                          type="password"
                          inputKey="password"
                          value={state.password}
                        />
                      </div>
                    </div>
                    <div className="csi_user_button_wrapper">
                      <div className="csi_user_button">
                        <Button
                          text={state.edit ? 'Update user' : 'Add user'}
                          type="primary"
                          onClick={
                            state.edit ? handleUpdateUser : handleAddUser
                          }
                        />
                      </div>
                    </div>
                  </Modal>
                </div>
                <ConfirmModal
                  openModal={state.confirmModal}
                  description="Are you sure want to delete the Csi user ?"
                  closeModal={() => setState({ confirmModal: false })}
                  deleteConfirm={handleDeleteUser}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
