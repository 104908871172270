import React, { useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './resource_docs.screen.scss';
import Pagination from 'react-js-pagination';
import '../../index.scss';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import Button from 'components/button/button.component';
import ResourcesCard from 'components/resources_card/resources_card';
import { getYear, throwError, useSetState } from 'utils/common.utils';
import SelectBox from 'common_components/ui/select_box/select_box.ui';
import { csiMonthSelectionData } from 'helper/constants.helper';
import PaginationComponent from 'components/pagination/pagination.component';
import Navbar from 'components/navbar/navbar.component';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDepartments, getAllPublication } from "models/public.model";
import { GET_BOOKS, GET_DEPARTMENT, GET_RESOURCES } from 'utils/types.utils';
import { getAllDepartment } from 'models/department.model';
import moment from 'moment';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Eye from '../../assets/icons/Eye.svg'

export default function ResourceDocs(props) {
  const dispatch = useDispatch();
  const departments = useSelector((state: any) => state.department.allDepartments);
  const resources = useSelector((state: any) => state.publication.resources);
  const [state, setState] = useSetState({
    loading: true,
    select_box_data: [],
    page: 1,
    department: '',
    limit: 21,
    year: ''
  });
  const handlePageChange = (pageNumber) => {
    setState({ activePage: pageNumber, page: pageNumber });
  };
  interface DepartmentArray {
    label: string,
    value: string,
  }
  const deptData: DepartmentArray[] = [{ label: 'All', value: '' }];
  const dept = departments && departments.length !== 0
    && departments.map((department) => {
      deptData.push({ label: department.name, value: department._id });
      return "";
    });
  const handleGetAllDepartment = async () => {
    try {
      const department: any = await getAllDepartments();
      if (
        department !== undefined
        && department.data !== undefined
         && department.data.docs !== undefined
      ) {
        dispatch({
          type: GET_DEPARTMENT,
          payload: department.data,
        });
      }
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Department details")
    }
  };
  const handleGetAllBooks = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        sort: true,
        search: '',
        type: 'resources',
      };
      const res: any = await getAllPublication(data);
      const doc = res.data.docs.map((docs) => {
        docs.author = docs.created_by.user_name;
        return docs;
      });
      res.data.docs = doc;
      dispatch({
        type: GET_RESOURCES,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Resource documents")
    }
  };
  useEffect(() => {
    handleGetAllBooks();
    handleGetAllDepartment();
  }, [state.page]);
  const handleApplyFilter = async (department, year) => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: '',
        type: 'resources',
        year_from: year,
        year_to: year,
        department,
      };
      const resource: any = await getAllPublication(data);
      if (
        resource !== undefined && resource.data !== undefined && resource.data.docs !== undefined
      ) {
        setState({ loading: false });
        const doc = resource.data.docs.map((docs) => {
          docs.department_name = docs.department.name;
          docs.author = docs.created_by.user_name;
          docs.date = moment().format('DD/MM/YYYY');
          return docs;
        });
        resource.data.docs = doc;
        dispatch({
          type: GET_RESOURCES,
          payload: resource.data,
        });
      }
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to filter Resource Documents")
    }
  };
  return (
    <div className="screen">
      <div className="header_wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="navbar_wrapper">
          <Navbar {...props} />
        </div>
      </div>
      <div className="resource_docs_screen_header_wrapper">
        <div className="resource_docs_screen_header h2">
          Resource Documents
          <div className="header_line resource" />
        </div>
      </div>
      <div className="resource_docs_screen_container">
      <div className="select_box_wrapper">
        <div className="select_box">
          <SelectBox
            data={deptData ? deptData : []}
            name=""
            selectedItem={state.department}
            onChange={(selectedOption) => {
              handleApplyFilter(selectedOption.value, state.year);
              setState({ department: selectedOption.value })
            }}
          />
        </div>
        <div className="select_box">
          <SelectBox
            data={getYear(1947)}
            name=""
            selectedItem={state.year}
            onChange={(selectedOption) => {
              setState({ year: selectedOption.value })
              handleApplyFilter(state.department, selectedOption.value)
            }}
          />
        </div>
      </div>
        <div className="resource_docs_screen_body_wrapper">
          {state.spinner ? <Spinner /> : resources && resources.docs.length > 0
          ? resources.docs.map((resource) => {
              return (
                <div className="card_wrapper">
                  <ResourcesCard
                    onClick={() => {}}
                    hoverText="View"
                    title={resource.title}
                    icon={Eye}
                    type="publications"
                    url={resource.thumbnail}
                    pdfLink={resource.pdf}
                  />
                </div>
              );
            }) : <div className="error_text">No Resource documents found</div>}
        </div>
      </div>
    <div className="pagination_wrapper">
      <div className="pagination">
        <Pagination
          activePage={state.activePage}
          activeLinkClass="link_active"
          itemsCountPerPage={state.limit}
          totalItemsCount={
            resources && resources.totalDocs ? resources.totalDocs : 4
          }
          pageRangeDisplayed={6}
          firstPageText="<<"
          lastPageText=">>"
          prevPageText="<"
          nextPageText=">"
          itemClassFirst="firstElement"
          onChange={(e) => handlePageChange(e)}
          hideDisabled={
            resources && resources.hasNextPage ? true : false
          }
        />
      </div>
    </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
