import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './home.screen.scss';
import Header from 'components/header/header.component';
import moment from 'moment';
import { Modal } from 'react-responsive-modal';
import Adswiper from 'components/ad_swiper/ad_swiper.component';
import Navbar from 'components/navbar/navbar.component';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import Input from 'common_components/ui/input_box/input_box.ui';
import ArrowSmall from 'assets/icons/Arrow_right_fill_new.svg';
import { useSetState, getMonth, getType, throwError } from 'utils/common.utils';
import { getAllAdvertisment } from 'models/advertisment.model';
import {
  getAllNews,
  getAllEvents,
  getAllVideo,
  getAllPublication,
  getCampaignDetails,
  getAllAdvertismentWithTypes,
  addSubscriber,
} from 'models/public.model';
import { getAllSubscribers } from 'models/news_letter.model';
import {
  GET_DIOCESE_NEWS,
  GET_CSI_LIFE,
  GET_VIDEOS,
  THROW_ERROR,
  GET_DIOCESE_EVENTS,
  GET_BOOKS,
  GET_CAMPAIGNS,
  GET_DEPARTMENT_NEWS,
  GET_ALL_DEPARTMENTS,
  GET_ALL_DIOCESES,
  GET_ADVERTISEMENT,
  GET_SUBSCRIBER,
  GET_ADVERTISEMENT_WITH_TYPES,
  GET_DEPARTMENT_EVENTS,
} from 'utils/types.utils';
import Newscard1 from 'components/news_card_1/news_card_1.component';
import SmallNewsCard from 'components/small_news_card/small_news_card';
import { toast } from 'react-toastify';
import { getAllDioceses } from 'models/diocese.model';
import Newscard2 from 'components/news_card_2/news_card_2.component';
import NewsCardSmall from 'components/news_card_small/news_card_small';
import EventCard from 'components/events_card/events_card.component';
import Filter from 'components/filter_component/filter.component';
import Button from 'components/button/button.component';
import SynodVideo from 'components/synod_video/synod_video.component';
import WorShip from 'components/worship_resources/worship_resources.component';
import { getAllDepartments } from 'models/department.model';
import LandingPageScreen from 'screens/Landing/Landing_page.screen';
import Magazine from 'components/magazine/magazine.component';
import Publication from 'components/swiper/swiper.component';
import Download from 'components/download_app/download_app.component';
import Subscriber from 'components/subscribe/subscribe.component';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import { Link } from 'react-router-dom';
import Footer from 'components/footer/footer.component';
import { videoContent, ads } from 'helper/constants.helper';
import Loader from 'components/content_loader/content_loader';
import Background from '../../assets/images/wallpaper.jpg';
import Arrow from '../../assets/icons/Arrow Line_Right Large Copy 11.svg';

export default function Home(props) {
  const news = useSelector((state: any) => state.diocese.news);
  const departmentNews = useSelector((state: any) => state.department.news);
  const events = useSelector((state: any) => state.department.events);
  const video = useSelector((state: any) => state.video);
  const csi = useSelector((state: any) => state.publication.csi_life);
  const books = useSelector((state: any) => state.publication.books);
  const campaign = useSelector((state: any) => state.campaigns);
  const departments = useSelector(
    (state: any) => state.department.allDepartments,
  );
  const myRef: any = React.createRef()
  const advertisementData = useSelector((state: any) => state.advertisement);
  const advertisementWithTypes = useSelector(
    (state: any) => state.advertisement.advertisementTypes,
  );
  // const platinumAds = advertisementData
  // && advertisementData.docs && advertisementData.docs.filter((ad) => ad.type === 'platinum')
  // const goldAds = advertisementData
  // && advertisementData.docs && advertisementData.docs.filter((ad) => ad.type === 'gold')
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    selected_filter: '',
    limit: 20,
    page: 1,
    search: '',
    landingPage: true,
    activated: 'all',
    content_loader: true,
    openModal: false,
    homePage: true,
    subscribeModal: false,
    name: '',
    email: '',
    mobile: '',
    loading: true,
    url: '',
  });

  const handleFilter = (filter) => {
    setState({ selected_filter: filter });
    document.getElementById(filter)?.scrollIntoView();
  };

  const handleGetAllDioceseNews = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        activated: true,
        type: 'dioceses',
      };
      const res: any = await getAllNews(data);
      getType(data.type, res.data.docs);
      dispatch({
        type: GET_DIOCESE_NEWS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Diocese news")
    }
  };

  const handleGetAllDepartmentNews = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        type: 'departments',
        activated: true,
      };
      const newsData: any = await getAllNews(data);
      getType(data.type, newsData.data.docs);
      dispatch({
        type: GET_DEPARTMENT_NEWS,
        payload: newsData.data,
      });
      setState({ loading: false, content_loader: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Department news")
    }
  };

  const handleGetAllDepartmentEvents = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        type: 'departments',
      };
      const res: any = await getAllEvents(data);
      getType(data.type, res.data.docs);
      dispatch({
        type: GET_DEPARTMENT_EVENTS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Department events")
    }
  };

  const getVideos = async () => {
    try {
      const data = {
        limit: 4,
        page: state.page,
        search: state.search,
        activated: true,
      };
      const videos: any = await getAllVideo(data);
      dispatch({
        type: GET_VIDEOS,
        payload: videos.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get CSI videos")
    }
  };

  const handleGetAllCsiLife = async () => {
    try {
      const data = {
        limit: state.limit,
        page: 1,
        sort: true,
        search: state.searchValue,
        type: 'csi_life',
        special_edition: 'all',
      };
      const csiLife: any = await getAllPublication(data);
      dispatch({
        type: GET_CSI_LIFE,
        payload: csiLife.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get CSI Life details")
    }
  };

  const handleGetAllBooks = async () => {
    try {
      const data = {
        limit: state.limit,
        page: 1,
        search: state.searchValue,
        activated: true,
        sort: true,
        type: 'books',
      };
      const book: any = await getAllPublication(data);
      getType(data.type, book.data.docs);
      dispatch({
        type: GET_BOOKS,
        payload: book.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Publication details")
    }
  };

  const handleGetAllCampaignDetails = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: state.search,
        activated: true,
      };
      const res: any = await getCampaignDetails(data);
      const details = res.data.docs.map((doc) => {
        doc.description = doc.description.replace(/(<([^>]+)>)/gi, '');
        return doc;
      });
      res.data.docs = details;
      dispatch({
        type: GET_CAMPAIGNS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Campaign details")
    }
  };

  // const handleGetAllAdvertisment = async ({
  //   page,
  //   limit,
  //   search,
  //   activated,
  // }) => {
  //   try {
  //     const data = {
  //       limit,
  //       page,
  //       search,
  //       activated: true,
  //     };
  //     const advertisement: any = await getAllAdvertisment(data);
  //     if (advertisement) {
  //       setState({ advertisement: advertisement.data.docs });
  //       const doc = advertisement.data.docs.map((docs) => {
  //         docs.author = docs.created_by.user_name;
  //         return docs;
  //       });
  //       advertisement.data.docs = doc;
  //       dispatch({
  //         type: GET_ADVERTISEMENT,
  //         payload: advertisement.data,
  //       });
  //     setState({ loading: false });
  //     }
  //   } catch (err:any) {
  //     throwError(err.data ? err.data.desc : "Failed to get Advertisements")
  //   }
  // };

  const handleGetAllAdvertismentWithTypes = async ({
    page,
    limit,
    search,
    activated,
  }) => {
    try {
      const data = {
        limit,
        page,
        search,
        activated: true,
      };
      const advertisement: any = await getAllAdvertismentWithTypes();
      dispatch({
        type: GET_ADVERTISEMENT_WITH_TYPES,
        payload: advertisement.data,
      });
      setState({ loading: false });
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Advertisements")
    }
  };

  const handleGetAllSubscriber = async ({ limit, page, search }) => {
    try {
      const data = { limit, page, search };
      const subscriber: any = await getAllSubscribers(data);
      if (subscriber !== undefined && subscriber.data !== undefined) {
        const doc = subscriber.data.docs.map((docs) => {
          // docs.diocese = docs.diocese.name;
          docs.created_at = moment(docs.created_at).format('DD-MM-YYYY');
          return docs;
        });
        subscriber.data.docs = doc;
        dispatch({
          type: GET_SUBSCRIBER,
          payload: subscriber.data,
        });
        setState({ loading: false });
      }
    } catch (err:any) {
      throwError(err.data ? err.data.desc : "Failed to get Subscriber Details")
    }
  };

  const handleAddsubscriber = async () => {
    if (state.email === "" || state.name === "") {
      toast.error("Please enter all the details")
    } else {
    try {
      const data = {
        name: state.name,
        email: state.email,
        phone: state.mobile,
      };
      const subscriber: any = await addSubscriber(data);
      await handleGetAllSubscriber({
        limit: state.limit,
        search: state.search,
        page: state.page,
      });
      setState({ subscribeModal: false, edit: false });
    } catch (err:any) {
      throwError(err.data ? err.data.message : err.data.desc)
  }
 }
}
  const getAdvertisementBasedOnId = (item) => {
    setState({ openModal: true, banner: item.banner_image, url: item.url });
  };
  const handleScroll = (event: any) => {
    // const { scrollTop } = event.srcElement.body
    // const itemTranslate = Math.min(0, scrollTop / 3 - 60);
    // console.log(itemTranslate)
  }
  window.addEventListener('scroll', handleScroll);
  useEffect(() => {
    handleGetAllDioceseNews();
    handleGetAllDepartmentNews();
    handleGetAllDepartmentEvents();
    getVideos();
    handleGetAllCsiLife();
    handleGetAllBooks();
    handleGetAllCampaignDetails();
    handleGetAllAdvertismentWithTypes(state);
  }, []);

  const handleSmallNewsClick = () => {
    // Api call
  };

  const handleInputChange = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  const loadMoreNews = () => {
    props.history.push('/news_list_screen/dioceses');
  };

  const loadMoreEvent = () => {
    props.history.push('/home/event/departments');
  };
  const scrollToHome = () => {
    setState({ homePage: true })
    setTimeout(() => document.getElementById("main")?.scrollIntoView(), 300)
    setTimeout(() => { setState({ landingPage: false }) }, 1000);
  }

  return (
    <div className="home_screen" onScroll={() => {}}>
      {/* {state.landingPage
      ? <div className="landing_page_wrapper">
          <LandingPageScreen onClick={
            () => { scrollToHome() }
        } />
        </div> : ''} */}
   <div className="home_container" id="main">
        <div className="home_wrapper">
          <Header />
        </div>
        <div className="home_wrapper" style={{ position: 'sticky', top: '0', zIndex: 9999 }}>
          <Navbar {...props} />
        </div>
        {state.loading ? <Spinner />
      : <>
          {state.content_loader
          ? <Loader />
        : <>
        <div className="home_wrapper home_news_card_wrapper">
            <SmallNewsCard
              date=""
              text=""
              url={Background}
              data={
                departmentNews && departmentNews.docs ? departmentNews.docs.slice(0, 6) : []
              }
              className="csi_synod_news_swiper"
              Click={(id) =>
                props.history.push(`/news_screen/departments/${id}`)}
              types="synodNews"
            />
        </div>
        <div className="home_wrapper">
            <NewsCardSmall
              date=""
              text=""
              url={Background}
              data={
                departmentNews && departmentNews.docs ? departmentNews.docs.slice(6, 10) : []
              }
              className="synod_news_swiper"
              Click={(id) =>
                props.history.push(`/news_screen/departments/${id}`)}
              types=""
            />
        </div>
          </>}
        <div className="home_wrapper filter_box">
          <Filter selected={state.selected_filter} onClick={handleFilter} />
        </div>
        <div id="news" className="home_wrapper">
          <div className="news_card_envent_list">
            <div className="news_card_envent_list_container">
              <div className="news_card_wrapper">
                <div className="news_title p2">Diocese News</div>
                  {news && news.docs && news.docs.length !== 0 && (
                    <div className="news_content">
                      <Newscard1
                        title={news.docs[0].title}
                        description={moment(
                          news.docs[0].date,
                        ).format('dddd, MMM D, YYYY')}
                        name={news.docs[0].diocese_name}
                        link={news.docs[0].thumbnail}
                        onClick={() =>
                          props.history.push(
                            `/news_screen/dioceses/${
                              news.docs[0]._id
                            }`,
                          )}
                        type="news_card"
                      />
                    </div>
                  )}
                <div className="news_content_2">
                  {
                    news && news.docs && news.docs.slice(1, 4).map(
                      (m, index) =>
                          <div className="news_card_details">
                            <Newscard2
                              title={m.title}
                              name={m.diocese_name}
                              description={moment(m.date).format(
                                'dddd, MMM D, YYYY',
                              )}
                              link={m.thumbnail}
                              onClick={() =>
                                props.history.push(
                                  `/news_screen/dioceses/${m._id}`,
                                )}
                              type="event"
                              text={m.heading}
                            />
                          </div>
                        )
}
                </div>
                {
                  news && news.docs && news.docs.length > 2 && (
                    <div className="load_more_btn">
                      <div className="btn_content">
                        <Button
                          text="View More"
                          className=""
                          onClick={loadMoreNews}
                        />
                      </div>
                    </div>
                  )
}
              </div>
              <div className="event_wrapper">
                <div className="event_title p2">CSI Synod Events</div>

                <div className="event_content">
                  {
                    events && events.docs.length > 0 ? events.docs.map(
                      (m, index) =>
                        index < 3 && (
                          <div className="news_card_details">
                            <EventCard
                              title={m.department_name}
                              description={m.name}
                              image={m.thumbnail}
                              day={`${moment(m.from_date).format('DD')}`}
                              month={`${moment(m.from_date).format('MMM')}`}
                              className="news_card_image"
                            />
                          </div>
                        ),
                    ) : <div className="h5 no_events_title">No upcoming events</div>
                }
                </div>
                {
                  events && events.docs && events.docs.length > 2 && (
                    <div className="load_more_btn">
                      <div className="btn_content">
                        <Button
                          text="View More"
                          className=""
                          onClick={loadMoreEvent}
                        />
                      </div>
                    </div>
                  )
}
              </div>
            </div>
          </div>
        </div>
        <div id="videos" className="home_wrapper">
          <div className="home_csi_video">
            <div className="video_content">
              <div className="home_csi_head_wrapper">
                <div className="home_csi_title h2">CSI Synod Video</div>
                <div className="head_line"> </div>
                <div className="csi_video_action">
                  <div className="text" onClick={() => props.history.push("/csi_videos_screen")}>View More</div>
                  <img
                    src={Arrow}
                    alt="csi_video_view_more_arrow"
                    className="arrow_icon"
                  />
                </div>
              </div>
              <div className="video_carousel">
                <div className="video_content_carousel">
                  {state.loading ? (
                    <Spinner />
                  ) : (
                    <SynodVideo data={video && video.docs ? video.docs : []} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home_wrapper">
          <div className="worship_wrapper">
            <div className="home_worship_container">
              {state.loading ? <Spinner />
              : <WorShip {...props} />}
            </div>
          </div>
        </div>
        <div id="resources" className="home_wrapper">
          <div className="home_magazine_container">
            {state.loading ? (
              <Spinner />
            ) : (
              <Magazine
                data={csi && csi.docs ? csi.docs : []}
                Click={() => props.history.push('/resources/csi_life_screen')}
              />
            )}
          </div>
        </div>
        <div className="home_wrapper">
          <div className="home_csi_video">
            <div id="publications" className="video_content">
              <div className="home_csi_head_wrapper">
                <div className="home_csi_title h2">Publications</div>
                <div className="head_line"> </div>
                <div className="csi_video_action">
                  <div
                    className="text"
                    onClick={() =>
                      props.history.push('/resources/publications_screen')}
                  >
                    View More
                  </div>
                  <img
                    src={ArrowSmall}
                    alt="csi_video_view_more_arrow"
                    className="arrow_icon"
                  />
                </div>
              </div>
              <div id="publications" className="publication_carousel">
                <div className="publication_content_carousel">
                  {state.loading ? (
                    <Spinner />
                  ) : (
                    <Publication
                      className="publication_image"
                      types="publication"
                      orderNow={(id) =>
                        props.history.push(`/publication_details/${id}`)}
                      data={books && books.docs ? books.docs : []}
                      number={3}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home_wrapper">
          <div className="subscriber">
            <div className="subscriber_home">
              <div className="subscriber_wrapper">
                <Download {...props} />
              </div>
              <div className="subscriber_wrapper">
                <Subscriber
                  onClick={() =>
                    setState({
                      subscribeModal: true,
                      name: '',
                      email: '',
                      mobile: '',
                    })}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="campaigns" className="home_wrapper">
          <div className="campaigns_and_ads">
            <div className="content">
              <div className="campaigns_and_ads_head_wrapper">
                <div className="campaigns_home_wrapper">
                  <div className="campaigns_and_ads_title h2">Campaigns</div>
                  {/* <div
                    className="campaigns_and_ads_action"
                    onClick={() =>
                      props.history.push('/about/campaign_list_screen')}
                  >
                    <div className="text">View More</div>
                    <img
                      src={ArrowSmall}
                      alt="csi_video_view_more_arrow"
                      className="arrow_icon"
                    />
                  </div> */}
                </div>
                <div className="campaigns_home_advertisement_wrapper h2">
                  <div className="advertisement_title h2">Advertisement
                <div className="head_line"> </div>
                  </div>
                </div>
              </div>
              <div className="add_campaign_content">
                <div className="home_campaigns_wrapper">
                  {state.loading ? (
                    <Spinner />
                  ) : (
                    campaign && campaign.docs
                    && campaign.docs.slice(0, 2).map((campaignsData, index) => (
                      <div className="news_card_2_campaign">
                        <Newscard2
                          title={campaignsData.title}
                          description={moment(campaignsData.created_at).format(
                            'dddd, MMM D, YYYY',
                          )}
                          link={campaignsData.thumbnail}
                          onClick={() =>
                            props.history.push(
                              `/campaign_screen/${campaignsData._id}`,
                            )}
                          type="campaign"
                          text={campaignsData.description}
                          // reverse={(index + 1) % 2 === 0 ? false : true}
                          reverse={false}
                        />
                      </div>
                    ))
                  )}
                  <div className="advertisement_button_wrapper">
                    <Button
                      text="View More"
                      className=""
                      onClick={() => props.history.push('/about/campaign_list_screen')}
                    />
                  </div>
                </div>
                <div className="add_wrapper">
                  <div className="advertisement_platinum_swiper">
                    {state.loading ? (
                      <Spinner />
                    ) : (
                      advertisementWithTypes && advertisementWithTypes.length > 0
                    ? <Adswiper
                        className="advertisement_platinum_swiper_image"
                        data={
                          advertisementWithTypes
                          && advertisementWithTypes[0] ? advertisementWithTypes[0].platinum : []
                        }
                        onClick={(index, item) =>
                          getAdvertisementBasedOnId(item)}
                    /> : "No active Platinum advertisements"
                    )}
                  </div>
                  {/* <div className="advertisement_gold_swiper">
                    {state.loading ? (
                      <Spinner />
                    ) : (
                      goldAds && goldAds.length > 0
                    ? <Adswiper
                        className="advertisement_gold_swiper_image"
                        data={
                          goldAds && goldAds.length > 0
                            ? goldAds.slice(0, 2)
                            : []
                        }
                        onClick={(index, item) =>
                          getAdvertisementBasedOnId(item)}
                    /> : "No active Gold advertisements"
                    )}
                  </div> */}
                  <div className="advertisement_button_wrapper">
                    <Button
                      text="View More"
                      className=""
                      onClick={() => props.history.push('/home/advertisement')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home_wrapper">
          <div className="home_footer">
            <Footer
              onClick={() =>
                setState({
                  subscribeModal: true,
                  name: '',
                  email: '',
                  mobile: '',
                })}
            />
          </div>
        </div>
        <div className="home_wrapper">
          <div className="home_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'advertisementModal',
                closeButton: 'closeButton',
              }}
              animationDuration={700}
              showCloseIcon
            >
              <div className="advertisement_modal_container">
                <div className="advertisement_modal_image_wrapper">
                  <img
                    src={state.banner}
                    className="advertisement_banner"
                    alt="advertisement_modal"
                    width="400px"
                    height="620px"
                  />
                </div>
                <div className="advertisement_button_wrapper">
                  <Button
                    text="Know More"
                    className=""
                    onClick={() => window.open(state.url, '_blank')}
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="home_wrapper">
          <div className="home_modal_subscribe_container">
            <Modal
              open={state.subscribeModal}
              onClose={() => setState({ subscribeModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'subscribeModal',
                closeButton: 'subscribe_close_button',
              }}
              showCloseIcon
            >
              <div className="subscription_modal_container">
                <div className="subscription_title_wrapper">
                  <div className="subscription_title h2">
                    CSI SYNOD NewsLetter
                  </div>
                  <div className="subscription_line_wrapper"> </div>
                </div>
                <div className="subscription_description_wrapper">
                  <div className="subscription_description h4">
                    Subscribe to receive the latest information from CSI SYNOD
                    in your Inbox !
                  </div>
                </div>
                <div className="subscription_input_wrapper">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder=" *  Enter Your Name"
                    value={state.name}
                    className="subscription_input"
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder=" *  Enter Your Email ID"
                    value={state.email}
                    className="subscription_input"
                    onChange={handleInputChange}
                  />
                  <input
                    type="number"
                    id="mobile"
                    name="mobile"
                    placeholder=" *  Enter Your Mobile Number"
                    value={state.mobile}
                    className="subscription_input"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="subscription_button_wrapper">
                  <Button
                    text="Subscribe"
                    className="subscription_button"
                    onClick={handleAddsubscriber}
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
        </>}
   </div>
    </div>
  );
}
