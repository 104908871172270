/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import _ from "lodash";
import { uploadMedia, uploadMultiple } from "models/upload_media.model";
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';
import Nandyal from '../assets/images/diocese_logos/Nandyal-Diocese.png'
import KrishnaGodavari from '../assets/images/diocese_logos/csi-krishna-godavari-diocese-seal.png'
import Rayalaseema from '../assets/images/diocese_logos/Rayalaseema.png'
import Cochin from '../assets/images/diocese_logos/Cochin-Diocese.png'
import KarnatakaCentral from '../assets/images/diocese_logos/Karnataka-Central.png'
import KarnatakaNorthern from '../assets/images/diocese_logos/Karnataka-Northern-diocese.png'
import KarnatakaSouthern from '../assets/images/diocese_logos/Karnataka-Southern.png'
import Kollam from '../assets/images/diocese_logos/Kollam-Kottarakkara-Diocese.png'
import MadhyaKerala from '../assets/images/diocese_logos/Madhya-Kerala-Diocese.png'
import Madras from '../assets/images/diocese_logos/Madras-Diocese.png'
import MaduraiRamnad from '../assets/images/diocese_logos/Madurai-Ramanad.png'
import Malabar from '../assets/images/diocese_logos/Malabar-Diocese.png'
import Medak from '../assets/images/diocese_logos/Medak.png'
import SouthKerala from '../assets/images/diocese_logos/South-Kerala.png'
import ThoothukudiNazareth from '../assets/images/diocese_logos/Thoothukudi-Nazarath.png'
import Tirunelveli from '../assets/images/diocese_logos/Tirunelveli-Diocese.png'
import TrichyTanjore from '../assets/images/diocese_logos/Trichy-Tanjore-diocese.png'
import Vellore from '../assets/images/diocese_logos/Vellore-Diocese.png'
import Dornakal from '../assets/images/diocese_logos/Dornakal-Diocese.png'
import Coimbatore from '../assets/images/diocese_logos/Coimbatore-Diocese.png'
import Kanyakumari from '../assets/images/diocese_logos/Kanyakumari.png'
import EastKerala from '../assets/images/diocese_logos/East-Kerala.png'
import Jaffna from '../assets/images/diocese_logos/Jaffna-Diocese.png'
import Karimnagar from '../assets/images/diocese_logos/Karimnagar-Diocese.png'

export const useSetState = (initialState: any) => {
  const [state, setState] = useState(initialState)

  const newSetState = (newState: any) => {
    setState((prevState: any) => ({ ...prevState, ...newState }))
  }
  return [state, newSetState]
}

export const handleOnChangeList = (selectedData, multiple) => {
  const [state, setState] = useSetState({ checkList: [] });
  if (multiple === false) {
    // setState({ checkList: [selectedData] });
  } else {
    const findIndex = _.findIndex(state.checkList, { index: selectedData.index });
    if (findIndex === -1) {
      // setState({ checkList: [...state.checkList, ...[selectedData]] });
    } else {
      const indexes = [];
      state.checkList.forEach((data) => {
        if (data.index !== selectedData.index) {
          // indexes.push(data);
        }
      })
      // setState({ checkList: indexes });
      return indexes;
    }
  }
};
export const getType = (type, response) => {
  const doc = response.map((docs) => {
    if (type === 'departments') {
      docs.department_name = docs.departments.name;
      docs.author_name = docs.author.user_name
      docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
    }
    if (type === 'dioceses') {
      docs.diocese_name = docs.dioceses.name;
      docs.author_name = docs.author.user_name
      docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
    }
    if (type === 'all') {
        if (docs.diocese) {
          docs.diocese_name = docs.diocese.name;
        }
        if (docs.department) {
          docs.department_name = docs.department.name
        }
      docs.author_name = docs.created_by.user_name
      docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
    }
    docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
    return docs;
  });
}
export const throwError = (text) => {
toast.error(text)
}

export const getYear = (fromYear) => {
  const year: any[] = [{ label: 'All', value: '' }];
  const currentYear = (new Date()).getFullYear();
  for (let i = fromYear; i <= currentYear; i += 1) {
    year.push({
      label: i,
      value: i
    });
  }
  return year
}

export const getMonth = (month) => {
  if (month === 1) {
    return "January"
  }

  if (month === 2) {
    return " February"
  }

  if (month === 3) {
    return "March"
  }

  if (month === 4) {
    return "April"
  }

  if (month === 5) {
    return "May"
  }

  if (month === 6) {
    return "June"
  }

  if (month === 7) {
    return "July"
  }

  if (month === 8) {
    return "August"
  }

  if (month === 9) {
    return "September"
  }

  if (month === 10) {
    return "October"
  }

  if (month === 11) {
    return "November"
  }

  if (month === 12) {
    return "December"
  }
}
export const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.9,
      maxWidth: 1000,
      success(result) {
        resolve(result);
      },
      error(result) {
        reject(result)
      }
    })
  })
}
export const uploadPdf = async (e) => {
  try {
    if (e.dataTransfer) {
      const data = new FormData();
      const ext = e.dataTransfer.files[0].name.split('.').pop();
      const filename = e.dataTransfer.files[0].name
        .split('.')[0]
        .replace(/ /g, '');
      const newFileName = `${filename}${Date.now()}.${ext}`;
      const newFile = new File([e.dataTransfer.files[0]], newFileName);
      data.append('file', newFile);
      const image = await uploadMedia(data);
      return image;
    }
    if (e.target) {
      const data = new FormData();
      const ext = e.target.files[0].name.split('.').pop();
      const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '')
      const newFileName = `${filename}${Date.now()}.${ext}`;
      const newFile = new File([e.target.files[0]], newFileName);
      data.append('file', newFile);
      const image: any = await uploadMedia(data);
      return image
    }
  } catch (err:any) {
    return err;
  }
}

export const imageMultiple = async (e) => {
  try {
    if (e.dataTransfer) {
      const dragData = new FormData();
      [...e.dataTransfer.files].map(async (m, index) => {
        console.log(m)
        const ext = e.dataTransfer.files[index].name.split('.').pop();
        const filename = e.dataTransfer.files[index].name.split('.')[0].replace(/ /g, '')
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.dataTransfer.files[index]], newFileName);
        dragData.append('file', newFile);
        return ""
      });
      const image: any = await uploadMultiple(dragData);
      return image;
    }
    if (e.target) {
      const data = new FormData();
      const fileArr: any = [];
        [...e.target.files].map(async (m, index) => {
          const compressedFile: any = await compressImage(m)
          console.log('compress', compressedFile.name)
          const ext = compressedFile.name.split('.').pop();
          const filename = compressedFile.name.split('.')[0].replace(/ /g, '')
          const newFileName = `${filename}${Date.now()}.${ext}`;
          console.log(compressedFile.name)
          const newFile: any = new File([compressedFile], newFileName);
          console.log(newFile)
          fileArr.push(newFile)
          data.append('file', 'newFile');
        });
      console.log(data)
      const image: any = await uploadMultiple(data);
      return image;
    }
  } catch (err:any) {
    return err;
  }
}
export const compressImageNew = (file: File) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.5,
      maxWidth: 1000,
      success: (result) => {
        resolve(new File([result], file.name, { type: result.type }));
      },
      error: (error: Error) => reject(error)
    })
  })
}

export const uploadMultipleFiles = async (e) => {
  try {
    if (e.target.files) {
      const formData = new FormData();
      const files = e?.target.files;
      const compressPromises: any = [];
      for (const file of files) {
        compressPromises.push(compressImageNew(file));
      }
      const compressedFiles = await Promise.all(compressPromises);
      compressedFiles.map((file: any) => {
        const ext = file.name.split('.').pop();
        const filename = file.name.split('.')[0].replace(/ /g, '')
        const newFileName = `${filename}${Date.now()}.${ext}`;
        formData.append('file', file, newFileName);
        return "";
      })
      const uploadImage = await uploadMultiple(formData);
      return uploadImage
    } if (e.dataTransfer.files) {
      console.log(e.dataTransfer.files)
      const formData = new FormData();
      const files = e?.dataTransfer.files;
      console.log(files)
      const compressPromises: any = [];
      for (const file of files) {
        compressPromises.push(compressImageNew(file));
      }
      const compressedFiles = await Promise.all(compressPromises);
      compressedFiles.map((file: any) => {
        const ext = file.name.split('.').pop();
        const filename = file.name.split('.')[0].replace(/ /g, '')
        const newFileName = `${filename}${Date.now()}.${ext}`;
        formData.append('file', file, newFileName);
        return "";
      })
      const uploadImage = await uploadMultiple(formData);
      console.log(uploadImage)
      return uploadImage
    }
  } catch (err:any) {
    console.log(err);
    return err;
  }
}
export const uploadMediaFiles = async (e) => {
  try {
    if (e.dataTransfer) {
      const data = new FormData();
      const ext = e.dataTransfer.files[0].name.split('.').pop();
      const filename = e.dataTransfer.files[0].name
        .split('.')[0]
        .replace(/ /g, '');
      const newFileName = `${filename}${Date.now()}.${ext}`;
      const newFile = new File([e.dataTransfer.files[0]], newFileName);
      const compressedFile: any = await compressImage(e.dataTransfer.files[0])
      data.append('file', compressedFile, compressedFile.name);
      const image = await uploadMedia(data);
      return image;
    }
    if (e.target) {
      const data = new FormData();
      const ext = e.target.files[0].name.split('.').pop();
      const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '')
      const newFileName = `${filename}${Date.now()}.${ext}`;
      const newFile = new File([e.target.files[0]], newFileName);
      const compressedFile: any = await compressImage(e.target.files[0])
      data.append('file', compressedFile, compressedFile.name);
      const image: any = await uploadMedia(data);
      return image
    }
  } catch (err:any) {
    return err;
  }
}

const dioceseSubdomain = [
  'krishnagodavari',
  'nandyal',
  'rayalaseema',
  'karnatakacentral',
  'karnatakanorthern',
  'karnatakasouthern',
  'coimbatore',
  'kanyakumari',
  'jaffna',
  'madras',
  'madurairamnad',
  'thoothukudinazareth',
  'tirunelveli',
  'trichytanjore',
  'vellore',
  'cochin',
  'eastkerala',
  'southkerala',
  'kollamkottarakkara',
  'madhyakerala',
  'malabar',
  'dornakal',
  'karimnagar',
  'medak'
]
export const logos = [
  {
      name: 'krishnagodavari',
      image: KrishnaGodavari
  },
  {
      name: 'nandyal',
      image: Nandyal
  },
  {
      name: 'rayalaseema',
      image: Rayalaseema
  },
  {
      name: 'karnatakacentral',
      image: KarnatakaCentral
  },
  {
      name: 'karnatakanorthern',
      image: KarnatakaNorthern
  },
  {
      name: 'karnatakasouthern',
      image: KarnatakaSouthern
  },
  {
      name: 'coimbatore',
      image: Coimbatore
  },
  {
      name: 'kanyakumari',
      image: Kanyakumari
  },
  {
      name: 'madras',
      image: Madras
  },
  {
      name: 'madurairamnad',
      image: MaduraiRamnad
  },
  {
      name: 'thoothukudinazareth',
      image: ThoothukudiNazareth
  },
  {
      name: 'tirunelveli',
      image: Tirunelveli
  },
  {
      name: 'trichytanjore',
      image: TrichyTanjore
  },
  {
      name: 'vellore',
      image: Vellore
  },
  {
      name: 'cochin',
      image: Cochin
  },
  {
      name: 'eastkerala',
      image: EastKerala
  },
  {
      name: 'southkerala',
      image: SouthKerala
  },
  {
      name: 'kollamkottarakkara',
      image: Kollam
  },
  {
      name: 'madhyakerala',
      image: MadhyaKerala
  },
  {
      name: 'malabar',
      image: Malabar
  },
  {
      name: 'dornakal',
      image: Dornakal
  },
  {
      name: 'karimnagar',
      image: Karimnagar
  },
  {
      name: 'medak',
      image: Medak
  },
  {
    name: 'jaffna',
    image: Jaffna
  }
]
export const findSubDomain = (name) => {
  if (dioceseSubdomain.includes(name)) {
    return true
  }
  return false
}
