/* eslint-disable max-len */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "screens/user_screens/diocese.screen.scss";
import NewsCard1 from "components/news_card_1/news_card_1.component";
import parse from 'html-react-parser';
import Logo from "assets/images/CSI_Logo.png";
import Wallpaper from "assets/images/wallpaper.jpg";
import TextArea from "common_components/ui/text_area/text_area.ui";
import PdfFile from "common_components/ui/pdf_file/pdf_file.component";
import NewsCard2 from "components/news_card_2/news_card_2.component";
import Adswiper from "components/ad_swiper/ad_swiper.component";
import Inactive from "assets/icons/Inactive_black.svg";
import EventCard from "components/events_card/events_card.component";
import { GET_DIOCESE_NEWS, GET_DIOCESE, THROW_ERROR, GET_USERS, GET_DIOCESE_EVENTS } from 'utils/types.utils';
import { getAllNews, getAllEvents, getDioceseById, getAllUser, sendQuery, getAllDiocesesBySubDomain } from "models/public.model";
import Map from "components/google_maps/google_maps.component";
import moment from 'moment';
import Button from "components/button/button.component";
import OfficerCard from "components/officer_card/officer_card.component";
import Swiper from "components/swiper/swiper.component";
import Input from "common_components/ui/input_box/input_box.ui";
import HamberFill from '../../assets/icons/hamburger.svg';
import publicationBird from "../../assets/images/publication_bird.png";
import { throwError, useSetState, logos } from '../../utils/common.utils';

export default function Diocese(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const dioceseNews = useSelector((state: any) => state.diocese.news);
    const dioceseData = useSelector((state: any) => state.diocese.diocese);
    const personnelData = useSelector((state: any) => state.users.user_list);
    const parsedData = window.location.host.split('.');
    const events = useSelector((state: any) => state.diocese.events);
    const [state, setState] = useSetState({ name: "", email: "", address: "", subject: "", message: "", search: "", activated: "", limit: 20, page: 1, id: '', logo: '' });

    const handleOnChange = (e, key) => {
        setState({ [key]: e.target.value });
    }

    const handleOnSubmit = () => {
        // Api call
    }

    const getDioceseLogo = () => {
        let link: any = document.querySelector("link[rel~='icon']");
      const pic: any = logos.filter((logo) => logo.name === parsedData[0])
      setState({ logo: pic[0].image })
        if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = pic[0].image;
    }
    const contactUs = async () => {
        try {
          const data = {
            message: state.message,
            email: state.email,
            name: state.name,
            subject: state.subject,
            type: state.id
          }
          const res: any = await sendQuery(data)
          setState({
            message: '',
            name: '',
            email: '',
            subject: '',
          })
          toast.error('Details sent')
        } catch (error:any) {
          throwError(error.data ? error.data.desc : "Failed to send details")
        }
      }
    const handleGetAllDioceseNews = async (dioceseId) => {
        try {
            const data = {
                limit: state.limit,
                page: state.page,
                search: state.search,
                type: 'dioceses',
                activated: true,
                diocese: dioceseId ? [dioceseId] : ""
                // diocese: ['604eec56e0f1281b66e12161'],
            };
            const res: any = await getAllNews(data);
            const doc = res.data.docs.map((docs) => {
                docs.diocese_name = docs.dioceses.name;
                docs.author = docs.author.user_name;
                docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
                return docs;
            });
            res.data.docs = doc;
            dispatch({
                type: GET_DIOCESE_NEWS,
                payload: res.data,
            });
            setState({ loading: false });
        } catch (err:any) {
            throwError(
                err.data ? err.data.desc : 'Failed to load diocese news',
            );
        }
    };

    const handleGetAllDioceseEvents = async (dioceseId) => {
        try {
            const data = {
              limit: state.limit,
              page: state.page,
              search: state.search,
              type: 'dioceses',
              diocese: dioceseId ? [dioceseId] : ""
            // diocese: ['604eec56e0f1281b66e12161'],
            };
            const res: any = await getAllEvents(data);
            const details = res.data.docs.map((doc) => {
              doc.diocese = doc.dioceses.name;
              doc.date = moment(doc.from_date).format('DD-MM-YYYY');
              return doc;
            });
            res.data.docs = details
            dispatch({
              type: GET_DIOCESE_EVENTS,
              payload: res.data,
            });
            setState({ loading: false });
          } catch (err:any) {
            throwError(err.data ? err.data.desc : "Failed to get Diocese Events")
        }
      };

    const handleGetAllPersonnels = async (dioceseId) => {
        try {
            const data = {
                limit: state.limit,
                page: state.page,
                user_sort: true,
                search: '',
                diocese: dioceseId ? [dioceseId] : "",
                // diocese: ['604eec56e0f1281b66e12161'],
                type: 'diocese_personnels',
            };
            const res: any = await getAllUser(data);
            dispatch({
                type: GET_USERS,
                payload: res.data,
            });
            setState({ loading: false });
        } catch (err:any) {
            throwError(err.data ? err.data.desc : "Failed to get Diocese Personnels")
        }
    };
    const handleGetAllDiocese = async () => {
        try {
            const data = {
                limit: 35,
                page: state.page,
                search: state.search,
                sub_domain: parsedData ? parsedData[0] : ""
                // sub_domain: 'rayalaseema'
            };

            const res: any = await getAllDiocesesBySubDomain(data);
            if (res && res.data._id !== undefined) {
                handleGetAllDioceseNews(res.data._id);
                handleGetAllDioceseEvents(res.data._id);
                handleGetAllPersonnels(res.data._id);
            }
            dispatch({
                type: GET_DIOCESE,
                payload: res.data,
            });
            setState({ loading: false, id: res.data._id });
        } catch (err:any) {
            dispatch({
                type: THROW_ERROR,
                payload: err,
            });
        }
    };
    useEffect(() => {
        getDioceseLogo()
        handleGetAllDiocese();
    }, [])
    return (
        <div className="diocese_screen">
            <div className="diocese_container">
                <div className="diocese_wrapper">
                    <div className="diocese_header_wrapper" id="home">
                        <div className="diocese_header_left_icon_wrapper">
                            <a href="#home">
                            <img src={state.logo} alt="left_icon" className="diocese_header_left_icon" />
                            </a>
                        </div>
                        <div className="diocese_header_title_wrapper">
                            <div className="diocese_header_title h2">{dioceseData && dioceseData.name} Diocese</div>
                            <div className="diocese_header_description h3">Church Of South India - SYNOD</div>
                        </div>
                        <div className="diocese_header_right_icon_wrapper">
                            <a href="https://csi1947.com/home">
                            <img src={Logo} alt="right_icon" className="diocese_header_right_icon" />
                            </a>
                        </div>
                    </div>
                    <div className="diocese_navbar_wrapper">
                        <a href="#home">Home</a>
                        <a href="#aboutus">About Us</a>
                        <a href="#ministerRoll">Ministerial Roll</a>
                        <a href="#news">News & Events</a>
                        <a href="#gallery">Gallery</a>
                        <a href="#getInTouch">Get in Touch</a>
                    </div>
                    <div className="mobile_diocese_navbar_container">
                            <div className="mobile_diocese_navbar_wrapper">
                                <div className="mobile_diocese_navbar_icon_container">
                                    <img src={HamberFill} alt="hamber_fill_icon" className="mobile_diocese_hamber_search_fill_icon" onClick={() => setState({ hamber: true })} />
                                </div>
                            </div>
                            {
                        state.hamber
                        && <div className="mobile_diocese_navbar_content_wrapper">
                                <div className="mobile_diocese_navbar_content_close_icon_wrapper">
                                <img src={Inactive} alt="close_icon" className="mobile_diocese_navbar_content_close_icon" onClick={() => setState({ hamber: false })} />
                                </div>
                                <div className="mobile_diocese_navbar_content">
                                    <a href="#home" onClick={() => setState({ hamber: false })}>Home</a>
                                    <a href="#aboutus" onClick={() => setState({ hamber: false })}>About Us</a>
                                    <a href="#ministerRoll" onClick={() => setState({ hamber: false })}>Ministerial Roll</a>
                                    <a href="#news" onClick={() => setState({ hamber: false })}>News & Events</a>
                                    <a href="#gallery" onClick={() => setState({ hamber: false })}>Gallery</a>
                                    <a href="#getInTouch" onClick={() => setState({ hamber: false })}>Get in Touch</a>
                                </div>
                           </div>
                        }
                    </div>
                    <div className="diocese_home_image_wrapper">
                        <Adswiper className="diocese_home_image" data={dioceseData && dioceseData.banner ? dioceseData.banner : []} onClick={(index, item) => setState({ openModal: true })} />
                    </div>
                    <div className="diocese_home_body_wrapper">
                        <div className="diocese_home_body">
                        {
                            personnelData && personnelData.length !== 0 ? personnelData.map((diocese) => diocese.role === 'director'
                            && <div className="diocese_nandayal_dioceses_wrapper">
                                    <div className="diocese_nandayal_dioceses_title h2">{dioceseData && dioceseData.name} Diocese</div>
                                    <div className="diocese_nandayal_dioceses_line_wrapper"> </div>
                                        <div className="diocese_nandayal_diocese_image_wrapper">
                                            <img src={diocese.profile_picture} alt="nandayal_diocese_image" className="diocese_nandayal_diocese_image" />
                                        </div>
                                        <div className="diocese_nandayal_diocese_name h5">{diocese.user_name}</div>
                                        <div className="diocese_nandayal_diocese_description h6">Director</div>
                               </div>
                            )
                            : <div className="diocese_nandyal_dioceses_message">Personnel Details is not found</div>
                        }
                        {
                            personnelData && personnelData.length !== 0 ? personnelData.map((diocese) => diocese.role === 'bishop'
                            && <div className="diocese_nandayal_dioceses_wrapper">
                                    <div className="diocese_nandayal_dioceses_title h2">{dioceseData && dioceseData.name} Diocese</div>
                                    <div className="diocese_nandayal_dioceses_line_wrapper"> </div>
                                        <div className="diocese_nandayal_diocese_image_wrapper">
                                            <img src={diocese.profile_picture} alt="nandayal_diocese_image" className="diocese_nandayal_diocese_image" />
                                        </div>
                                     {/* <OfficerCard types="officers" profilePicture={diocese.profile_picture} email={diocese.email} name={diocese.user_name} profession={diocese.designation} location={diocese.address} mobile={diocese.mobile} phoneFax={diocese.phone} className="officers_card" /> */}
                                        <div className="office_info_wrapper">

                                        <div className="diocese_nandayal_diocese_name h5">{diocese.user_name}</div>
                                    <div className="diocese_nandayal_diocese_description h6">Bishop</div>
                                        {diocese.email && <div className="diocese_nandayal_diocese_info h5">Email: {diocese.email}</div>}
                                        {diocese.address && <div className="diocese_nandayal_diocese_info h5">{diocese.address}</div>}
                                    {diocese.phone && <div className="diocese_nandayal_diocese_info h5">{diocese.phone}</div>}
                                        </div>
                               </div>
                            )
                            : <div className="diocese_nandyal_dioceses_message">Personnel Details is not found</div>
                        }
                            <div className="diocese_officers_wrapper">
                                <div className="diocese_officers_title h2">Diocesan Officers</div>
                                <div className="diocese_officers_line_wrapper"> </div>
                                    <div className="diocese_officers_information_wrapper">
                                        {
                                        personnelData && personnelData.length !== 0 ? personnelData.map((diocese) => diocese.role === 'diocesan_officer'
                                        // && <div className="diocese_officers_information">
                                        //         <div className="diocese_officers_information_image_wrapper">
                                        //             <img src={diocese.profile_picture} alt="diocese_personal_image" className="diocese_officers_information_image" />
                                        //         </div>
                                        //         <div className="diocese_officers_information_name_wrapper">
                                        //             <div className="diocese_officers_information_name h5">{diocese.user_name}</div>
                                        //             <div className="diocese_officers_information_mobile h6">{diocese.phone.length > 0 ? `Phone: ${diocese.phone}` : ''}</div>
                                        //             <div className="diocese_officers_information_email h6">{diocese.email.length > 0 ? `Email: ${diocese.email}` : ''}</div>
                                        //             <div className="diocese_officers_information_email h6">{diocese.address.length > 0 ? `Address: ${diocese.address}` : ''}</div>
                                        //         </div>
                                        //    </div>
                                         && <OfficerCard types="officers" profilePicture={diocese.profile_picture} email={diocese.email} name={diocese.user_name} profession={diocese.designation} location={diocese.address} mobile={diocese.mobile} phoneFax={diocese.phone} className="officers_card" />
                                        )
                                        : <div>Diocesan Officer Details is not found</div>
                                        }
                                    </div>
                            </div>
                            <div id="aboutus" className="diocese_about_us_wrapper">
                                { dioceseData
                                && dioceseData.description !== undefined
                                ? dioceseData.description.map((diocese, index) =>
                                <div className="diocese_about_us_jurisdiction_wrapper">
                                    { diocese.title !== ""
                                        && <div>
                                                <div className="diocese_about_us_title h3">{diocese.title}</div>
                                                <div className="diocese_about_us_line_wrapper"> </div>
                                           </div>}
                                    <div className="diocese_about_us_description">
                                        {parse(`${diocese.description}`)}
                                    </div>
                                </div>
                                ) : "No description is found"}
                            </div>
                            <div id="ministerRoll" className="diocese_ministerial_role_wrapper">
                                <div className="diocese_ministerial_role_heading h2">Ministerial Roll</div>
                                <div className="diocese_ministerial_role_line_wrapper"> </div>
                                {
                                   dioceseData && dioceseData.ministerial_role
                                   && dioceseData.ministerial_role.length !== 0
                                   && dioceseData.ministerial_role[0].length !== 0
                                    ? <div className="diocese_ministerial_role_pdf_wrapper">
                                        <PdfFile title={dioceseData.ministerial_role[0].url} className="diocese_about_us_ministerial_role_pdf" onClick={() => window.open(dioceseData.ministerial_role[0].url, "_blank")} />
                                      </div>
                                      : <div className="diocese_ministerial_role_message">No Minsiterial Roll added</div>
                                }
                            </div>
                            <div id="news" className="diocese_news_events_wrapper">
                                <div className="diocese_news_events_title_wrapper">
                                    <div className="diocese_news_events_title h2">News</div>
                                    <div className="diocese_news_events_line_wrapper"> </div>
                                </div>
                                <div className="diocese_news_events_content_wrapper">
                                    <div className="dioceses_news_events_content_news">
                                        {
                                         dioceseNews && dioceseNews.docs
                                            && dioceseNews.docs.length !== 0
                                            ? <div className="dioceses_news_events_up_news_card">
                                                    <NewsCard1 link={dioceseNews.docs[0].thumbnail} title={dioceseNews.docs[0].heading} onClick={() => props.history.push(`/news_screen/dioceses/${dioceseNews.docs[0]._id}`)} description={moment(dioceseNews.docs[0].date).format('dddd, MMM D, YYYY')} type="news_card" className="diocese_news_card_image" />
                                              </div>
                                              : <div>No News Found</div>
                                        }
                                        {dioceseNews && dioceseNews.docs
                                            && dioceseNews.docs.slice(1, 3).map((news, index) =>
                                               <div className="dioceses_news_events_down_news_card">
                                                    <NewsCard2 text={news.heading} link={news.thumbnail} title={news.title} onClick={() => props.history.push(`/news_screen/dioceses/${news._id}`)} description={moment(news.date).format('dddd, MMM D, YYYY')} type="news_card" />
                                               </div>
                                            )}
                                        {
                                            dioceseNews && dioceseNews.docs
                                            && dioceseNews.docs.length > 1
                                            && <div className="diocese_news_events_load_more_news_button_wrapper">
                                                <Button text="Load More" onClick={() => props.history.push(`/news_list_screen/dioceses/${state.id}`)} className="diocese_button" />
                                               </div>
                                        }
                                    </div>
                                    <div className="dioceses_news_events_content_events">
                                        {events && events.docs.length !== 0
                                        ? events.docs.map((diocese, index) => index < 3
                                        && <div className="dioceses_news_events_card">
                                            <EventCard day={`${moment(diocese.from_date).format('DD')}`} month={`${moment(diocese.from_date).format('MMM')}`} title={diocese.dioceses.name} description={diocese.name} image={diocese.thumbnail} className="diocese_event_card" />
                                           </div>
                                        )
                                        : <div className="dioceses_news_events_content_message">No Events found</div>}
                                        {
                                            events && events.docs && events.docs.length > 3
                                            && <div className="diocese_news_events_load_more_event_button_wrapper">
                                                    <Button text="Load More" onClick={() => props.history.push(`/home/event/dioceses/${match.params.id}`)} className="diocese_button" />
                                               </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="gallery" className="diocese_home_gallery_wrapper">
                            <div className="diocese_home_gallery_title h2">Gallery</div>
                            <div className="diocese_home_gallery_line_wrapper"> </div>
                            <div className="diocese_home_gallery_content">
                                {
                                dioceseData && dioceseData.gallery
                                && dioceseData.gallery.length !== 0
                                ? <Swiper data={dioceseData && dioceseData.gallery ? dioceseData.gallery : []} orderNow={() => { }} types="gallery" className="diocese_home_gallery_swiper" number={3} />
                                : <div>No Gallery is found</div>
                                }
                            </div>
                        </div>
                        <div id="getInTouch" className="diocese_get_in_touch_wrapper">
                            <div className="diocese_get_in_touch_title h2">Contact</div>
                            <div className="diocese_get_in_touch_line_wrapper"> </div>
                            <div className="diocese_get_in_touch_content">
                                <div className="diocese_get_in_touch_description_content">
                                <div className="diocese_question_title_wrapper">
                                <div className="diocese_question_title h2">Do you have any questions ?</div>
                                <div className="diocese_question_line_wrapper"> </div>
                                <div className="diocese_question_content_wrapper">
                                    <div className="diocese_question_input">
                                        <Input name="User name" onChange={handleOnChange} type="text" inputKey="name" value={state.name} background="#FFF8FB" />
                                    </div>
                                    <div className="diocese_question_input">
                                        <Input name="Email Address" onChange={handleOnChange} type="text" inputKey="email" value={state.email} background="#FFF8FB" />
                                    </div>
                                    <div className="diocese_question_input">
                                        <Input name="Subject" onChange={handleOnChange} type="text" inputKey="subject" value={state.subject} background="#FFF8FB" />
                                    </div>
                                    <div className="diocese_question_input">
                                        <TextArea name="Message" onChange={handleOnChange} inputKey="message" value={state.message} background="#FFF8FB" />
                                    </div>
                                    <div className="diocese_question_submit_button">
                                        <Button text="Submit" onClick={() => contactUs()} className="diocese_question_button" />
                                    </div>
                                </div>
                                </div>
                                </div>
                                <div className="diocese_get_in_touch_description_wrapper">
                                    { dioceseData && dioceseData.length !== 0
                                    ? <div className="diocese_get_in_touch_description_content">
                                        <div className="diocese_question_title h2">Get in touch</div>
                                        <div className="diocese_question_line_wrapper"> </div>
                                            <div className="diocese_get_in_touch_office_address">
                                                {dioceseData.address && <div className="diocese_office_name">{dioceseData.address}</div>}
                                            </div>
                                            <div className="diocese_get_in_touch_office_mobile_contact">
                                                {dioceseData.phone && <div className="diocese_office_number">{dioceseData.phone}</div>}
                                                {dioceseData.mobile && <div className="diocese_office_number">{dioceseData.mobile}</div>}
                                                {dioceseData.fax && <div className="diocese_office_number">{dioceseData.fax}</div>}
                                            </div>
                                            <div className="diocese_get_in_touch_office_mobile_contact_email">
                                                {dioceseData.email && <div className="diocese_office_email">{dioceseData.email}</div>}
                                            </div>
                                      </div>
                                : <div>No Personnal Information is found</div>}
                                </div>
                                <div className="diocese_get_in_touch_map_wrapper">
                                    <Map />
                                </div>
                            </div>
                        </div>
                        {/* <div className="diocese_question_wrapper">
                            <div className="diocese_question_title_wrapper">
                                <div className="diocese_question_title h2">Do you have any questions ?</div>
                                <div className="diocese_question_line_wrapper"> </div>
                                <div className="diocese_question_content_wrapper">
                                    <div className="diocese_question_input">
                                        <Input name="User name" onChange={handleOnChange} type="text" inputKey="name" value={state.name} background="#FFF8FB" />
                                    </div>
                                    <div className="diocese_question_input">
                                        <Input name="Email Address" onChange={handleOnChange} type="text" inputKey="email" value={state.email} background="#FFF8FB" />
                                    </div>
                                    <div className="diocese_question_input">
                                        <Input name="Subject" onChange={handleOnChange} type="text" inputKey="subject" value={state.subject} background="#FFF8FB" />
                                    </div>
                                    <div className="diocese_question_input">
                                        <TextArea name="Message" onChange={handleOnChange} inputKey="message" value={state.message} background="#FFF8FB" />
                                    </div>
                                    <div className="diocese_question_submit_button">
                                        <Button text="Submit" onClick={() => contactUs()} className="diocese_question_button" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="diocese_footer_wrapper">
                            <div className="diocese_footer_content h4">© csi1947.com | Powered By CSI SYNOD</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
