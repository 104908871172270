/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { useSetState } from 'utils/common.utils';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { SHOW_SUCCESS, THROW_ERROR } from 'utils/types.utils';
import { reducers } from 'interfaces/common.interface';
import connectSocket from 'utils/socket.utils';
import Div100vh from 'react-div-100vh';
import { toast } from 'react-toastify';
import ActivePartners from "assets/icons/csi_partners.svg";
import InactivePartners from "assets/icons/csi_partners_inactive.svg";
import ActiveVideo from "assets/icons/csi_video_active.svg";
import InactiveVideo from "assets/icons/csi_video_inactive.svg"
import ActiveWorship from "assets/icons/csi_worship_resource_active.svg";
import InactiveWorship from "assets/icons/csi_worship_resource_inactive.svg";
import InactiveAdvertisement from "assets/icons/csi_ad_active.svg";
import ActiveAdvertisement from "assets/icons/csi_ad_inactive.svg"
import Button from 'common_components/ui/button/button.ui';
import Search from '../ui/search_bar/search_bar.ui';
import './main.component.scss';
import Notification from '../../assets/icons/Bell.svg';
import ActiveNotification from '../../assets/icons/Bell_Active.svg';
import ArrowDown from '../../assets/icons/Arrow.svg';
import Logo from '../../assets/images/CSI_Logo.png';
import User from '../../assets/icons/Users_w.svg';
import ActiveUser from '../../assets/icons/Users.svg';
import Diocese from '../../assets/icons/Dioceses_w.svg';
import ActiveDiocese from '../../assets/icons/Dioceses.svg';
import Department from '../../assets/icons/Departments_w.svg';
import ActiveDepartment from '../../assets/icons/Departments.svg';
import Logout from '../../assets/icons/Logout_White.svg';
import AboutCsi from '../../assets/icons/About_csi_w.svg';
import ActiveAboutCsi from '../../assets/icons/About_csi.svg';
import NewsLetter from '../../assets/icons/Newsletter_w.svg';
import ActiveNewsLetter from '../../assets/icons/Newsletter.svg';
import Publications from '../../assets/icons/Publications_w.svg';
import ActivePublications from '../../assets/icons/Publications.svg';
import News from '../../assets/icons/News_w.svg';
import ActiveNews from '../../assets/icons/News.svg';
import Others from '../../assets/icons/Others_w.svg';
import ActiveOthers from '../../assets/icons/Others.svg';
import Background from '../../assets/images/wallpaper.jpg';

let socket: any;

export default function Main(props: any) {
  const { history } = props
  const locationList = useLocation()
  const [state, setState] = useSetState({ signout: false, activeTab: '' });
  const user = useSelector((store: reducers) => store.user);
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');

  useEffect(() => {
    // socket = connectSocket();
  }, []);

  const checkActive = (route) => {
    const location = useLocation();
    const url = location.pathname.split('/')[1]
    if (url.includes(route)) {
      return true;
    }
    return false;
  };

  const showSuccess = (text: string) => {
    dispatch({
      type: SHOW_SUCCESS,
      payload: text,
    });
  };

  const throwError = (text: string) => {
    dispatch({
      type: THROW_ERROR,
      payload: text,
    });
    toast.error(text);
  };

  const searchOnchange = (text: string) => {
    setState({ search: text });
  };

  const renderChildren = () => {
    return React.Children.map(props.children, (child: any) => {
      if (child) {
        return React.cloneElement(child, {
          user,
          showSuccess,
          throwError,
          socket,
        });
      }
    });
  };

  // if (token) window.location.href = '/';
  return (
    <Div100vh>
      <div className="sidebar_topbar_container">
        <div className="sidebar_component">
          <div className="sidebar_container">
            <div className="sidebar_wrapper padding_bottom">
              <div className="sidebar_logo_content">
                <NavLink to="/users" className="nav_link">
                  <div className="img_container">
                    <img className="sidebar_logo" src={Logo} alt="csi_logo" />
                  </div>
                  <div className="sidebar_head">csi - synod</div>
                </NavLink>
              </div>
            </div>
            {role === "csi_user"
             && <div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/users" className="nav_link">
                        <div className="tab_icon">
                          <img
                            src={checkActive('/users') ? ActiveUser : User}
                            alt="user_icon"
                          />
                        </div>
                        <div className="tab_name">Users</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/dioceses" className="nav_link">
                        <div className="tab_icon">
                          <img
                            src={checkActive('/dioceses') ? ActiveDiocese : Diocese}
                            alt="user_icon"
                          />
                        </div>
                        <div className="tab_name">Dioceses</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/departments" className="nav_link">
                        <div className="tab_icon">
                          <img
                            src={
                              checkActive('/departments')
                                ? ActiveDepartment
                                : Department
                            }
                            alt="user_icon"
                          />
                        </div>
                        <div className="tab_name">Departments</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/about" className="nav_link">
                        <div className="tab_icon">
                          <img
                            src={checkActive('/about') ? ActiveAboutCsi : AboutCsi}
                            alt="user_icon"
                          />
                        </div>
                        <div className="tab_name">About CSI</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/csi_videos" className="nav_link">
                        <div className="tab_icon">
                          <img
                            src={
                              checkActive('csi_videos') ? ActiveVideo : InactiveVideo
                            }
                            width="16px"
                            alt="user_icon"
                          />
                        </div>
                        <div className="tab_name">CSI Videos</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink
                        to="/news_letter/departments"
                        className={`nav_link ${(locationList.pathname.split('/').includes('news_letter') || locationList.pathname.split('/').includes('subscriber')) && "active"}`}
                      >
                        <div className="tab_icon">
                          <img
                            src={
                              checkActive('news_letter')
                                ? ActiveNewsLetter
                                : NewsLetter
                            }
                            alt="user_icon"
                          />
                        </div>
                        <div className="tab_name">Newsletter</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/publications" className="nav_link">
                        <div className="tab_icon active">
                          <img
                            src={
                              checkActive('publications')
                                ? ActivePublications
                                : Publications
                            }
                            alt="user_icon"
                          />
                        </div>
                        <div className="tab_name active_text">Publications</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/campaigns" className="nav_link">
                        <div className="tab_icon">
                          <img
                            src={checkActive('campaigns') ? ActiveNews : News}
                            alt="user_icon"
                          />
                        </div>
                        <div className="tab_name">Campaigns</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/worship_resources" className="nav_link">
                        <div className="tab_icon">
                          <img
                            src={
                              checkActive('worship_resources')
                                ? ActiveWorship
                                : InactiveWorship
                            }
                            alt="user_icon"
                            width="16px"
                          />
                        </div>
                        <div className="tab_name">Worship Resources</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink
                        to="/announcements/govt_notification_schemes/govt"
                        className={`nav_link ${locationList.pathname.split('/').includes('announcements') && "active"}`}
                      >
                        <div className="tab_icon">
                          <img
                            src={checkActive('announcements') ? ActiveNews : News}
                            alt="user_icon"
                          />
                        </div>
                        <div className="tab_name">Announcements</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/advertisement" className="nav_link">
                        <div className="tab_icon">
                          <img
                            src={
                              checkActive('advertisement') ? ActiveAdvertisement : InactiveAdvertisement
                            }
                            alt="user_icon"
                            width="16px"
                          />
                        </div>
                        <div className="tab_name">Advertisement</div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="sidebar_wrapper">
                    <div className="tab_icon_container">
                      <NavLink to="/partners" className="nav_link">
                        <div className="tab_icon">
                          <img
                            src={checkActive('partners') ? ActivePartners : InactivePartners}
                            alt="user_icon"
                            width="16px"
                          />
                        </div>
                        <div className="tab_name">Partners & Network</div>
                      </NavLink>
                    </div>
                  </div>
                </div>}
                {role === "diocese_user"
                && <div className="sidebar_wrapper">
                      <div className="tab_icon_container">
                        <NavLink to="/dioceses" className="nav_link">
                          <div className="tab_icon">
                            <img
                              src={checkActive('/dioceses') ? ActiveDiocese : Diocese}
                              alt="user_icon"
                            />
                          </div>
                          <div className="tab_name">Dioceses</div>
                        </NavLink>
                      </div>
                   </div>}
                   {role === "department_user"
                && <div className="sidebar_wrapper">
                      <div className="tab_icon_container">
                        <NavLink to="/departments" className="nav_link">
                          <div className="tab_icon">
                            <img
                              src={
                                checkActive('/departments')
                                  ? ActiveDepartment
                                  : Department
                              }
                              alt="user_icon"
                            />
                          </div>
                          <div className="tab_name">Departments</div>
                        </NavLink>
                      </div>
                   </div>}
          </div>
        </div>
        <div className="top_bar_container">
          <div className="top_bar_content">
            <div className="top_bar_wrapper">
              {/* <div className="top_bar_search">
                <Search
                  suggessionData={[]}
                  suggession=""
                  onChange={searchOnchange}
                />
              </div> */}
            </div>
            <div className="top_bar_wrapper">
              {/* <div className="top_bar_profile">
                <div className="notification_icon padding_icons">
                  <img src={Notification} alt="notification" />
                </div>
                <div className="profile_icon padding_icons">
                  <img src={Background} className="profile_pic" alt="profile" />
                </div>
                <div className="view_arrow padding_icons">
                  <img src={ArrowDown} alt="profile" />
                </div>
              </div> */}
              <div className="logout_wrapper">
                <Button
                  type="custom"
                  icon={Logout}
                  className="logout_button"
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = '/login';
                  }}
                  text="Logout"
                />
              </div>
            </div>
          </div>
          <div className="main_body">{renderChildren()}</div>
        </div>
      </div>
    </Div100vh>
  );
}
