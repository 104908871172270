import React, { useEffect } from 'react';
import 'screens/diocese/diocese_description.screen.scss';
import {
  bannerImageData,
  descriptionData,
  galleryData,
  personnelsBody,
} from 'helper/constants.helper';
import { Modal } from 'react-responsive-modal';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import CustomNavBar from 'components/custom_nav/custom_nav.component';
import { useSetState } from 'utils/common.utils';
import { editDiocese, getDioceseById } from 'models/diocese.model';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import Table from 'components/table/table.component';
import _ from 'lodash';
import Button from 'common_components/ui/button/button.ui';
import { useDispatch } from 'react-redux';
import { THROW_ERROR } from 'utils/types.utils';
import { uploadMedia } from 'models/news.model';
import moment from 'moment';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import ActionButton from '../../common_components/ui/action_button/action_button.ui';
import PlusIcon from '../../assets/icons/Plus_White.svg';
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";
import Arrowleft from '../../assets/icons/Arrow.svg';

export default function DioceseDescription(props) {
  const dispatch = useDispatch();
  const { match } = props;
  const [state, setState] = useSetState({
    selectedData: 'diocese_description',
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    loading: true,
    confirmModal: '',
    name: '',
    email: '',
    mobile: '',
    description: [],
    dioceseName: '',
    thumbnail: '',
    banner: '',
    id: '',
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const suggessionData = [
    {
      icon: PlusIcon,
      name: 'sunil',
    },
    {
      icon: PlusIcon,
      name: 'prakash',
    },
    {
      icon: PlusIcon,
      name: 'Mukunthan',
    },
  ];
  const handleGetDiocese = async () => {
    try {
      const res: any = await getDioceseById(match.params.id);
      const details = [...res.data.description];
      const doc = details.map((docs) => {
        docs.description = docs.description.replace(/(<([^>]+)>)/gi, '');
        return docs;
      });
      setState({
        dioceseName: res.data.name,
        loading: false,
        description: res.data.description,
      });
    } catch (err:any) {
     props.throwError(err.data ? err.data.desc : "Failed to fetch details")
    }
  };
  useEffect(() => {
    handleGetDiocese();
  }, []);
  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = personnelsBody.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleUpdateBanner = async () => {
    try {
      const data = {
        banner: state.thumbnail,
      };
      const res = await editDiocese(match.params.id, data);
      handleGetDiocese();
      setState({ openModal: false });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
    setState({ openModal: false });
  };

  const handleDeleteDetails = async () => {
    try {
      const deleteDesc = state.description.filter(
        (desc, index) => index !== state.id,
      );
      const data = {
        description: deleteDesc
      }
      await editDiocese(match.params.id, data);
      setState({ confirmModal: false })
      handleGetDiocese()
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to delete details');
    }
  };
  const loadMore = () => {
    // Api Call
  };

  const searchChange = () => {};

  const handleConfirm = (index) => {
    setState({ confirmModal: true, id: index });
  };
  const handleEdit = (index) => {
    props.history.push(
      `/dioceses/diocese_information/edit_description/${match.params.id}`,
      { data: index },
    );
  };

  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEdit,
      icon: Edit,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleConfirm,
      icon: Delete,
    },
  ];

  const dioceseData = [
    {
      navName: 'Address',
      navValue: 'address',
      navUrl: `/dioceses/diocese_information/address/${match.params.id}`,
    },
    {
      navName: 'Banner Images',
      navValue: 'diocese_descriptions',
      navUrl: `/dioceses/diocese_information/banner_images/${match.params.id}`,
    },
    {
      navName: 'Gallery',
      navValue: 'diocese_gallery',
      navUrl: `/dioceses/diocese_information/gallery/${match.params.id}`,
    },
    {
      navName: 'Personnels',
      navValue: 'diocese_personnels',
      navUrl: `/dioceses/diocese_information/personnels/${match.params.id}`,
    },
    {
      navName: 'Description',
      navValue: 'diocese_description',
      navUrl: `/dioceses/diocese_information/description/${match.params.id}`,
    },
    {
      navName: 'Ministerial Roll',
      navValue: 'diocese_ministerial_roll',
      navUrl: `/dioceses/diocese_information/ministerial_roll/${match.params.id}`,
    },
  ];

  return (
    <div className="diocese_description_screen">
      <div className="diocese_description_container">
        <div className="diocese_description_wrapper">
          <div className="diocese_description_header_wrapper">
            <div className="diocese_description_navigate_icon_wrapper">
              <div className="diocese_description_navigate_icon">
                <img
                  className="navigate_icon"
                  alt=""
                  src={Arrowleft}
                  onClick={() =>
                    props.history.push('/dioceses/diocese_information')}
                />
              </div>
            </div>
            <div className="diocese_description_header_name_container">
              <div className="diocese_description_header_name h3">
                {state.dioceseName}
              </div>
              <div className="diocese_description_header_button_wrapper">
                <Button
                  text="Add description"
                  type="primary"
                  icon={PlusIcon}
                  onClick={() => {
                    props.history.push(
                      `/dioceses/diocese_information/add_description/`,
                      { data: match.params.id },
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="diocese_description_navbar_wrapper">
            <CustomNavBar
              data={dioceseData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add Personnels"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={suggessionData}
              buttonOnClick={() => setState({ openModal: true, edit: false })}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="diocese_description_table_wrapper">
            {state.loading ? (
              <Spinner />
            ) : (
              <Table
                header={descriptionData}
                body={state.description ? state.description : []}
                isChecked={state.isChecked}
                checkAll={checkAll}
                checkOne={checkOne}
                checkedData={state.checkedList}
                loadMore={loadMore}
                actionButton={actionArray}
                error="No description details found"
              />
            )}
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete this description ?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleDeleteDetails}
          />
        </div>
      </div>
    </div>
  );
}
