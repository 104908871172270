import React, { useEffect } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import _ from "lodash";
import "screens/newsletter/newsletter_diocese.screen.scss";
import CustomNavBar from "components/custom_nav/custom_nav.component";
import CheckList from "common_components/ui/checklist/checklist.ui";
import Button from "common_components/ui/button/button.ui";
import Tick from "assets/icons/Tick_White.svg";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import Input from "common_components/ui/input_box/input_box.ui";
import Table from "components/table/table.component";
import { throwError, useSetState } from "utils/common.utils";
import PlusIcon from "assets/icons/Plus_White.svg";
import Filter from "assets/icons/Filter.svg";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import { NewsLetterNavData, NewsNavData, newsletterDepartmentTableHeading, newsletterDioceseBody, csiUserSuggessionData, departmentData, dioceseData, newsletterDioceseTableHeading } from "helper/constants.helper";
import { GET_ALL_DIOCESES, GET_NEWS } from "utils/types.utils";
import { getAllDioceses } from "models/diocese.model";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews } from "models/news.model";
import { sendNewsletter } from "models/news_letter.model";
import { toast } from "react-toastify";

export default function NewsLetterDiocese(props) {
  const dispatch = useDispatch()
  const news = useSelector((state: any) => state.newsLetter.news)
  const dioceseDetails = useSelector((state: any) => state.diocese.allDioceses);
  const [state, setState] = useSetState({
    selectedData: "newsletter",
    selectedNavData: "dioceses",
    edit: false,
    openModal: false,
    filterModal: false,
    newsModal: false,
    loading: true,
    checkedList: [],
    checkList: [],
    heading: [],
    dioceseList: [],
    isChecked: false,
    confirmModal: false,
    title: '',
    seachValue: '',
    limit: 25,
    page: 1,
    search: '',
    activated: 'all',
    fromDate: '',
    toDate: '',
    image: '',
  });
  const dioData: any = [];
  const dioDetails = dioceseDetails
    && dioceseDetails.length !== 0 && dioceseDetails.map((diocese) => {
      dioData.push({ name: diocese.name, value: diocese._id });
      return '';
    });
  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const handleOnChangeNavData = (value) => {
    setState({ selectedNavData: value })
  }
  const getDioceses = async () => {
    try {
      const res: any = await getAllDioceses()
      dispatch({
        type: GET_ALL_DIOCESES,
        payload: res.data
      })
    } catch (err:any) {
         props.throwError(err.data ? err.data.desc : "Failed to Get Dioceses");
    }
  }
  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = newsletterDioceseBody.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (newsletterDioceseBody.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleOnChangeList = (selectedData, multiple) => {
    if (multiple === false) {
      setState({ checkList: [selectedData] });
    } else {
      const findIndex = _.findIndex(state.checkList, { index: selectedData.index });
      if (findIndex === -1) {
        setState({ checkList: [...state.checkList, ...[selectedData]] });
      } else {
        const indexes: any[] = [];
        state.checkList.forEach((data) => {
          if (data.index !== selectedData.index) {
            indexes.push(data);
          }
        })
        setState({ checkList: indexes });
      }
    }
  };

  const handleOnChangeInDiocese = (selectedData, multiple) => {
    if (multiple === false) {
      setState({ dioceseList: [selectedData] });
    } else {
      const findIndex = _.findIndex(state.dioceseList, { index: selectedData.index });
      if (findIndex === -1) {
        setState({ dioceseList: [...state.dioceseList, ...[selectedData]] });
      } else {
        const indexes: any[] = [];
        state.dioceseList.forEach((data) => {
          if (data.index !== selectedData.index) {
            indexes.push(data);
          }
        })
        setState({ dioceseList: indexes });
      }
    }
  };

  const searchChange = () => { }

  const handleUpdateNewsLetter = () => {
    setState({ openModal: true })
  }

  const handleAddNewsLetter = async () => {
    setState({ openModal: true })
    try {
        const data = {
          news: state.checkedList
        }
        if (state.checkedList.length > 0) {
          const res: any = await sendNewsletter(data)
          setState({ openModal: false, checkedList: [], isChecked: false })
          toast.error("News letter sent successfully")
        } else {
          toast.error("Please select atleast one news")
        }
    } catch (error:any) {
      throwError(error.data ? error.data.desc : "Failed to send newsletter")
    }
  }

  const handleEditNewsLetter = () => {
    setState({ openModal: true, edit: true })
  }

  const handleDeleteNewsLetter = () => {
    setState({ confirmModal: true })
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditNewsLetter,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteNewsLetter,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSearch = async (e) => {
    setState({ search: e.target.value, page: 1 })
  }
  const handleGetAllSubscribers = async ({ limit, page, search }) => {
    try {
      const data = {
        page,
        limit,
        search: {
          diocese: search
        },
        activated: 'all',
        type: 'dioceses',
        diocese: state.filter,
      };
      const res: any = await getAllNews(data)
      const docs = res.data.docs.map((doc) => {
        doc.diocese_name = doc.dioceses.name
        doc.author = doc.author.user_name;
        return doc
      }
      )
      res.data.docs = docs
      dispatch({
        type: GET_NEWS,
        payload: res.data
      })
      setState({ loading: false })
    } catch (err:any) {
    props.throwError(err.data ? err.data.desc : "Failed to get newsletter details")
    }
  };
  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }
  const handleApplyFilter = async () => {
    const filter: any = [];
        state.dioceseList.map((d) => {
          filter.push(d.value);
          return null;
        });
      setState({ filter, page: 1 })
      const data = {
        limit: state.limit,
        page: 1,
        search: {
          diocese: state.search
        },
        type: 'dioceses',
        from: state.fromDate,
        to: state.toDate,
        activated: 'all',
        diocese: filter,
      };
      try {
        const res: any = await getAllNews(data);
        const docs = res.data.docs.map((doc) => {
          doc.diocese_name = doc.dioceses.name
          doc.author = doc.author.user_name;
          return doc
        }
        )
        res.data.docs = docs
        dispatch({
          type: GET_NEWS,
          payload: res.data,
        });
        setState({ filterModal: false });
      } catch (err:any) {
        props.throwError(
          err.data ? err.data.desc : 'Failed to filter news',
        );
      }
  }
  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        page,
        limit,
        search: {
          diocese: search
        },
        activated: state.activated,
        type: 'dioceses',
        diocese: state.filter
      };
      const res: any = await getAllNews(data);
      const doc = res.data.docs.map((docs) => {
        docs.diocese_name = docs.dioceses.name;
        docs.author = docs.author.user_name
        return docs;
      });
      res.data.docs = doc
      res.data.docs = [...news.docs, ...res.data.docs];
      dispatch({
        type: GET_NEWS,
        payload: res.data,
      });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to get news');
    }
  };
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    });
  };
  useEffect(() => {
    getDioceses()
    handleGetAllSubscribers(state)
  }, [state.search])
  return (
    <div className="newsletter_diocese_screen">
      <div className="newsletter_diocese_container">
        <div className="newsletter_diocese_wrapper">
          <div className="newsletter_diocese_header_wrapper">
            <div className="newsletter_diocese_custom_navbar_wrapper">
              <CustomNavBar
                data={NewsLetterNavData}
                onTapClick={handleChangeClick}
                selectedItem={state.selectedData}
                buttonText="Add User"
                buttonType="primary"
                buttonIcon={PlusIcon}
                searchText="Search Text"
                suggession={csiUserSuggessionData}
                buttonOnClick={handleAddNewsLetter}
                button={false}
                search={false}
                onSearchChange={searchChange}
              />
            </div>
            <div className="newsletter_diocese_action_button_wrapper">
              <div className="newsletter_diocese_search_box_wrapper">
                <SearchBar suggession="" type="secondary" suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
              </div>
              <div className="newsletter_diocese_filter_button_wrapper">
                <Button type="primary" text="Filter" onClick={() => setState({ filterModal: true })} icon={Filter} />
              </div>
              <div className="newsletter_diocese_action_button_wrapper">
                <Button type="primary" text="Send News Letter" onClick={() => setState({ newsModal: true, edit: false })} icon="" />
              </div>
            </div>
          </div>
          <div className="newsletter_diocese_navbar_wrapper">
            <CustomNavBar
              data={NewsNavData}
              onTapClick={handleOnChangeNavData}
              selectedItem={state.selectedNavData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddNewsLetter}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="officers_table-wrapper">
            <Table
              header={newsletterDioceseTableHeading}
              body={news ? news && news.docs : []}
              isChecked={state.isChecked}
              checkAll={checkAll}
              checkOne={checkOne}
              checkedData={state.checkedList}
              hasMore={news && news.hasNextPage ? news.hasNextPage : false}
              loadMore={loadMoreInitial}
              actionButton={actionArray}
            />
          </div>
          <div className="newsletter_diocese_department_modal">
            <Modal
              open={state.filterModal}
              onClose={() => setState({ filterModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'newsletter_diocese_filter_modal'
              }}
              showCloseIcon={false}
            >
              <div className="newsletter_diocese_filter_modal_container">
                <div className="newsletter_diocese_filter_modal_wrapper">
                  <div className="newsletter_diocese_filter_modal_header_wrapper">
                    <div className="newsletter_diocese_filter_modal_header h4">
                      Date
                    </div>
                    <div className="newsletter_diocese_filter_modal_clear h6" onClick={() => setState({ fromDate: "", toDate: "" })}>
                      Clear All
                    </div>
                  </div>
                  <div className="newsletter_diocese_filter_modal_date_wrapper">
                    <div className="newsletter_diocese_filter_modal_name h4">From</div>
                    <div className="newsletter_diocese_filter_modal_input_wrapper">
                      <Input value={state.fromDate} type="date" onChange={handleOnChange} inputKey="fromDate" name="" />
                    </div>
                    <div className="newsletter_diocese_filter_modal_name h4">To</div>
                    <div className="newsletter_diocese_filter_modal_input_wrapper">
                      <Input value={state.toDate} type="date" onChange={handleOnChange} inputKey="toDate" name="" />
                    </div>
                  </div>
                  <div className="newsletter_diocese_filter_modal_header_wrapper">
                    <div className="newsletter_diocese_filter_modal_department_heading h4">
                      Diocese
                    </div>
                    <div className="newsletter_diocese_filter_modal_department_clear h6" onClick={() => setState({ checkList: [], dioceseList: [] })}>
                      Clear All
                    </div>
                  </div>
                  <div className="newsletter_diocese_filter_modal_checklist">
                    <CheckList data={dioData ? dioData : []} icon={Tick} multiple selectedData={state.dioceseList} iconBackground="" onChange={handleOnChangeInDiocese} list={state.dioceseList} multipleList={state.dioceseList} />
                  </div>
                  <div className="newsletter_diocese_filter_apply_filter_button_wrapper">
                    <div className="newsletter_diocese_filter_button">
                      <Button text="Apply Filters" type="primary" onClick={handleApplyFilter} />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="newsletter_diocese_news_modal">
            <Modal
              open={state.newsModal}
              onClose={() => setState({ newsModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'newsletter_diocese_news_modal'
              }}
              showCloseIcon={false}
            >
              <div className="newsletter_diocese_notification_wrapper">
                <div className="newsletter_diocese_message h3">News Letter is Sended Successfully</div>
                <div className="newsletter_diocese_button_wrapper">
                  <Button text="OK" type="primary" onClick={() => setState({ newsModal: false })} />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}
