import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_USERS, THROW_ERROR } from "utils/types.utils";
import "screens/department/personnels.screen.scss";
import { departmentpersonnelsTableHeading, personnelsBody, csiUserSuggessionData, personnelsData } from "helper/constants.helper";
import { Modal } from "react-responsive-modal";
import Spinner from "common_components/ui/spinner/spinner.ui";
import { getDepartmentById, addDepartment } from "models/department.model";
import { uploadMedia } from "models/upload_media.model";
import { getAllUser, getUserById, addUser, editUser, deleteUser } from "models/user_management.model";
import SelectBox from "common_components/ui/select_box/select_box.ui";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import CustomNavBar from "components/custom_nav/custom_nav.component";
import { uploadMediaFiles, useSetState } from "utils/common.utils";
import Input from "common_components/ui/input_box/input_box.ui";
import UploadBox from "common_components/ui/upload_box/upload_box.ui";
import Table from "components/table/table.component";
import _ from "lodash";
import AddIcon from "assets/icons/Add.svg";
import InactiveIcon from "assets/icons/Inactive_black.svg";
import TextArea from "common_components/ui/text_area/text_area.ui";
import Button from "common_components/ui/button/button.ui";
import PlusIcon from "../../assets/icons/Plus_White.svg";
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";
import Arrowleft from "../../assets/icons/Arrow.svg";

export default function Personnels(props) {
  const personnel = useSelector((state: any) => state.users.user_list);
  const dispatch = useDispatch();
  const [state, setState] = useSetState(
    {
      selectedData: "personnel",
      edit: false,
      openModal: false,
      checkedList: [],
      isChecked: false,
      confirmModal: "",
      limit: 20,
      page: 1,
      sortOrder: '',
      name: "",
      email: "",
      mobile: "",
      address: "",
      userType: "",
      role: "",
      profilePicture: "",
      departmentName: "",
      personnelId: "",
      loading: true,
      uploaded: false,
      fileUploading: false,
    });
  const { match } = props;

  const departmentViewData = [{
    navName: "Aims and Objectives",
    navValue: "aims_and_objectives",
    navUrl: `/departments/department_information/aimsandobjectives/${match.params.id}`
  },
  {
    navName: "Personnel",
    navValue: "personnel",
    navUrl: `/departments/department_information/personnels/${match.params.id}`
  }];

  const handleGetAllDepartment = async () => {
    try {
      const department: any = await getDepartmentById(match.params.id);
      if (department !== undefined
        && department.data !== undefined) {
        setState({ departmentName: department.data.name })
      }
    } catch (err:any) {
      props.throwError(err.statusText ? err.statusText : "Failed to Load DepartmentDetails")
    }
  }

  const handleGetAllPersonnels = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: "",
        department: match.params.id,
        type: "department_personnels",
        user_sort: true,
      }
      const user: any = await getAllUser(data);
      if (user !== undefined
        && user.data !== undefined) {
        const doc = user.data.docs.map((docs) => {
          docs.department = docs.department.name;
          docs.author = docs.created_by.user_name;
          return docs;
        })
        user.data.docs = doc;
        dispatch({
          type: GET_USERS,
          payload: user.data
        })
        setState({ loading: false })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to load Department Personnels");
    }
  }

  useEffect(() => {
    handleGetAllDepartment();
    handleGetAllPersonnels();
  }, []);

  const handleAddPersonnels = async () => {
    try {
      const data = {
        department: match.params.id,
        user_name: state.name,
        user_type: "department_personnels",
        mobile: state.mobile,
        role: state.userType,
        address: state.address,
        sort: state.sortOrder,
        email: state.email,
        profile_picture: state.profilePicture
      }
      const personnels: any = await addUser(data);
      await handleGetAllPersonnels();
      setState({ openModal: false, edit: false });
    } catch (err:any) {
      props.throwError(err.status === 422 ? err.data.message : "Failed to add Department personnels");
    }
  }

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const suggessionData = [{
    icon: PlusIcon,
    name: "sunil",
  },
  {
    icon: PlusIcon,
    name: "prakash"
  },
  {
    icon: PlusIcon,
    name: "Mukunthan"
  }];

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = personnelsBody.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const handleEditPersonnels = async (id) => {
    try {
      setState({ openModal: true, edit: true, personnelId: id });
      const personnels: any = await getUserById(id);
      if (personnels) {
        setState({
          name: personnels.data.user_name,
          userType: personnels.data.role,
          profilePicture: personnels.data.profile_picture,
          mobile: personnels.data.mobile,
          email: personnels.data.email,
          sortOrder: personnels.data.sort,
          address: personnels.data.address,
          uploaded: personnels.data.profile_picture ? true : false
        })
      }
    } catch (err:any) {
      props.throwError(err.status === 422 ? err.data.message : "Failed to edit department personnels");
    }
  }

  const handleUpdatePersonnels = async (id) => {
    try {
      const data = {
        user_name: state.name,
        role: state.userType,
        mobile: state.mobile,
        sort: state.sortOrder,
        email: state.email,
        profile_picture: state.profilePicture,
        address: state.address,
        user_sort: true,
      }
      const personnels: any = await editUser(state.personnelId, data);
      await handleGetAllPersonnels();
      setState({ openModal: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to update personnels");
    }
  }

  const handleUploadPersonalImage = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true })
          const personalImage: any = await uploadMediaFiles(e);
          if (personalImage) {
            setState({ profilePicture: personalImage.url, uploaded: true, fileUploading: false })
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif');
        }
      } else {
        setState({ fileUploading: true })
        const personalImage: any = await uploadMediaFiles(e);
        if (personalImage) {
          setState({ profilePicture: personalImage.url, uploaded: true, fileUploading: false })
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Images");
    }
  }

  const loadMore = () => {
    // Api Call
  }

  const searchChange = () => { }

  const handleConfirm = (id) => {
    setState({ confirmModal: true, personnelId: id })
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditPersonnels,
      icon: Edit
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleConfirm,
      icon: Delete
    }
  ]

  const handleOnDeletePersonnels = async () => {
    try {
      const personnels = await deleteUser(state.personnelId);
      await handleGetAllPersonnels();
      setState({ confirmModal: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete department personnels");
    }
  }

  const handleOnSelectUserType = (selectedOptions) => {
    setState({ userType: selectedOptions.value });
  }

  return (
    <div className="personnels_screen">
      <div className="personnels_container">
        <div className="personnels_wrapper">
          <div className="personnels_header_wrapper">
            <div className="personnels_navigate_icon_wrapper">
              <div className="personnels_navigate_icon">
                <img className="navigate_icon" alt="" src={Arrowleft} onClick={() => props.history.push('/departments/department_information')} />
              </div>
            </div>
            <div className="personnels_header_name_container">
              <div className="personnels_header_name h3">
                {state.departmentName !== undefined ? state.departmentName : "Malabar"}
              </div>
              <div className="personnels_header_button_wrapper">
                <Button text="Add Personnel" icon={PlusIcon} type="primary" onClick={() => setState({ openModal: true, edit: false, name: "", sortOrder: '', email: "", userType: "", address: "", personalImage: "", mobile: "", profilePicture: "", uploaded: false })} />
              </div>
            </div>
          </div>
          <div className="personnels_navbar_wrapper">
            <CustomNavBar
              data={departmentViewData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add Personnels"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={suggessionData}
              buttonOnClick={handleAddPersonnels}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="personnels_table_wrapper">
            {
              state.loading === false
                ? <Table
                    header={departmentpersonnelsTableHeading}
                    body={personnel ? personnel.docs : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    checkedData={state.checkedList}
                    loadMore={loadMore}
                    actionButton={actionArray}
                    error="No Department Personnel found"
                />
                : <Spinner />
            }
          </div>
          <div>
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false, uploaded: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'personnels_modal', closeButton: "close_button"
              }}
              showCloseIcon
            >
              <div className="personnels_modal_header_wrapper">
                <div className="personnels_modal_title_wrapper">
                  <div className="personnels_modal_title h4">{state.edit ? "Edit Personnel" : "Add Personnel"}</div>
                  <div className="personnels_modal_icon_wrapper">
                    <img className="personnels_modal_icon" src={InactiveIcon} alt="close_icon" onClick={() => setState({ openModal: false })} />
                  </div>
                </div>
                <div className="personnels_modal_line_wrapper"> </div>
              </div>
              <div className="personnels_modal_input_wrapper">
                <div className="personnels_modal_input">
                  <Input name="Name" onChange={handleOnChange} inputKey="name" value={state.name} />
                </div>
                <div className="personnels_modal_input">
                  <SelectBox data={personnelsData} name="Type" selectedItem={state.userType} onChange={handleOnSelectUserType} />
                </div>
                <div className="personnels_modal_input">
                  <div className="personnels_thumbnail_wrapper">Personnel image</div>
                  <div className="department-events_modal_uploadbox">
                    <UploadBox name="personal_image" className="personalImage" uploaded={state.uploaded} loading={state.fileUploading} accept="image/x-png,image/gif,image/jpeg" onChange={handleUploadPersonalImage} text="Drag and Drop" />
                  </div>
                </div>
                <div className="personnels_modal_input">
                  <Input name="Phone Number" type="text" onChange={handleOnChange} inputKey="mobile" value={state.mobile} />
                </div>
                <div className="personnels_modal_input">
                <Input name="Sort Order" type="number" onChange={handleOnChange} inputKey="sortOrder" value={state.sortOrder} />
                </div>
                <div className="personnels_modal_input">
                  <Input name="Email" type="email" onChange={handleOnChange} inputKey="email" value={state.email} />
                </div>
                <div className="personnels_modal_input">
                  <TextArea name="Address" onChange={handleOnChange} inputKey="address" value={state.address} />
                </div>
              </div>
              <div className="personnels_button_wrapper">
                <div className="personnels_button">
                  <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={state.edit ? handleUpdatePersonnels : handleAddPersonnels} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete Personnel ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeletePersonnels} />
        </div>
      </div>
    </div>
  )
}
