import React, { useEffect } from 'react';
import {
  aimsAndObjectivesBody,
  csiUserSuggessionData,
  addressTableHeading,
} from 'helper/constants.helper';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import CustomNavBar from 'components/custom_nav/custom_nav.component';
import { useSetState } from 'utils/common.utils';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import Button from 'common_components/ui/button/button.ui';
import Table from 'components/table/table.component';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import { Modal } from 'react-responsive-modal';
import { THROW_ERROR } from 'utils/types.utils';
import {
  deleteDiocese,
  editDiocese,
  getDioceseById,
} from 'models/diocese.model';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import Input from 'common_components/ui/input_box/input_box.ui';
import ActionButton from '../../common_components/ui/action_button/action_button.ui';
import PlusIcon from '../../assets/icons/Plus_White.svg';
import Arrowleft from '../../assets/icons/Arrow.svg';
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";
import 'screens/diocese/diocese_view.screen.scss';

export default function DioceseView(props) {
  const dispatch = useDispatch();
  const { match } = props;
  const [state, setState] = useSetState({
    selectedData: 'address',
    loading: true,
    edit: false,
    openModal: false,
    checkedList: [],
    details: [],
    isChecked: false,
    confirmModal: '',
    added: false,
    dioceseName: '',
    address: '',
    phone: '',
    email: '',
    mobile: '',
    fax: '',
  });
  const role = localStorage.getItem('role');
  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };
  const handleGetDiocese = async () => {
    try {
      const res: any = await getDioceseById(match.params.id);
      const details = [res.data];
      const list = details.map((doc) => {
        if (doc.phone && doc.fax && doc.mobile) {
          doc.number = `${doc.mobile}/${doc.phone}/${doc.fax}`
          doc.author = doc.created_by.user_name
          } else if (doc.phone && (doc.fax || doc.mobile)) {
            doc.number = `${doc.phone}/${doc.mobile}${doc.fax}`
            doc.author = doc.created_by.user_name
          } else if (doc.phone || doc.fax || doc.mobile) {
            doc.number = `${doc.phone}${doc.fax}${doc.mobile}`
            doc.author = doc.created_by.user_name
          } else {
            doc.number = ''
            doc.author = doc.created_by.user_name
          }
        return doc;
      });
      setState({
        dioceseName: res.data.name,
        details: list,
        loading: false,
        added: true,
      });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
  };
  const handleOnClick = (edit) => {
    if (!edit) {
      setState({ openModal: true });
    } else {
      setState({ openModal: true });
      setState({ edit: true });
    }
  };

  useEffect(() => {
    handleGetDiocese();
  }, []);

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = aimsAndObjectivesBody.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleEdit = async () => {
    try {
      setState({ openModal: true, edit: true });
      const res: any = await getDioceseById(match.params.id);
      handleGetDiocese();
      setState({
        address: res.data.address,
        email: res.data.email,
        phone: res.data.phone,
        mobile: res.data.mobile,
        fax: res.data.fax,
        added: true,
      });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
  };

  const handleAddAddress = () => {
    setState({
      openModal: true,
      edit: true,
      dioceseName: "",
      details: [],
      address: '',
      phone: '',
      fax: '',
      mobile: '',
      email: '',
    });
  };
  const handleUpdateAddress = async () => {
    try {
      const data = {
        address: state.address,
        fax: state.fax,
        phone: state.phone,
        email: state.email,
        mobile: state.mobile,
      };
      const res = await editDiocese(match.params.id, data);
      await handleGetDiocese();
      setState({ openModal: false, added: true });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
    setState({ openModal: false });
  };
  const handleDeleteAddress = async () => {
    try {
      const data = {
        address: '',
        fax: '',
        phone: '',
        email: '',
        mobile: '',
        dioceseName: '',
      };
      const res = await editDiocese(match.params.id, data);
      await handleGetDiocese();
      setState({ confirmModal: false, added: false });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
    setState({ openModal: false, added: false });
  };

  const loadMore = () => {
    // ApiCall
  };

  const searchChange = () => {};

  const handleConfirm = () => {
    setState({ confirmModal: true });
  };

  const actionArray = [
    {
      type: 'secondary',
      text: 'Edit',
      onClick: handleEdit,
      icon: Edit,
    },
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleConfirm,
      icon: Delete,
    },
  ];
  const dioceseData = [
    {
      navName: 'Address',
      navValue: 'address',
      navUrl: `/dioceses/diocese_information/address/${match.params.id}`,
    },
    {
      navName: 'Banner Images',
      navValue: 'banner_images',
      navUrl: `/dioceses/diocese_information/banner_images/${match.params.id}`,
    },
    {
      navName: 'Gallery',
      navValue: 'gallery',
      navUrl: `/dioceses/diocese_information/gallery/${match.params.id}`,
    },
    {
      navName: 'Personnels',
      navValue: 'personnels',
      navUrl: `/dioceses/diocese_information/personnels/${match.params.id}`,
    },
    {
      navName: 'Description',
      navValue: 'description',
      navUrl: `/dioceses/diocese_information/description/${match.params.id}`,
    },
    // {
    //   navName: 'Ministerial Roll',
    //   navValue: 'ministerial_roll',
    //   navUrl: `/dioceses/diocese_information/ministerial_roll/${match.params.id}`,
    // },
  ];
  const dioceseAdminData = [
    {
      navName: 'Address',
      navValue: 'address',
      navUrl: `/dioceses/diocese_information/address/${match.params.id}`,
    },
    {
      navName: 'Banner Images',
      navValue: 'banner_images',
      navUrl: `/dioceses/diocese_information/banner_images/${match.params.id}`,
    },
    {
      navName: 'Gallery',
      navValue: 'gallery',
      navUrl: `/dioceses/diocese_information/gallery/${match.params.id}`,
    },
    {
      navName: 'Personnels',
      navValue: 'personnels',
      navUrl: `/dioceses/diocese_information/personnels/${match.params.id}`,
    },
    {
      navName: 'Description',
      navValue: 'description',
      navUrl: `/dioceses/diocese_information/description/${match.params.id}`,
    },
    {
      navName: 'Ministerial Roll',
      navValue: 'ministerial_roll',
      navUrl: `/dioceses/diocese_information/ministerial_roll/${match.params.id}`,
    },
  ];
  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };
  return (
    <div className="diocese_view_screen">
      <div className="diocese_view_container">
        <div className="diocese_view_wrapper">
          <div className="diocese_view_header_wrapper">
            <div className="diocese_view_navigate_icon_wrapper">
              <div className="diocese_view_navigate_icon">
                <img
                  className="navigate_icon"
                  alt=""
                  src={Arrowleft}
                  onClick={() =>
                    props.history.push('/dioceses/diocese_information')}
                />
              </div>
            </div>
            <div className="diocese_view_header_name_container">
              <div className="diocese_view_header_name h3">
                {state.dioceseName}
              </div>
              <div className="diocese_view_header_button_wrapper">
                {state.added ? (
                  ''
                ) : (
                  <Button
                    text="Add Address"
                    type="primary"
                    icon={PlusIcon}
                    onClick={handleAddAddress}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="diocese_view_navbar_wrapper">
            <CustomNavBar
              data={role === 'csi_user' ? dioceseAdminData : dioceseData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add Address"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleOnClick}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="diocese_view_table_wrapper">
            {state.loading ? (
              <Spinner />
            ) : (
              <Table
                header={addressTableHeading}
                body={state.details ? state.details : []}
                isChecked={state.isChecked}
                checkAll={checkAll}
                checkOne={checkOne}
                checkedData={state.checkedList}
                loadMore={loadMore}
                actionButton={actionArray}
                error="No address found"
              />
            )}
          </div>
          <div className="">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'modal_container',
                closeButton: 'closeButton',
              }}
              showCloseIcon
            >
              <div className="modal_title_wrapper">
                <div className="modal_title h4">
                  {state.edit !== true ? 'Add Address' : 'Edit Address'}
                </div>
              </div>
              <div className="modal_line_wrapper"> </div>
              <div className="modal_input_wrapper">
                <div className="modal_input">
                  <TextArea
                    name="Address"
                    onChange={handleOnChange}
                    inputKey="address"
                    value={state.address}
                  />
                </div>
                <div className="modal_input">
                  <Input
                    name="Email"
                    onChange={handleOnChange}
                    type="email"
                    inputKey="email"
                    value={state.email}
                  />
                </div>
                <div className="modal_input">
                  <Input
                    name="Mobile"
                    onChange={handleOnChange}
                    type="tel"
                    inputKey="mobile"
                    value={state.mobile}
                  />
                </div>
                <div className="modal_input">
                  <Input
                    name="Phone"
                    onChange={handleOnChange}
                    type="tel"
                    inputKey="phone"
                    value={state.phone}
                  />
                </div>
                <div className="modal_input">
                  <Input
                    name="Fax"
                    onChange={handleOnChange}
                    type="tel"
                    inputKey="fax"
                    value={state.fax}
                  />
                </div>
              </div>
              <div className="csi_user_button_wrapper">
                <div className="csi_user_button">
                  <Button
                    text={state.edit ? 'Update Address' : 'Add Address'}
                    type="primary"
                    onClick={handleUpdateAddress}
                  />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete Address ?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleDeleteAddress}
          />
        </div>
      </div>
    </div>
  );
}
