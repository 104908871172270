import React, { useRef, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './contact_us.screen.scss';
import '../../index.scss';
import Header from 'components/header/header.component';
import Footer from 'components/footer/footer.component';
import Input from 'common_components/ui/input_box/input_box.ui';
import Button from 'components/button/button.component';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import { throwError, useSetState } from 'utils/common.utils';
import MyMapComponent from 'components/google_maps/google_maps.component';
import Navbar from 'components/navbar/navbar.component';
import { sendQuery } from 'models/public.model';
import { toast } from 'react-toastify';

export default function ContactScreen(props) {
    const [state, setState] = useSetState({
        message: '',
        name: '',
        email: '',
        subject: '',
      });
      const location = useLocation()
      const handleOnChange = (e, key) => {
        setState({ [key]: e.target.value });
      };
      const contactUs = async () => {
        try {
          const data = {
            message: state.message,
            email: state.email,
            name: state.name,
            subject: state.subject,
            type: 'contact_us'
          }
          const res: any = await sendQuery(data)
          setState({
            message: '',
            name: '',
            email: '',
            subject: '',
          })
          toast.error('Details sent')
        } catch (error:any) {
          throwError(error.data ? error.data.desc : "Failed to send details")
        }
      }
      useEffect(() => {
       if (location.pathname.includes("queries")) {
         document.getElementById("queries")?.scrollIntoView()
       }
      }, [])
  return (
    <div className="contact_screen">
      <div className="header_wrapper">
      <div className="header">
        <Header />
      </div>
        <div className="navbar_wrapper">
        <Navbar {...props} />
        </div>
      </div>
      <div className="contact_screen_container">
        <div className="contact_screen_header_wrapper">
          <div className="contact_screen_header h1">Contact Us</div>
          <div className="contact_header_line" />
        </div>
      </div>
      <div className="contact_body_wrapper">
        <div className="contact_wrapper_left">
        <div className="contact_screen_header_wrapper">
          <div className="contact_screen_header h3">
            Do you have any questions?
          </div>
          <div className="contact_header_line" />
        </div>
        <div className="contact_questions_wrapper">
          <div className="contact_input_wrapper">
            <Input
              name="Name"
              onChange={handleOnChange}
              type="text"
              inputKey="name"
              value={state.name}
            />
          </div>
          <div className="contact_input_wrapper">
            <Input
              name="Email address"
              onChange={handleOnChange}
              type="text"
              inputKey="email"
              value={state.email}
            />
          </div>
          <div className="contact_input_wrapper">
            <Input
              name="Subject"
              onChange={handleOnChange}
              type="text"
              inputKey="subject"
              value={state.subject}
            />
          </div>
          <div className="contact_input_wrapper">
          <TextArea
            name="Message"
            onChange={handleOnChange}
            inputKey="message"
            value={state.message}
          />
          </div>
          <div className="contact_button_wrapper">
              <Button text="Submit" onClick={() => contactUs()} className="button" />
          </div>
        </div>
        </div>
        <div className="contact_wrapper_left wrapper_one">
        <div className="contact_screen_header_wrapper">
          <div className="contact_screen_header h3">
            Get in touch
          </div>
          <div className="contact_header_line" />
        </div>
          <div className="contact_details_wrapper">
            <div className="contact_title h6">
              Church of South India - SYNOD
            </div>
            <div className="contact_title h6">
            No. 5, Whites Road, Royapettah, Chennai,<br /> India, Pin - 600 014.
            </div>
            <div className="contact_title h6">csisynod@gmail.com</div>
            <div className="contact_title h6">
              +91 44 2852 1566 <br />
              +91 44 2852 4166 <br />
              +91 9840577404
            </div>
          </div>
        </div>
        <div className="contact_wrapper_right">
          <div className="map_wrapper">
            <MyMapComponent isMarkerShown />
          </div>
        </div>
      </div>
      {/* <div className="contact_screen_container_body" id="queries">
        <div className="contact_screen_header_wrapper">
          <div className="contact_screen_header h3">
            Do you have any questions?
          </div>
          <div className="contact_header_line" />
        </div>
        <div className="contact_questions_wrapper">
          <div className="contact_input_wrapper">
            <Input
              name="Name"
              onChange={handleOnChange}
              type="text"
              inputKey="name"
              value={state.name}
            />
          </div>
          <div className="contact_input_wrapper">
            <Input
              name="Email address"
              onChange={handleOnChange}
              type="text"
              inputKey="email"
              value={state.email}
            />
          </div>
          <div className="contact_input_wrapper">
            <Input
              name="Subject"
              onChange={handleOnChange}
              type="text"
              inputKey="subject"
              value={state.subject}
            />
          </div>
          <div className="contact_input_wrapper">
          <TextArea
            name="Message"
            onChange={handleOnChange}
            inputKey="message"
            value={state.message}
          />
          </div>
          <div className="contact_button_wrapper">
              <Button text="Submit" onClick={() => contactUs()} className="button" />
          </div>
        </div>
      </div> */}
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
