import React, { useEffect } from 'react';
import 'screens/diocese/diocese_gallery.screen.scss';
import {
  bannerImageData,
  galleryData,
  personnelsBody,
} from 'helper/constants.helper';
import { Modal } from 'react-responsive-modal';
import ConfirmModal from 'components/confirm_modal/confirm_modal.component';
import CustomNavBar from 'components/custom_nav/custom_nav.component';
import { uploadMediaFiles, useSetState } from 'utils/common.utils';
import { editDiocese, getDioceseById, removeMedia } from 'models/diocese.model';
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import Table from 'components/table/table.component';
import _ from 'lodash';
import Button from 'common_components/ui/button/button.ui';
import Spinner from 'common_components/ui/spinner/spinner.ui';
import { useDispatch, useSelector } from 'react-redux';
import { GET_DIOCESE, THROW_ERROR } from 'utils/types.utils';
import { uploadMedia } from 'models/news.model';
import moment from 'moment';
import ActionButton from '../../common_components/ui/action_button/action_button.ui';
import PlusIcon from '../../assets/icons/Plus_White.svg';
import Arrowleft from '../../assets/icons/Arrow.svg';
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";

export default function DioceseGallery(props) {
  const dispatch = useDispatch();
  const { match } = props;
  const [state, setState] = useSetState({
    selectedData: 'diocese_gallery',
    edit: false,
    openModal: false,
    checkedList: [],
    isChecked: false,
    confirmModal: '',
    loading: true,
    imageUploaded: false,
    removeGallery: '',
    gallery: [],
    uploaded: false,
    fileUploading: false,
    newGallery: '',
    imageUrl: '',
    id: '',
    dioceseName: '',
    resourceUrl: '',
    thumbnail: '',
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value });
  };

  const suggessionData = [
    {
      icon: PlusIcon,
      name: 'sunil',
    },
    {
      icon: PlusIcon,
      name: 'prakash',
    },
    {
      icon: PlusIcon,
      name: 'Mukunthan',
    },
  ];
  const handleGetDiocese = async () => {
    try {
      const res: any = await getDioceseById(match.params.id);
      setState({
        gallery: res.data.gallery,
        dioceseName: res.data.name,
        loading: false,
      });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
  };
  useEffect(() => {
    handleGetDiocese();
  }, []);
  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = personnelsBody.map((ele) => {
        return ele._id;
      });
      setState({ isChecked: true, checkedList: checkData });
    }
  };

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  };

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  };

  const handleUpdateBanner = async (gallery) => {
    try {
      const data = {
        gallery,
      };
      const res = await editDiocese(match.params.id, data);
      handleGetDiocese();
      setState({ openModal: false });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
    setState({ openModal: false });
  };
  const addGallery = async () => {
    try {
      const data = {
        gallery: [...state.gallery, ...state.newGallery],
      };
      const res = await editDiocese(match.params.id, data);
      handleGetDiocese();
      setState({ openModal: false });
    } catch (err:any) {
      dispatch({
        type: THROW_ERROR,
        payload: err,
      });
    }
    setState({ openModal: false });
  };
  const handleDeleteUser = async () => {
    const gallery = state.gallery.filter((gal, index) => index !== state.id);
    const removeImage = state.gallery.filter((gal, index) => index === state.id);
    setState({ gallery, removeGallery: removeImage[0].url });
    const data = {
      resource_url: state.removeGallery
    }
    setState({ confirmModal: false });
    handleUpdateBanner(gallery);
    await removeMedia(data)
  };

  const loadMore = () => {
    // Api Call
  };

  const searchChange = () => { };

  const handleConfirm = (index) => {
    setState({ confirmModal: true, id: index });
  };
  const uploadGallery = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ fileUploading: true });
          const gallery: any = await uploadMediaFiles(e);
          const obj = [
            {
              url: gallery.url,
              date: moment(new Date()).format('DD-MM-YYYY HH:MM A'),
            },
          ];
          if (gallery) {
            setState({
              newGallery: obj,
              imageUrl: gallery.url,
              uploaded: true,
              fileUploading: false,
              imageUploaded: true,
            });
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif');
        }
      } else {
        setState({ fileUploading: true });
        const gallery: any = await uploadMediaFiles(e);
        const obj = [
          {
            url: gallery.url,
            date: moment(new Date()).format('DD-MM-YYYY HH:MM A'),
          },
        ];
        if (gallery) {
          setState({
            newGallery: obj,
            imageUrl: gallery.url,
            uploaded: true,
            fileUploading: false,
            imageUploaded: true,
          });
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Unable to Upload Gallery');
    }
  };

  const actionArray = [
    {
      type: 'secondary',
      text: 'Delete',
      onClick: handleConfirm,
      icon: Delete,
    },
  ];

  const dioceseData = [
    {
      navName: 'Address',
      navValue: 'address',
      navUrl: `/dioceses/diocese_information/address/${match.params.id}`,
    },
    {
      navName: 'Banner Images',
      navValue: 'banner_images',
      navUrl: `/dioceses/diocese_information/banner_images/${match.params.id}`,
    },
    {
      navName: 'Gallery',
      navValue: 'diocese_gallery',
      navUrl: `/dioceses/diocese_information/gallery/${match.params.id}`,
    },
    {
      navName: 'Personnels',
      navValue: 'personnels',
      navUrl: `/dioceses/diocese_information/personnels/${match.params.id}`,
    },
    {
      navName: 'Description',
      navValue: 'description',
      navUrl: `/dioceses/diocese_information/description/${match.params.id}`,
    },
    {
      navName: 'Ministerial Roll',
      navValue: 'ministerial_roll',
      navUrl: `/dioceses/diocese_information/ministerial_roll/${match.params.id}`,
    },
  ];

  return (
    <div className="diocese_gallery_screen">
      <div className="diocese_gallery_container">
        <div className="diocese_gallery_wrapper">
          <div className="diocese_gallery_header_wrapper">
            <div className="diocese_gallery_navigate_icon_wrapper">
              <div className="diocese_gallery_navigate_icon">
                <img
                  className="navigate_icon"
                  alt=""
                  src={Arrowleft}
                  onClick={() =>
                    props.history.push('/dioceses/diocese_information')}
                />
              </div>
            </div>
            <div className="diocese_gallery_header_name_container">
              <div className="diocese_gallery_header_name h3">
                {state.dioceseName}
              </div>
              <div className="diocese_gallery_header_button_wrapper">
                <Button
                  text="Add Images"
                  icon={PlusIcon}
                  type="primary"
                  onClick={() =>
                    setState({
                      openModal: true,
                      edit: false,
                      imageUploaded: false,
                      imageUrl: '',
                      fileUploading: false,
                      uploaded: false,
                    })}
                />
              </div>
            </div>
          </div>
          <div className="diocese_gallery_navbar_wrapper">
            <CustomNavBar
              data={dioceseData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add Images"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={suggessionData}
              buttonOnClick={() => setState({ openModal: true, edit: false })}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="diocese_gallery_table_wrapper">
            {state.loading ? (
              <Spinner />
            ) : (
                <Table
                  header={galleryData}
                  body={state.gallery ? state.gallery : []}
                  isChecked={state.isChecked}
                  checkAll={checkAll}
                  checkOne={checkOne}
                  checkedData={state.checkedList}
                  loadMore={loadMore}
                  actionButton={actionArray}
                  error="No images found"
                />
              )}
          </div>
          <div>
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay',
                modal: 'diocese_gallery_modal',
              }}
              showCloseIcon
            >
              <div className="diocese_gallery_modal_title_wrapper">
                <div className="diocese_gallery_modal_title h4">
                  {state.edit !== true
                    ? 'Add gallery images'
                    : 'Edit gallery images'}
                </div>
              </div>
              <div className="diocese_gallery_modal_line_wrapper"> </div>
              <div className="diocese_gallery_modal_input_wrapper">
                <div className="banner_outline_modal_input">
                  <div className="upload_box_wrapper">
                    Gallery images
                    <div className="upload_box">
                      <UploadBox
                        className="upload"
                        name="gallery"
                        uploaded={state.uploaded}
                        loading={state.fileUploading}
                        accept="image/x-png,image/gif,image/jpeg"
                        text="Drag and Drop or"
                        onChange={uploadGallery}
                      />
                    </div>
                    {state.imageUploaded ? (
                      <div className="uploaded_diocese_gallery">
                        <img
                          className="diocese_gallery"
                          src={state.imageUrl}
                          alt=""
                        />
                      </div>
                    ) : (
                        ''
                      )}
                  </div>
                </div>
              </div>
              <div className="diocese_gallery_button_wrapper">
                <div className="diocese_gallery_button">
                  <Button
                    text="Add Image"
                    type="primary"
                    onClick={addGallery}
                  />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal
            openModal={state.confirmModal}
            description="Are you sure want to delete the image ?"
            closeModal={() => setState({ confirmModal: false })}
            deleteConfirm={handleDeleteUser}
          />
        </div>
      </div>
    </div>
  );
}
