import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import { GET_ALL_DEPARTMENTS, GET_ALL_DIOCESES, GET_DEPARTMENT, GET_SINGLE_DEPARTMENT, THROW_ERROR } from "utils/types.utils";
import { getDepartmentById, addDepartment, deleteDepartment, getAllDepartment, editDepartment, getAllDepartments } from "models/department.model";
import Input from "common_components/ui/input_box/input_box.ui";
import Spinner from "common_components/ui/spinner/spinner.ui";
import Button from "common_components/ui/button/button.ui";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import TitleCard from "common_components/ui/title_card/title_card.ui";
import { departmentNavData, departmentInformationTableHeading, departmentInformationBody, csiUserSuggessionData, titleCardData } from "helper/constants.helper";
import { getAllDioceses } from "models/diocese.model";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import { useSetState } from "../../utils/common.utils";
import "screens/department/department_information.screen.scss";
import PlusIcon from "../../assets/icons/Plus_White.svg";

export default function DepartmentInformation(props) {
  const departments = useSelector((state: any) => state.department.allDepartments);
  const singleDepartment = useSelector((state: any) => state.department.singleDepartment);
  const dispatch = useDispatch();
  const departmentUser = localStorage.getItem('department');
  const [state, setState] = useSetState({ selectedData: "department_information", id: '', department_user_id: '', edit: false, openModal: false, confirmModal: "", department: "", departmentData: [], limit: 20, page: 1, loading: true });

  const handleGetAllDepartment = async () => {
    try {
      const data = {
        limit: 30,
        page: state.page,
      };
      if (departmentUser) {
      const department: any = await getDepartmentById(departmentUser);
      setState({
        department_user_id: department.data._id,
        button: department.data._id === departmentUser ? true : false
      })
      dispatch({
        type: GET_SINGLE_DEPARTMENT,
        payload: department.data
      })
      setState({ loading: false })
      } else {
        const department: any = await getAllDepartment(data);
        dispatch({
          type: GET_DEPARTMENT,
          payload: department.data
        })
        setState({ loading: false })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Get Departments");
    }
  }
  const getDepartments = async () => {
    try {
      const res: any = await getAllDepartments()
      dispatch({
        type: GET_ALL_DEPARTMENTS,
        payload: res.data
      })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Get Departments");
    }
  }

  useEffect(() => {
    handleGetAllDepartment();
    getDepartments()
  }, [])

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const handleOnClick = (edit) => {
    if (!edit) {
      setState({ openModal: true });
    } else {
      setState({ openModal: true });
      setState({ edit: true });
    }
  }

  const handleOnChangeInput = (e, key) => {
    setState({ [key]: e.target.value })
  }

  const handleEditUser = () => {
    // Api call
  }

  const handleDeleteUser = () => {
    // Api call
  }

  const handleAddDepartment = async () => {
    try {
      const data = {
        name: state.department
      }
      const department = await addDepartment(data);
      await handleGetAllDepartment();
      setState({ openModal: false });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to add Departments");
    }
  }

  const searchChange = async (e) => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: e.target.value
      };
      const department: any = await getAllDepartment(data);
      if (department !== undefined
        && department.data !== undefined && department.data.docs !== undefined) {
        dispatch({
          type: GET_DEPARTMENT,
          payload: department.data
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Search Department");
    }
  }

  const handleOnDeleteInformation = async () => {
    try {
      const department = await deleteDepartment(state.id);
      await handleGetAllDepartment();
      setState({ confirmModal: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete Departments");
    }
  }
  const handleGetDepartmentById = async (id) => {
    try {
      setState({
        openModal: true,
        edit: true,
        id
      })
      const res: any = await getDepartmentById(id);
      setState({
        department: res.data.name
      })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete Departments");
    }
  }
  const handleUpdateDeparmentById = async () => {
    try {
      const data = {
        name: state.department,
      }
      const res: any = await editDepartment(state.id, data);
      await handleGetAllDepartment();
      setState({ openModal: false });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete Departments");
    }
  }
  return (
    <div className="department_information_screen">
      <div className="department_information_container">
        <div className="department_information_navbar_wrapper">
          <CustomNavBar
            data={departmentNavData}
            onTapClick={handleChangeClick}
            selectedItem={state.selectedData}
            buttonText="Add Department"
            buttonType="primary"
            buttonIcon={PlusIcon}
            searchText="Search..."
            searchType="secondary"
            suggession={csiUserSuggessionData}
            buttonOnClick={() => setState({ openModal: true, edit: false, department: "" })}
            button={state.button ? false : true}
            search={state.button ? false : true}
            onSearchChange={searchChange}
          />
        </div>
        {
          state.loading ? <Spinner />
            : <div className="department_information_title_card_wrapper">
              {
                departmentUser
                ? singleDepartment
                && <div className="department_information_title_card">
                      <TitleCard
                        title={singleDepartment.name}
                        onClick={() => { props.history.push(`/departments/department_information/aimsandobjectives/${singleDepartment._id}`) }}
                        editAction={() => handleGetDepartmentById(singleDepartment._id)}
                        deleteAction={() => setState({ confirmModal: true,
                        id: singleDepartment._id })}
                      />
                   </div>
             : departments && departments.map((title) =>
                <div className="department_information_title_card">
                  <TitleCard
                    title={title.name}
                    onClick={() => { props.history.push(`/departments/department_information/aimsandobjectives/${title._id}`) }}
                    editAction={() => handleGetDepartmentById(title._id)}
                    deleteAction={() => setState({ confirmModal: true, id: title._id })}
                  />
                </div>)
                }
              </div>
        }
        <div>
          <Modal
            open={state.openModal}
            onClose={() => setState({ openModal: false })}
            center
            classNames={{
              overlay: 'customOverlay', modal: 'department_information_modal', closeButton: 'close_button'
            }}
            showCloseIcon
          >
            <div className="department_information_modal_title_wrapper">
              <div className="department_information_modal_title h4">{state.edit !== true ? "Add Department" : "Edit Department"}</div>
            </div>
            <div className="department_information_modal_line_wrapper"> </div>
            <div className="department_information_modal_input_wrapper">
              <div className="department_information_modal_input">
              <Input name="Department" inputKey="department" onChange={handleOnChangeInput} value={state.department} />
              </div>
            </div>
            <div className="department_information_button_wrapper">
              <div className="department_information_button">
                <Button text={state.edit ? 'Update' : 'Add'} type="primary" onClick={state.edit ? handleUpdateDeparmentById : handleAddDepartment} />
              </div>
            </div>
          </Modal>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete Department Information ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteInformation} />
        </div>
      </div>
    </div>
  )
}
