import React, { useEffect } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import _ from "lodash";
import "screens/newsletter/newsletter_department.screen.scss";
import CustomNavBar from "components/custom_nav/custom_nav.component";
import CheckList from "common_components/ui/checklist/checklist.ui";
import Button from "common_components/ui/button/button.ui";
import Tick from "assets/icons/Tick_White.svg";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import Input from "common_components/ui/input_box/input_box.ui";
import Table from "components/table/table.component";
import { getType, throwError, useSetState } from "utils/common.utils";
import PlusIcon from "assets/icons/Plus_White.svg";
import Filter from "assets/icons/Filter.svg";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import { NewsLetterNavData, NewsNavData, newsletterDepartmentTableHeading, newsletterDepartmentBody, csiUserSuggessionData, departmentData, dioceseData } from "helper/constants.helper";
import { GET_ALL_DEPARTMENTS, GET_DEPARTMENT, GET_NEWS } from "utils/types.utils";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews } from "models/news.model";
import { getAllDepartment, getAllDepartments } from "models/department.model";
import { toast } from "react-toastify";
import { sendNewsletter } from "models/news_letter.model";

export default function NewsLetterDepartment(props) {
  const { match } = props
  const dispatch = useDispatch()
  const news = useSelector((state: any) => state.newsLetter.news)
  const departments = useSelector((state: any) => state.department.allDepartments);
  const [state, setState] = useSetState({
    selectedData: "newsletter",
    selectedNavData: "departments",
    edit: false,
    openModal: false,
    filterModal: false,
    checkedList: [],
    checkList: [],
    dioceseList: [],
    isChecked: false,
    confirmModal: false,
    title: '',
    seachValue: '',
    limit: 25,
    page: 1,
    search: '',
    activated: 'all',
    fromDate: '',
    toDate: '',
    image: '',
  });
  const deptData: any = [];
  const dept = departments && departments.length !== 0
    && departments.map((department) => {
      deptData.push({ name: department.name, value: department._id });
      return "";
    });
    const handleGetAllSubscribers = async ({ limit, page, search }) => {
      try {
        const data = {
          page,
          limit,
          search: {
            department: search
          },
          activated: 'all',
          type: 'departments',
          department: state.filter,
        };
        const res: any = await getAllNews(data)
        const docs = res.data.docs.map((doc) => {
          doc.department_name = doc.departments.name
          doc.author = doc.author.user_name;
          return doc
        }
        )
        res.data.docs = docs
        dispatch({
          type: GET_NEWS,
          payload: res.data
        })
        setState({ loading: false })
      } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get newsletter details")
      }
    };
    const getDepartments = async () => {
      try {
        const res: any = await getAllDepartments()
        dispatch({
          type: GET_ALL_DEPARTMENTS,
          payload: res.data
        })
      } catch (err:any) {
           props.throwError(err.data ? err.data.desc : "Failed to Get Departments");
      }
    }
  const handleApplyFilter = async () => {
    const filter: any = [];
        state.checkList.map((d) => {
          filter.push(d.value);
          return null;
        });
      setState({ filter, page: 1 })
      const data = {
        limit: state.limit,
        page: 1,
        search: {
          department: state.search
        },
        type: 'departments',
        from: state.fromDate,
        to: state.toDate,
        activated: 'all',
        department: filter,
      };
      try {
        const res: any = await getAllNews(data);
        const docs = res.data.docs.map((doc) => {
          doc.department_name = doc.departments.name
          doc.author = doc.author.user_name;
          return doc
        }
        )
        res.data.docs = docs
        dispatch({
          type: GET_NEWS,
          payload: res.data,
        });
        setState({ filterModal: false });
      } catch (err:any) {
        props.throwError(
          err.data ? err.data.desc : 'Failed to filter news',
        );
      }
  }
  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        page,
        limit,
        search: {
          department: search
        },
        activated: state.activated,
        type: 'departments',
        department: state.filter
      };
      const res: any = await getAllNews(data);
      const doc = res.data.docs.map((docs) => {
        docs.department_name = docs.departments.name;
        docs.author = docs.author.user_name
        return docs;
      });
      res.data.docs = doc
      res.data.docs = [...news.docs, ...res.data.docs];
      dispatch({
        type: GET_NEWS,
        payload: res.data,
      });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to get news');
    }
  };
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    });
  };
  useEffect(() => {
    handleGetAllSubscribers(state)
    getDepartments()
  }, [state.search])
  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const handleOnChangeNavData = (value) => {
    setState({ selectedNavData: value })
  }

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = newsletterDepartmentBody.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (newsletterDepartmentBody.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleOnChangeList = (selectedData, multiple) => {
    if (multiple === false) {
      setState({ checkList: [selectedData] });
    } else {
      const findIndex = _.findIndex(state.checkList, { index: selectedData.index });
      if (findIndex === -1) {
        setState({ checkList: [...state.checkList, ...[selectedData]] });
      } else {
        const indexes: any[] = [];
        state.checkList.forEach((data) => {
          if (data.index !== selectedData.index) {
            indexes.push(data);
          }
        })
        setState({ checkList: indexes });
      }
    }
  };

  const handleOnChangeInDiocese = (selectedData, multiple) => {
    if (multiple === false) {
      setState({ dioceseList: [selectedData] });
    } else {
      const findIndex = _.findIndex(state.dioceseList, { index: selectedData.index });
      if (findIndex === -1) {
        setState({ dioceseList: [...state.dioceseList, ...[selectedData]] });
      } else {
        const indexes: any[] = [];
        state.dioceseList.forEach((data) => {
          if (data.index !== selectedData.index) {
            indexes.push(data);
          }
        })
        setState({ dioceseList: indexes });
      }
    }
  };

  const searchChange = () => { }

  const handleUpdateNewsLetter = () => {
    setState({ openModal: true })
  }

  const handleAddNewsLetter = async () => {
    setState({ openModal: true })
    try {
        const data = {
          news: state.checkedList
        }
        if (state.checkedList.length > 0) {
          const res: any = await sendNewsletter(data)
          setState({ openModal: false, checkedList: [], isChecked: false })
          toast.error("News letter sent successfully")
        } else {
          toast.error("Please select atleast one news")
        }
    } catch (error:any) {
      throwError(error.data ? error.data.desc : "Failed to send newsletter")
    }
  }

  const handleEditNewsLetter = () => {
    setState({ openModal: true, edit: true })
  }

  const handleDeleteNewsLetter = () => {
    setState({ confirmModal: true })
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditNewsLetter,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteNewsLetter,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSearch = (e) => {
    setState({ search: e.target.value, page: 1 })
  }

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value })
  }

  return (
    <div className="newsletter_department_screen">
      <div className="newsletter_department_container">
        <div className="newsletter_department_wrapper">
          <div className="newsletter_department_header_wrapper">
            <div className="newsletter_department_custom_navbar_wrapper">
              <CustomNavBar
                data={NewsLetterNavData}
                onTapClick={handleChangeClick}
                selectedItem={state.selectedData}
                buttonText="Add User"
                buttonType="primary"
                buttonIcon={PlusIcon}
                searchText="Search Text"
                suggession={csiUserSuggessionData}
                buttonOnClick={handleAddNewsLetter}
                button={false}
                search={false}
                onSearchChange={searchChange}
              />
            </div>
            <div className="newsletter_department_action_button_wrapper">
              <div className="newsletter_department_search_box_wrapper">
                <SearchBar suggession="" type="secondary" suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
              </div>
              <div className="newsletter_department_filter_button_wrapper">
                <Button type="primary" text="Filter" onClick={() => setState({ filterModal: true })} icon={Filter} />
              </div>
              <div className="newsletter_department_action_button_wrapper">
                <Button type="primary" text="Send News Letter" onClick={handleAddNewsLetter} icon="" />
              </div>
            </div>
          </div>
          <div className="newsletter_department_navbar_wrapper">
            <CustomNavBar
              data={NewsNavData}
              onTapClick={handleOnChangeNavData}
              selectedItem={state.selectedNavData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddNewsLetter}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="officers_table-wrapper">
            <Table
              header={newsletterDepartmentTableHeading}
              body={news ? news.docs : []}
              isChecked={state.isChecked}
              checkAll={checkAll}
              checkOne={checkOne}
              checkedData={state.checkedList}
              loadMore={loadMoreInitial}
              hasMore={news && news.hasNextPage ? news.hasNextPage : false}
              actionButton={actionArray}
            />
          </div>
          <div className="newsletter_department_department_modal">
            <Modal
              open={state.filterModal}
              onClose={() => setState({ filterModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'newsletter_department_filter_modal'
              }}
              showCloseIcon={false}
            >
              <div className="newsletter_department_filter_modal_container">
                <div className="newsletter_department_filter_modal_wrapper">
                  <div className="newsletter_department_filter_modal_header_wrapper">
                    <div className="newsletter_department_filter_modal_header h4">
                      Date
                    </div>
                    <div className="newsletter_department_filter_modal_clear h6" onClick={() => setState({ fromDate: "", toDate: "" })}>
                      Clear All
                    </div>
                  </div>
                  <div className="newsletter_department_filter_modal_date_wrapper">
                    <div className="newsletter_department_filter_modal_name h4">From</div>
                    <div className="newsletter_department_filter_modal_input_wrapper">
                      <Input value={state.fromDate} type="date" onChange={handleOnChange} inputKey="fromDate" name="" />
                    </div>
                    <div className="newsletter_department_filter_modal_name h4">To</div>
                    <div className="newsletter_department_filter_modal_input_wrapper">
                      <Input value={state.toDate} type="date" onChange={handleOnChange} inputKey="toDate" name="" />
                    </div>
                  </div>
                  <div className="newsletter_department_filter_modal_header_wrapper">
                    <div className="newsletter_department_filter_modal_department_heading h4">
                      Department
                    </div>
                    <div className="newsletter_department_filter_modal_department_clear h6" onClick={() => setState({ checkList: "" })}>
                      Clear All
                    </div>
                  </div>
                  <div className="newsletter_department_filter_modal_checklist">
                    <CheckList data={deptData ? deptData : []} icon={Tick} multiple selectedData={state.checkList} iconBackground="" onChange={handleOnChangeList} list={state.checkList} multipleList={state.checkList} />
                  </div>
                  <div className="newsletter_department_filter_apply_filter_button_wrapper">
                    <div className="newsletter_department_filter_button">
                      <Button text="Apply Filters" type="primary" onClick={handleApplyFilter} />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="newsletter_department_news_modal">
            <Modal
              open={state.newsModal}
              onClose={() => setState({ newsModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'newsletter_department_news_modal'
              }}
              showCloseIcon={false}
            >
              <div className="newsletter_department_notification_wrapper">
                <div className="newsletter_department_message h3">News Letter is Sended Successfully</div>
                <div className="newsletter_department_button_wrapper">
                  <Button text="OK" type="primary" onClick={() => setState({ newsModal: false })} />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}
