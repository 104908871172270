import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_OFFICERS } from "utils/types.utils";
import { getAllUser, addUser, getUserById, editUser, deleteUser } from "models/user_management.model";
import { uploadMedia } from "models/upload_media.model";
import Button from "common_components/ui/button/button.ui";
import Arrow from "assets/icons/Arrow.svg";
import UploadBox from "common_components/ui/upload_box/upload_box.ui";
import Input from "common_components/ui/input_box/input_box.ui";
import TextArea from "common_components/ui/text_area/text_area.ui";
import "screens/aboutCSI/add_officers.screen.scss";
import { useSetState, uploadMediaFiles } from "../../utils/common.utils";

export default function AddOfficers(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    userName: "",
    designation: "",
    image: "",
    address: "",
    email: "",
    mobile: "",
    fax: "",
    phone: "",
    sortOrder: "",
    imageUploading: false,
    uploaded: false
  });

  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  }

  const handleUploadImage = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ imageUploading: true });
          const image = await uploadMediaFiles(e);
          if (image) {
            setState({ image: image.url, uploaded: true, imageUploading: false })
          }
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif',)
        }
      } else {
        setState({ imageUploading: true })
        const image: any = await uploadMediaFiles(e);
        if (image) {
          setState({ image: image.url, uploaded: true, imageUploading: false })
        }
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Upload Thumbnail");
    }
  }

  const handleUpdateOfficers = async () => {
    try {
      const data = {
        user_name: state.userName,
        designation: state.designation,
        profile_picture: state.image,
        address: state.address,
        mobile: state.mobile,
        phone: state.phone,
        fax: state.fax,
        user_sort: true,
        email: state.email,
        sort: Number(state.sortOrder)
      }
      const officer: any = await editUser(match.params.id, data);
      props.history.push('/about_csi/officers');
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to Update Officers");
    }
  }

  const handleGetOfficersById = async () => {
    try {
      const officer: any = await getUserById(match.params.id);
      if (officer) {
        setState({
          userName: officer.data.user_name,
          designation: officer.data.designation,
          image: officer.data.profile_picture,
          address: officer.data.address,
          email: officer.data.email,
          mobile: officer.data.mobile,
          phone: officer.data.phone,
          fax: officer.data.fax,
          sortOrder: officer.data.sort,
          uploaded: officer.data.profile_picture ? true : false
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get Officers");
    }
  }

  useEffect(() => {
    if (match.params.id) {
      handleGetOfficersById();
    }
  }, [])

  const handleAddOfficers = async () => {
    try {
      const data = {
        user_name: state.userName,
        designation: state.designation,
        profile_picture: state.image,
        address: state.address,
        mobile: state.mobile,
        phone: state.phone,
        fax: state.fax,
        email: state.email,
        sort: Number(state.sortOrder),
        user_type: "officers",
        user_sort: true,
      }
      const officer: any = await addUser(data);
      if (officer) {
        dispatch({
          type: GET_OFFICERS,
          payload: officer.data
        });
        props.history.push('/about_csi/officers');
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.message || err.data.desc : "Failed to add officer");
    }
  }

  return (
    <div className="add_officers_screen">
      <div className="add_officers_container">
        <div className="add_officers_wrapper">
          <div className="add_officers_header_wrapper">
            <div className="add_officers_header_name_wrapper">
              <div className="add_officers_icon_wrapper">
                <img className="add_officers_nav_icon" alt="nav_icon" src={Arrow} onClick={() => props.history.push("/about_csi/officers")} />
              </div>
              <div className="add_officers_header_name h2">{match.params.id ? "Edit Officers" : "Add Officers"}</div>
            </div>
            <div className="add_officers_header_button_wrapper">
              <Button text={match.params.id ? "Update" : "Add"} type="primary" onClick={match.params.id ? handleUpdateOfficers : handleAddOfficers} />
            </div>
          </div>
          <div className="add_officers_body_wrapper">
            <div className="add_officers_input_wrapper">
              <div className="add_officers_input">
                <Input name="Name" onChange={handleOnChange} inputKey="userName" value={state.userName} />
              </div>
              <div className="add_officers_input">
                <Input name="Designation" onChange={handleOnChange} inputKey="designation" value={state.designation} />
              </div>
              <div className="add_officers_input">
                <div className="upload_box_wrapper">
                  Image
                    <div className="upload_box">
                    <UploadBox name="uploadpdf" className="image" accept="image/x-png,image/gif,image/jpeg" text="Drag and Drop or" uploaded={state.uploaded} loading={state.imageUploading} onChange={handleUploadImage} />
                    </div>
                </div>
              </div>
              <div className="add_officers_input">
                <div className="add_officers_text_area_wrapper">
                  <TextArea name="Address" onChange={handleOnChange} inputKey="address" value={state.address} />
                </div>
              </div>
            </div>
            <div className="add_officers_input_wrapper">
              <div className="add_officers_input">
                <Input name="Mobile" type="text" onChange={handleOnChange} inputKey="mobile" value={state.mobile} />
              </div>
              <div className="add_officers_input">
                <Input name="Email" type="text" onChange={handleOnChange} inputKey="email" value={state.email} />
              </div>
              <div className="add_officers_input">
                <Input name="Phone" type="number" onChange={handleOnChange} inputKey="phone" value={state.phone} />
              </div>
              <div className="add_officers_input">
                <Input name="Fax" onChange={handleOnChange} inputKey="fax" value={state.fax} />
              </div>
              <div className="add_officers_input">
                <Input name="Sort Order" type="number" onChange={handleOnChange} inputKey="sortOrder" value={state.sortOrder} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
