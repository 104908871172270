/* eslint-disable max-len */
import React, { useEffect } from 'react';
import './Landing_page.screen.scss';
import Header from 'components/header/header.component';
import { throwError, useSetState } from 'utils/common.utils';
import Button from 'components/button/button.component';
import Newscard2 from 'components/news_card_2/news_card_2.component';
import moment from 'moment';
import Div100vh from 'react-div-100vh';
import { globalSearch } from 'models/public.model';
import Inactive from 'assets/icons/Inactive_black.svg';
import InactivePink from 'assets/icons/Inactive_Pink.svg';
import Logo from '../../assets/images/CSI_Logo.png';
import FB from '../../assets/icons/Social_Media/White/Facebook.svg';
import Twitter from '../../assets/icons/Social_Media/White/Twitter.svg';
import Youtube from '../../assets/icons/Social_Media/White/Youtube.svg';
import FacebookFill from '../../assets/icons/Group 1.svg';
import Instagram from '../../assets/icons/instagram 2.svg';
import YoutubeFill from '../../assets/icons/Group 2.svg';
import SearchFill from '../../assets/icons/Tools/White/Search_2px.svg';
import HamberFill from '../../assets/icons/Tools/White/Ham Menu_2px.svg';
import Search from '../../assets/icons/Tools/Violet/Search.svg';
import Hamber from '../../assets/icons/Tools/Violet/Ham_Menu.svg';
import Whatsapp from '../../assets/icons/Group 4.svg';

export default function LandingPageScreen(props) {
  const [state, setState] = useSetState({
    search: false,
    hamber: false,
    globalSearch: '',
    globalNews: [],
    page: 1,
    limit: 4,
  });
  const searchNews = async () => {
    try {
      const data = {
        page: state.page,
        limit: state.limit,
        search: state.globalSearch,
      };
      const res: any = await globalSearch(data);
      const docs = res.data.docs.map((doc) => {
        if (doc.department) {
          doc.department_name = doc.department.name;
        } else if (doc.diocese) {
          doc.diocese_name = doc.diocese.name;
        }
        return doc;
      });
      setState({ globalNews: res.data.docs });
    } catch (error:any) {
      throwError("Failed to search news")
    }
  };

  useEffect(() => {
    searchNews();
  }, [state.globalSearch, state.page]);
  return (
    <Div100vh>
      <div className="landing_page_wrapper">
        <div className="landing_page_overlay" />
        <div className="landing_page_header_container">
          <div className="landing_page_header_wrapper">
            <div className="landing_page_icon_container">
              <a href="https://facebook.com/groups/195181353850157/" target="_blank" rel="noreferrer">
                <img
                  src={FB}
                  alt="social_icons"
                  className="landing_page_header_social_icon"
                />
              </a>
            </div>
            <div className="landing_page_icon_container">
              <a href="https://www.instagram.com/csi_synod_official/" target="_blank" rel="noreferrer">
                <img
                  src={Instagram}
                  alt="social_icons"
                  className="landing_page_header_social_icon"
                />
              </a>
            </div>
            <div className="landing_page_icon_container">
              <a href="https://www.youtube.com/@csilife" target="_blank" rel="noreferrer">
              <img
                src={Youtube}
                alt="social_icons"
                className="landing_page_header_social_icon"
              />
              </a>
            </div>
            <div className="landing_page_icon_container">
              <a href="https://wa.me/+919840577404" target="_blank" rel="noreferrer">
              <img
                src={Whatsapp}
                alt="social_icons"
                className="landing_page_header_social_icon"
              />
              </a>
            </div>
          </div>
          <div className="landing_page_header_wrapper hamber_wrapper">
            <div
              className="landing_page_icon_container landing_page_header_action_icons"
              onClick={() => setState({ search: true, hamber: false })}
            >
              <img src={SearchFill} alt="social_icons" />
            </div>
            <div
              className="landing_page_icon_container"
              onClick={() => setState({ search: false, hamber: true })}
            >
              <img src={HamberFill} alt="social_icons" />
            </div>
          </div>
        </div>
        <div className="landing_page_text_wrapper">
          <div className="landing_page_logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="landing_page_heading_text h1">
            Welcome to the Church of South India
          </div>
          <div className="landing_page_description h3">
            That they all may be one, and that the world may believe that you
            have sent me
          </div>
          {/* <div className="landing_page_description h1 launch_text">
            Launching on June 1, 2021
          </div> */}
          <div className="landing_page_button_wrapper">
            <Button
              text="Explore"
              onClick={() => {
                props.history.push('/home');
              }}
              className="landing_page_button"
            />
          </div>
        </div>
        <div className="landing_page_footer_wrapper">
          <div className="landing_page_footer_links">
            <Button
              text="About CSI"
              onClick={() => {
                props.history.push('/about/history');
              }}
              className="landing_page_footer_button"
            />
          </div>
          {/* <div className="landing_page_footer_links">
            <Button
              text="Scroll down"
              icon={Arrow}
              onClick={() => props.history.push('/home')}
              className="landing_page_footer_button"
            />
          </div> */}
        </div>
        {(state.search === true || state.hamber === true) && (
          <div className="landing_page_search_menu_wrapper">
            <div className="landing_page_header_wrapper">
              <div className="landing_page_icon_wrapper">
                <img
                  src={FacebookFill}
                  alt="facebook_icon"
                  className="facebook_icon"
                  onClick={() =>
                    window.open(
                      'https://facebook.com/groups/195181353850157/',
                      '_blank',
                    )}
                />
                <img
                  src={Instagram}
                  alt="facebook_icon"
                  className="twitter_icon"
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/csi_synod_communications/?igshid=17kzz9dce9iz8&fbclid=IwAR0XTfYUpXG6v0-8XCp_uZH07H1DLxp1-G1Cjwnu6Z5uSOHp1M5YwVXE-sQ',
                      '_blank',
                    )}
                />
                <img
                  src={YoutubeFill}
                  alt="facebook_icon"
                  className="youtube_icon"
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/channel/UCjk01xPVy2mw1NP8w2JIRSQ',
                      '_blank',
                    )}
                />
                <img
                  src={Whatsapp}
                  alt="facebook_icon"
                  className="youtube_icon"
                  onClick={() =>
                    window.open(
                      'https://wa.me/+919840577404',
                      '_blank',
                    )}
                />
              </div>
              <div className="landing_page_main_logo_wrapper">
                <img src={Logo} alt="search_icon" className="search_icon" />
                <div className="landing_page_main_logo_title h4">
                  Church of South India
                </div>
              </div>
              <div className="landing_page_search_menu_content">
              {state.search ? (
              <img
                id="header_search_icon"
                src={InactivePink}
                alt="social_icons"
                className="header_close_icon"
                onClick={() => setState({ search: false, hamber: false })}
              />
            ) : (
              <img
                id="header_search_icon"
                src={SearchFill}
                alt="social_icons"
                className="header_search_icon"
                onClick={() => setState({ search: true, hamber: false })}
              />
            )}
                <img
                  src={Hamber}
                  alt="search_icon"
                  className="hamber_icon"
                  onClick={() => setState({ hamber: true, search: false })}
                />
              </div>
              {state.search && (
                <div className="landing_search_input_wrapper">
                  <div className="landing_search_input_content">
                    <div className="landing_input_search_icon_wrapper">
                      <img src={Search} alt="landing_input_search_icon" />
                      <Button
                        text="Search"
                        onClick={() => {}}
                        className="landing_input_search_button"
                      />
                    </div>
                    <input
                      name=""
                      type="text"
                      className="landing_search_input_field"
                      value={state.globalSearch}
                      onChange={(e) => setState({ globalSearch: e.target.value })}
                    />
                  </div>
                  {/* <div className="landing_search_input_button_wrapper">
                    <Button
                      text="Close"
                      onClick={() => setState({ search: false })}
                      className="landing_search_button"
                      icon={Inactive}
                    />
                  </div> */}
                  <div className="global_news_wrapper">
                    {state.globalNews.length > 0 ? (
                      state.globalNews.map((news) => {
                        return (
                          <div className="global_news_card">
                          <Newscard2
                            title={news.title}
                            description={moment(news.created_at).format(
                              'dddd, MMM D, YYYY',
                              )}
                            name={
                              news.department_name
                              ? news.department_name
                                : news.diocese_name
                              }
                            link={news.thumbnail}
                            onClick={() => {
                              window.open(
                                `/news_screen/${
                                  news.department_name
                                    ? 'departments'
                                    : 'dioceses'
                                  }/${news._id}`,
                                  );
                                }}
                            type="news_card"
                            text={news.heading}
                          />
                          </div>
                        );
                      })
                    ) : (
                      <div className="h3">No news found</div>
                    )}
                  </div>
                  <div className="global_search_button_wrapper">
                    <Button
                      text="Load more"
                      onClick={() => setState({ page: state.page + 1 })}
                      className="global_search_button search_button"
                    />
                  </div>
                </div>
              )}
              {state.hamber && (
                <div
                  id="header_menu_wrapper"
                  className="landing_header_menu_wrapper"
                >
                  <div className="landing_header_menu_content">
                    <div
                      className="landing_header_menu_content_name h4"
                      onClick={() => props.history.push('/about/contact_us')}
                    >
                      Get in Touch
                    </div>
                    <div
                      className="landing_header_menu_content_name h4"
                      onClick={() => props.history.push('/about/contact_us')}
                    >
                      Find Us
                    </div>
                    <div
                      className="landing_header_menu_content_name h4"
                      onClick={() => props.history.push('/about/contact_us')}
                    >
                      Have any Queries?
                    </div>
                  </div>
                  <div className="landing_header_menu_button_wrapper">
                    <img
                      src={Inactive}
                      alt="close_icon"
                      className="landing_header_menu_close_icon"
                      onClick={() => setState({ hamber: false })}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Div100vh>
  );
}
