import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import _ from "lodash";
import moment from "moment";
import { GET_DEPARTMENT_NEWS, GET_DEPARTMENT, GET_DEPARTMENT_NEWS_DETAILS, GET_ALL_DEPARTMENTS } from 'utils/types.utils';
import Button from "common_components/ui/button/button.ui";
import CheckList from "common_components/ui/checklist/checklist.ui";
import { getAllNews, deleteNews, getNewsDetails, editNews, getNews, updateActiveMultiple } from "models/news.model";
import { getAllDepartment, getAllDepartments } from "models/department.model";
import { departmentNavData, departmentNewsTableHeading, departmentNewsBody, csiUserSuggessionData } from "helper/constants.helper";
import Spinner from "common_components/ui/spinner/spinner.ui";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import InfoCard from "../../common_components/ui/info_card/info_card.ui";
import ActionButton from "../../common_components/ui/action_button/action_button.ui";
import Table from "../../components/table/table.component";
import { useSetState } from "../../utils/common.utils";
import "screens/department/department_news.screen.scss";
import PlusIcon from "../../assets/icons/Plus_White.svg";
import Filter from "../../assets/icons/Filter.svg";
import Tick from "../../assets/icons/Tick_White.svg";
import Inactive from "../../assets/icons/Inactive_Pink.svg";
import NewsIcon from "../../assets/icons/News.svg";
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";

export default function DepartmentNews(props) {
  const departmentNews = useSelector((state: any) => state.department.news);
  const departments = useSelector((state: any) => state.department.allDepartments);
  const departmentNewsDetails = useSelector((state: any) => state.department.newsDetails);
  const dispatch = useDispatch();
  const departmentUser = localStorage.getItem('department');
  const [state, setState] = useSetState(
    {
      selectedData: "department_news",
      checkList: [],
      checkedList: [],
      isChecked: false,
      confirmModal: false,
      tableData: departmentNewsBody,
      active: false,
      limit: 25,
      page: 1,
      filter: '',
      search: "",
      news: [],
      id: "",
      loading: true,
      activated: "all",
      gallery: [],
    });

  const handleGetAllDepartmentNews = async ({ page, limit, search }) => {
    try {
      const data = {
        limit,
        page,
        search: {
          department: search
        },
        activated: 'all',
        type: "departments",
        department: departmentUser ? [departmentUser] : state.filter
      };
      const news: any = await getAllNews(data);
      if (news !== undefined && news.data !== undefined && news.data.docs !== undefined) {
        const doc = news.data.docs.map((docs) => {
          docs.department_name = docs.departments.name;
          docs.author = docs.author.user_name;
          docs.description = docs.description.replace(/(<([^>]+)>)/gi, "");
          docs.date = moment(docs.date).format('ddd, MMM D, YYYY')
          return docs;
        })
        news.data.docs = doc;
        setState({ news: doc, loading: false });
        dispatch({
          type: GET_DEPARTMENT_NEWS,
          payload: news.data
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to load department news");
    }
  }

  interface DataArray {
    name: string,
    value: string,
  }

  const deptData: any = [];
  const dept = departments && departments.length !== 0
    && departments.map((department) => {
      deptData.push({ name: department.name, value: department._id });
      return "";
    });

  const handleGetNewsDetails = async () => {
    try {
      const data = {
        type: "departments",
        department: departmentUser ? departmentUser : ""
      }
      const newsDetails: any = await getNewsDetails(data);
      if (newsDetails) {
        dispatch({
          type: GET_DEPARTMENT_NEWS_DETAILS,
          payload: newsDetails.data
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get department news details");
    }
  }

  const handleGetAllDepartment = async () => {
    try {
      const department: any = await getAllDepartments();
        setState({ departmentData: department.data.docs });
        dispatch({
          type: GET_ALL_DEPARTMENTS,
          payload: department.data
        })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get departments");
    }
  }

  useEffect(() => {
    handleGetAllDepartmentNews(state);
    handleGetAllDepartment();
    handleGetNewsDetails();
  }, [state.search]);

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const handleOnClick = (edit) => {
    if (!edit) {
      setState({ openModal: true });
    } else {
      setState({ openModal: true });
      setState({ edit: true });
    }
  }

  const handleOnChangeList = (selectedData, multiple) => {
    if (multiple === false) {
      setState({ checkList: [selectedData] });
    } else {
      const findIndex = _.findIndex(state.checkList, { index: selectedData.index });
      if (findIndex === -1) {
        setState({ checkList: [...state.checkList, ...[selectedData]] });
      } else {
        const indexes: any[] = [];
        state.checkList.forEach((data) => {
          if (data.index !== selectedData.index) {
            indexes.push(data);
          }
        })
        setState({ checkList: indexes });
      }
    }
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = departmentNews.docs.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (departmentNews.docs.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleAddNews = () => {
    props.history.push({
      pathname: "/departments/add_news"
    })
  }

  const handleMakeNewsActive = async () => {
    try {
      const news = { news: state.checkedList, activated: true };
      const active = await updateActiveMultiple(news);
      await handleGetAllDepartmentNews({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ checkedList: [], isChecked: false, page: 1 })
    handleGetNewsDetails();
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to activate news");
    }
  }

  const handleMakeNewsInactive = async () => {
    try {
      const news = { news: state.checkedList, activated: false };
      const active = await updateActiveMultiple(news);
      handleGetAllDepartmentNews({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ checkedList: [], isChecked: false, page: 1 })
    handleGetNewsDetails();
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to activate news");
    }
  }

  const handleMakeNewsActiveAndInactive = async (id) => {
    try {
      setState({ edit: true, id });
      const news: any = await getNews(id);
      const data = {
        activated: !news.data.activated
      }
      const add: any = await editNews(id, data);
      await handleGetAllDepartmentNews({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      handleGetNewsDetails();
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to activate news");
    }
  }

  const handleEditNews = (id) => {
    props.history.push({
      pathname: `/departments/edit_news/${id}`,
    })
  }

  const handleDeleteNews = (id) => {
    setState({ confirmModal: true, id })
  }

  const loadMore = async ({ limit, page, search }) => {
    try {
      const data = {
        limit,
        page,
        search: {
          department: search
        },
        type: "departments",
        activated: state.activated,
        department: departmentUser ? [departmentUser] : state.filter
      };
      const news: any = await getAllNews(data);
      if (news !== undefined && news.data !== undefined && news.data.docs !== undefined) {
        const doc = news.data.docs.map((docs) => {
          docs.department_name = docs.departments.name;
          docs.author = docs.author.user_name;
          docs.description = docs.description.replace(/(<([^>]+)>)/gi, "");
          docs.date = moment(docs.date).format('ddd, MMM D, YYYY')
          return docs;
        })
        news.data.docs = doc;
        news.data.docs = [...departmentNews.docs, ...news.data.docs];
        dispatch({
          type: GET_DEPARTMENT_NEWS,
          payload: news.data
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to load department news");
    }
  }
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    });
  };
  const searchChange = async (e) => {
    setState({ search: e.target.value, page: 1 })
    // try {
    //   const data = {
    //     limit: state.limit,
    //     page: state.page,
    //     search: {
    //       department: e.target.value
    //     },
    //     type: "departments",
    //     activated: state.activated,
    //     department: departmentUser ? [departmentUser] : ""
    //   };
    //   const news: any = await getAllNews(data);
    //   if (news !== undefined && news.data !== undefined && news.data.docs !== undefined) {
    //     const doc = news.data.docs.map((docs) => {
    //       docs.department_name = docs.departments.name;
    //       docs.author = docs.author.user_name;
    //       docs.created_at = moment().format('DD/MM/YYYY')
    //       return docs;
    //     })
    //     news.data.docs = doc;
    //     setState({ news: doc });
    //     dispatch({
    //       type: GET_DEPARTMENT_NEWS,
    //       payload: news.data
    //     })
    //   }
    // } catch (err:any) {
    //   props.throwError(err.data ? err.data.desc : "Failed to search department news");
    // }
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit News",
      onClick: handleEditNews,
      icon: Edit
    },
    {
      type: "secondary",
      text: "Delete News",
      onClick: handleDeleteNews,
      icon: Delete
    },
  ];

  const handleOnDeleteNews = async () => {
    try {
      const news = await deleteNews(state.id)
      await handleGetAllDepartmentNews({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      await handleGetNewsDetails();
      setState({ confirmModal: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete department news");
    }
  }

  const handleApplyFilter = async () => {
    try {
      const departmentFilter: any = [];
      state.checkList.map((d) => {
        departmentFilter.push(d.value);
        return null;
      })
      setState({ filter: departmentFilter, page: 1 })
      const data = {
        limit: state.limit,
        page: 1,
        search: {
          department: state.search
        },
        type: "departments",
        activated: state.activated,
        department: departmentUser ? [departmentUser] : departmentFilter
      };
      const news: any = await getAllNews(data);
      if (news !== undefined && news.data !== undefined && news.data.docs !== undefined) {
        const doc = news.data.docs.map((docs) => {
          docs.department_name = docs.departments.name;
          docs.author = docs.author.user_name;
          docs.date = moment(docs.date).format('ddd, MMM D, YYYY')

          return docs;
        })
        news.data.docs = doc;
        dispatch({
          type: GET_DEPARTMENT_NEWS,
          payload: news.data
        })
        setState({ openModal: false })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to load department news");
    }
  }
  return (
    <div className="department_news_screen">
      <div className="department_news_container">
        <div className="department_news_custom_nav_wrapper">
          <CustomNavBar
            data={departmentNavData}
            onTapClick={handleChangeClick}
            selectedItem={state.selectedData}
            buttonText="Filter"
            buttonType="primary"
            buttonIcon={Filter}
            searchText="Search..."
            searchType="secondary"
            suggession={csiUserSuggessionData}
            buttonOnClick={handleOnClick}
            button
            search
            onSearchChange={searchChange}
          />
        </div>
        <div className="department_news_info_card_wrapper">
          <div className="department_news_info_card h6"><InfoCard title="Total No of News" text={departmentNewsDetails && departmentNewsDetails.length > 0 ? departmentNewsDetails[0].total_news : 0} icon={NewsIcon} /></div>
          <div className="department_news_info_card h6"><InfoCard title="No. of Active News" text={departmentNewsDetails && departmentNewsDetails.length > 0 ? departmentNewsDetails[0].activate_news : 0} icon={NewsIcon} /></div>
          <div className="department_news_info_card h6"><InfoCard title="No. of Inactive News" text={departmentNewsDetails && departmentNewsDetails.length > 0 ? departmentNewsDetails[0].in_activate_news : 0} icon={NewsIcon} /></div>
          <div className="department_news_action_button_wrapper">
            <div className="department_news_action_button">
              <ActionButton text="Add News" icon={PlusIcon} type="secondary" onClick={handleAddNews} />
            </div>
            {!departmentUser
            && <>
                <div className="department_news_action_button">
                  <ActionButton text="Make Active" icon={Tick} type="secondary" onClick={handleMakeNewsActive} />
                </div>
                <div className="department_news_action_button">
                  <ActionButton text="Make Inactive" icon={Inactive} type="primary" onClick={handleMakeNewsInactive} />
                </div>
               </>}
          </div>
        </div>
        <div className="department_news_table_wrapper">
          {
            state.loading ? <Spinner />
              : <Table
                  header={departmentNewsTableHeading}
                  body={departmentNews ? departmentNews.docs : []}
                  isChecked={state.isChecked}
                  checkAll={checkAll}
                  checkOne={checkOne}
                  checkedData={state.checkedList}
                  loadMore={loadMoreInitial}
                  hasMore={departmentNews && departmentNews.hasNextPage ? departmentNews.hasNextPage : ''}
                  actionButton={actionArray}
                  active={!departmentUser ? true : false}
                  onClick={handleMakeNewsActiveAndInactive}
                  error="No Department News found"
              />
          }
        </div>
        <div>
          <Modal
            open={state.openModal}
            onClose={() => setState({ openModal: false })}
            center
            classNames={{
              overlay: 'customOverlay', modal: 'department_news_modal'
            }}
            showCloseIcon={false}
          >
            <div className="department_news_modal_container">
              <div className="department_news_modal_wrapper">
                <div className="department_news_modal_header_wrapper">
                  <div className="department_news_modal_header h4">
                    Status
                  </div>
                  <div className="department_news_modal_clear h6" onClick={() => setState({ checkedList: "", activated: "all" })}>
                    Clear All
                  </div>
                </div>
                <div className="department_news_button_wrapper">
                    <div className="button_active">
                    <Button text="Active" type={state.activated === true ? "primary" : "secondary"} onClick={() => setState({ activated: true })} />
                    </div>
                   <div className="button_inactive">
                    <Button text="Inactive" type={state.activated === false ? "primary" : "secondary"} onClick={() => setState({ activated: false })} />
                   </div>
                </div>
                {
                  departmentUser
                    ? null
                  : <div className="department_news_modal_header_wrap">
                      <div className="department_news_modal_header_wrapper">
                        <div className="department_news_modal_department_heading h4">
                          Department
                        </div>
                        <div className="department_news_modal_department_clear h6" onClick={() => setState({ checkList: [] })}>
                          Clear All
                        </div>
                      </div>
                      <div className="department_news_modal_checklist">
                        <CheckList data={deptData ? deptData : []} icon={Tick} multiple selectedData={state.checkList} iconBackground="" onChange={handleOnChangeList} list={state.checkList} multipleList={state.checkList} />
                      </div>
                    </div>
                }
                <div className="department_news_apply_filter_button_wrapper">
                  <div className="department_news_button">
                    <Button text="Apply Filters" type="primary" onClick={handleApplyFilter} />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="department_news_confirm_modal_wrapper">
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete the Department News ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteNews} />
        </div>
      </div>
    </div>
  )
}
