import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './aim_and_objectives.screen.scss';
import '../../index.scss';
import Footer from 'components/footer/footer.component';
import Header from 'components/header/header.component';
import Navbar from 'components/navbar/navbar.component';
import { getAllDepartmentsBySubDomain, getDepartmentById, getAllUser } from "models/public.model";
import { useDispatch, useSelector } from 'react-redux';
import { throwError, useSetState } from 'utils/common.utils';
import parse from 'html-react-parser';
import { GET_DEPARTMENT, GET_USERS, THROW_ERROR } from 'utils/types.utils';
import OfficerCard from 'components/officer_card/officer_card.component';

export default function AimScreen(props) {
  const { match } = props;
  const parsedData = window.location.host.split('.');
  const departmentNews = useSelector((state: any) => state.department.news);
  const departmentData = useSelector((state: any) => state.department);
  const departmentPersonnels = useSelector(
    (state: any) => state.users.user_list
  );
  const departmentDirector = departmentPersonnels
  && departmentPersonnels.filter(
    (user) => user.role === 'Director',
  );
  const directorInCharge = departmentPersonnels
  && departmentPersonnels.filter(
    (user) => user.role === 'Director In-Charge',
  );
  const honDirector = departmentPersonnels
  && departmentPersonnels.filter(
    (user) => user.role === 'Hon. Director',
  );
  const honAssociateDirector = departmentPersonnels
  && departmentPersonnels.filter(
    (user) => user.role === 'Hon. Associate Director',
  );
  const honAdvisor = departmentPersonnels
  && departmentPersonnels.filter(
    (user) => user.role === 'Hon. Advisor',
  );
  const directorInCharges = departmentPersonnels
  && departmentPersonnels.filter(
    (user) => user.role === 'Hon. Director In-Charges',
  );
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    selectedData: 'department_news',
    limit: 20,
    page: 1,
    search: '',
    id: '',
    loading: true,
    activated: 'all',
    aim: '',
    departmentName: '',
  });
  const handleGetDepartment = async () => {
    try {
      const res: any = await getDepartmentById(match.params.id);
      if (res !== undefined && res.data !== undefined) {
        setState({
          departmentName: res.data.name,
          loading: false,
          aim: res.data.aim !== undefined ? res.data.aim : '',
        });
      }
    } catch (err:any) {
        throwError(err.data ? err.data.desc : "Failed to get Department")
    }
  };
  const handleGetAllPersonnels = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
        search: '',
        department: [match.params.id],
        user_sort: true,
        type: 'department_personnels',
      };
      const res: any = await getAllUser(data);
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
      setState({ loading: false });
    } catch (err:any) {
       throwError(err.data ? err.data.desc : "Failed to get Personnels")
    }
  };
//   const handleGetAllDepartmentBySubdomain = async () => {
//     try {
//         const data = {
//             limit: 35,
//             page: state.page,
//             search: state.search,
//             sub_domain: parsedData ? parsedData[0] : ""
//         };

//         const res: any = await getAllDepartmentsBySubDomain(data);
//         if (res && res.data._id !== undefined) {
//           handleGetAllPersonnels(res.data._id);
//         }
//         dispatch({
//             type: GET_DEPARTMENT,
//             payload: res.data,
//         });
//         setState({ loading: false, id: res.data._id });
//     } catch (err:any) {
//         dispatch({
//             type: THROW_ERROR,
//             payload: err,
//         });
//     }
// };
  useEffect(() => {
    // handleGetAllDepartmentBySubdomain()
    handleGetDepartment()
    handleGetAllPersonnels()
  }, []);
  return (
    <div className="aim_screen">
      <div className="header_wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="navbar_wrapper" style={{ position: 'sticky', top: 0, zIndex: 9999 }}>
          <Navbar {...props} />
        </div>
      </div>
      <div className="aim_screen_container">
        <div className="aim_screen_header_wrapper">
          <div className="aim_screen_header h2">{state.departmentName}</div>
          <div className="header_line" />
        </div>
        <div className="aim_screen_title_wrapper">
          <div className="aim_screen_title h1">
            <div className="aim_links_wrapper">
              <div className="aim_link_1 h3">
                <NavLink
                  // to={`/department/${match.params.id}`}
                  to="/"
                  className="title_link"
                >
                  News & Events
                </NavLink>
              </div>
              <div className="aim_link h3">
                <NavLink
                  to={`/aim/${state.id}`}
                  className="title_link"
                  activeClassName="selected"
                >
                  Aim, Objectives & Personnel
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="aim_body_wrapper">
          <div className="aim_section_left">
            <div className="left_title_wrapper">
              <div className="left_description">{parse(state.aim)}</div>
            </div>
            <div className="director_section_wrapper">
             { departmentDirector && departmentDirector.length > 0
             && <div className="left_title_wrapper">
                  <div className="h3 left_heading">Director</div>
                  <div className="header_line director" />
                </div>}
              <div className="director_content_wrapper">
                {departmentDirector && departmentDirector.length > 0
                  ? departmentDirector.map((officer) => {
                      return (
                        <>
                          <div className="dept_card_wrapper">
                          <OfficerCard types="officers" email={officer.email} profilePicture={officer.profile_picture} name={officer.user_name} profession={officer.designation} location={officer.address} mobile={officer.mobile} phoneFax={officer.phone_fax} className="department_card" />
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            </div>
              {directorInCharge && directorInCharge.length > 0
              && <div className="left_title_wrapper">
                <div className="h3 left_heading">Director In-Charge</div>
                <div className="header_line director" style={{ marginBottom: '1em' }} />
                 </div>}
              <div className="director_content_wrapper">
                {directorInCharge && directorInCharge.length > 0
                  ? directorInCharge.map((officer) => {
                      return (
                        <>
                          <div className="dept_card_wrapper">
                          <OfficerCard types="officers" email={officer.email} profilePicture={officer.profile_picture} name={officer.user_name} profession={officer.designation} location={officer.address} mobile={officer.mobile} phoneFax={officer.phone_fax} className="department_card" />
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            {honDirector && honDirector.length > 0
            && <div className="left_title_wrapper">
                <div className="h3 left_heading">Hon. Director</div>
                <div className="header_line director" style={{ marginBottom: '1em' }} />
               </div>}
              <div className="director_content_wrapper">
                {honDirector && honDirector.length > 0
                  ? honDirector.map((officer) => {
                      return (
                        <>
                          <div className="dept_card_wrapper">
                          <OfficerCard types="officers" email={officer.email} profilePicture={officer.profile_picture} name={officer.user_name} profession={officer.designation} location={officer.address} mobile={officer.mobile} phoneFax={officer.phone_fax} className="department_card" />
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
              {honAssociateDirector && honAssociateDirector.length > 0
              && <div className="left_title_wrapper">
                <div className="h3 left_heading">Hon. Associate Director</div>
                <div className="header_line director" style={{ marginBottom: '1em' }} />
                 </div>}
              <div className="director_content_wrapper">
                {honAssociateDirector && honAssociateDirector.length > 0
                  ? honAssociateDirector.map((officer) => {
                      return (
                        <>
                          <div className="dept_card_wrapper">
                          <OfficerCard types="officers" email={officer.email} profilePicture={officer.profile_picture} name={officer.user_name} profession={officer.designation} location={officer.address} mobile={officer.mobile} phoneFax={officer.phone_fax} className="department_card" />
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
              {honAdvisor && honAdvisor.length > 0
              && <div className="left_title_wrapper">
                <div className="h3 left_heading">Hon. Advisor</div>
                <div className="header_line director" style={{ marginBottom: '1em' }} />
                 </div>}
              <div className="director_content_wrapper">
                {honAdvisor && honAdvisor.length > 0
                  ? honAdvisor.map((officer) => {
                      return (
                        <>
                          <div className="dept_card_wrapper">
                          <OfficerCard types="officers" email={officer.email} profilePicture={officer.profile_picture} name={officer.user_name} profession={officer.designation} location={officer.address} mobile={officer.mobile} phoneFax={officer.phone_fax} className="department_card" />
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
              {directorInCharges && directorInCharges.length > 0
              && <div className="left_title_wrapper">
                <div className="h3 left_heading">Hon. Director In-Charges </div>
                <div className="header_line director" style={{ marginBottom: '1em' }} />
                 </div>}
              <div className="director_content_wrapper">
                {directorInCharges && directorInCharges.length > 0
                  ? directorInCharges.map((officer) => {
                      return (
                        <>
                          <div className="dept_card_wrapper">
                          <OfficerCard types="officers" email={officer.email} profilePicture={officer.profile_picture} name={officer.user_name} profession={officer.designation} location={officer.address} mobile={officer.mobile} phoneFax={officer.phone_fax} className="department_card" />
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            <div className="personnel_wrapper">
              <div className="left_title_wrapper">
                <div className="h3 left_heading ">Personnel</div>
                <div className="header_line personnel" />
              </div>
              <div className="personnel_image_wrapper">
                {departmentPersonnels && departmentPersonnels.length > 0
                  ? departmentPersonnels.filter((personnel) => personnel.role === "Department Executive").map((personnel) => {
                      return (
                        // <div className="personnel_image">
                        //   <img
                        //     src={personnel.profile_picture}
                        //     alt="personnel"
                        //   />
                        //   <div className="h4 personnel_name">
                        //     {personnel.user_name}
                        //   </div>
                        // </div>
                        <OfficerCard types="officers" email={personnel.email} profilePicture={personnel.profile_picture} name={personnel.user_name} profession={personnel.designation} location={personnel.address} mobile={personnel.mobile} phoneFax={personnel.phone_fax} className="department_card" />
                      );
                    })
                  : 'No department personnel added'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
