import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compressImage, uploadMediaFiles, uploadMultipleFiles, useSetState } from 'utils/common.utils';
import Button from 'common_components/ui/button/button.ui';
import Select from 'common_components/ui/select_box/select_box.ui';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import AddNewsComponent from 'components/add_news/add_news.component';
import './diocese_add_news.screen.scss';
import {
  addNews,
  editNews,
  getNews,
  removeMedia,
  uploadMedia,
} from 'models/news.model';
import { getAllDiocese, getAllDioceses } from 'models/diocese.model';
import Input from 'common_components/ui/input_box/input_box.ui';
import { GET_ALL_DIOCESES, GET_DIOCESE } from 'utils/types.utils';
import TextArea from 'common_components/ui/text_area/text_area.ui';
import { toast } from 'react-toastify';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import Arrowleft from '../../assets/icons/Arrow.svg';

const DioceseAddNews = (props) => {
  const { match } = props;
  const imgRef = useRef(null);
  const [upImg, setUpImg] = useState();
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", height: 50, width: 100, x: 0, y: 0, maxHeight: 50 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  const dispatch = useDispatch();
  const dioceses = useSelector((state: any) => state.diocese.allDioceses);
  const dioceseUser = localStorage.getItem('diocese');
  const [state, setState] = useSetState({
    text: '',
    selectedData: '',
    thumbnail: '',
    heading: '',
    gallery: [],
    date: '',
    diocese: '',
    limit: 20,
    uploadFile: '',
    title: '',
    page: 1,
    img: '',
    pdf: [],
    fileUploading: false,
    uploaded: false,
    galleries: [],
  });
  interface DioceseArray {
    label: string;
    value: string;
  }
  const dioceseData: DioceseArray[] = [];
  const dio = dioceses && dioceses.length !== 0 && dioceses.map((diocese) => {
      dioceseData.push({ label: diocese.name, value: diocese._id });
      return '';
    });
  const getNewsById = async () => {
    try {
      const news: any = await getNews(match.params.id);
      if (news !== undefined) {
        setState({
          title: news.data.title,
          text: news.data.description,
          thumbnail: news.data.thumbnail,
          gallery: news.data.gallery,
          diocese: news.data.diocese,
          heading: news.data.heading,
          date: news.data.date,
          pdf: [...state.pdf, news.data.pdf],
          pdfUploaded: news.data.pdf ? true : false
        });
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to get diocese news');
    }
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader: any = new FileReader();
      setState({ uploadFile: e.target.files[0].name })
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleInputChange = (value) => {
    setState({ text: value });
  };
  const handleGetAllDiocese = async () => {
    try {
      const diocese: any = await getAllDioceses();
      if (
        diocese !== undefined && diocese.data !== undefined && diocese.data.docs !== undefined
      ) {
        dispatch({
          type: GET_ALL_DIOCESES,
          payload: diocese.data,
        });
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to get diocese');
    }
  };
  useEffect(() => {
    if (match.params.id) {
      getNewsById();
    }
    handleGetAllDiocese();
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
          }
    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    const newCrop: any = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = newCrop.width * pixelRatio;
    canvas.height = newCrop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);
  const uploadThumbnail = () => {
    setState({ cropModal: true })
    // try {
    //   if (e.dataTransfer) {
    //     setState({ fileUploading: true });
    //     const thumbnail: any = await uploadMediaFiles(e);
    //     if (thumbnail) {
    //       setState({
    //         thumbnail: thumbnail.url,
    //         uploaded: true,
    //         fileUploading: false,
    //       });
    //     }
    //   } else {
    //     setState({ fileUploading: true, cropModal: true });
    //     if (e.target.files && e.target.files.length > 0) {
    //       const reader: any = new FileReader();
    //       reader.addEventListener("load", () => setState({ img: reader.result }));
    //       reader.readAsDataURL(e.target.files[0]);
    //     }
    //     canvas.toBlob(
    //       (blob) => {
    //         const file = new File([blob], `${Date.now()}`, { type: 'image/jpeg' })
    //     },
    //       "image/png",
    //       1
    //     );
    //   }
    // } catch (err:any) {
    //   props.throwError(err.data ? err.data.desc : 'Failed to Upload Thumbnail');
    // }
  };

  const uploadGallery = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/jpg|\/jpeg|\/png|\/gif)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ galleryUploading: true });
          const res: any = await uploadMultipleFiles(e);
          console.log(res)
          const datas = [...state.gallery, ...res.data];
          setState({ gallery: datas });
        } else {
          props.throwError('Upload Image Format Jpg, Png, Gif');
        }
      } else {
        setState({ galleryUploading: true });
        const gallery: any = [];
        const res: any = await uploadMultipleFiles(e);
        const datas = [...state.gallery, ...res.data];
        setState({ gallery: datas });
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : 'Failed to Upload Gallery');
    }
  };

  const handleAddNews = async () => {
    try {
      const data = {
        description: state.text,
        title: state.title,
        diocese: dioceseUser ? dioceseUser : state.diocese,
        thumbnail: state.thumbnail,
        gallery: state.gallery,
        heading: state.heading,
        type: 'dioceses',
        date: new Date(state.date),
        pdf: state.pdf
      };
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        const res: any = await addNews(data);
        props.history.push('/dioceses');
      }
    } catch (err:any) {
      console.log(err)
      props.throwError(err.data ? err.data.desc : 'Failed to add diocese news');
    }
  };

  const handleUpdateNews = async () => {
    try {
      const data = {
        title: state.title,
        heading: state.heading,
        description: state.text,
        diocese: dioceseUser ? dioceseUser : state.diocese,
        thumbnail: state.thumbnail,
        gallery: state.gallery,
        date: new Date(state.date),
        pdf: state.pdf
      };
      if (state.thumbnail === "") {
        toast.error("Please upload thumbnail")
      } else {
        await editNews(match.params.id, data);
        props.history.push('/dioceses');
      }
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to update diocese news',
      );
    }
  };
   const generateDownload = async (canvas, img) => {
    // if (!crop || !canvas) {
    //   return;
    // }
    canvas.toBlob(
      async (blob) => {
        const file: any = new File([blob], state.uploadFile, { type: 'image/jpeg' })
        const data = new FormData();
        const ext = file.name.split('.').pop();
        const filename = file.name.split('.')[0].replace(/ /g, '')
        const newFileName = `${filename}.${ext}`;
        data.append('file', file, newFileName);
      const image: any = await uploadMedia(data);
      setState({ thumbnail: image.url })
      },
      "image/jpg",
      1
      );
      setState({ cropModal: false })
  }
  const removeThumbnail = async () => {
    try {
      const data = {
        resource_url: state.thumbnail,
      };
      await removeMedia(data);
      setState({ thumbnail: '' });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to remove gallery image',
      );
    }
  };
  const handleOnChange = (e, key) => {
    setState({ [key]: e.target.value });
  }

  const removeGallery = async (id) => {
    try {
      const galleries = state.gallery.splice(id, 1);
      const data = { resource_url: galleries[0] };
      const removeGalleryImage = await removeMedia(data);
      setState({ gallery: state.gallery });
    } catch (err:any) {
      props.throwError(
        err.data ? err.data.desc : 'Failed to remove gallery image',
      );
    }
  };
  const handleUploadPDF = async (e) => {
    try {
      if (e.dataTransfer) {
        const allowedFormats = /(\/pdf)$/i;
        if (allowedFormats.exec(e.dataTransfer.files[0].type)) {
          setState({ pdfLoading: true });
          const data = new FormData();
          const ext = e.dataTransfer.files[0].name.split('.').pop();
          const filename = e.dataTransfer.files[0].name
            .split('.')[0]
            .replace(/ /g, '');
          const newFileName = `${filename}${Date.now()}.${ext}`;
          const newFile = new File([e.dataTransfer.files[0]], newFileName);
          data.append('file', newFile);
          const pdf: any = await uploadMedia(data);
          if (pdf) {
            setState({ pdf: [...state.pdf, pdf.url] });
            const fileName = pdf.url.split('/')[4];
            setState({
              resourceName: fileName,
              pdfUploaded: true,
              pdfLoading: false,
            });
          }
        } else {
          props.throwError('File is not supported! Please Upload Pdf');
        }
      } else {
        const data = new FormData();
        setState({ pdfLoading: true });
        const ext = e.target.files[0].name.split('.').pop();
        const filename = e.target.files[0].name.split('.')[0].replace(/ /g, '');
        const newFileName = `${filename}${Date.now()}.${ext}`;
        const newFile = new File([e.target.files[0]], newFileName);
        data.append('file', newFile);
        const pdf: any = await uploadMedia(data);
        if (pdf) {
          setState({ pdf: [...state.pdf, pdf.url] });
          const fileName = pdf.url.split('/')[4];
          setState({
            resourceName: fileName,
            pdfUploaded: true,
            pdfLoading: false,
          });
        }
      }
    } catch (err:any) {
      console.log(err)
      props.throwError(err.data ? err.data.desc : 'Failed to Upload pdf');
    }
  };
  const handleDioceseChange = (selectedOption) => {
    setState({ diocese: selectedOption.value });
  };
  return (
    <div className="add_news_screen">
      <div className="add_news_content">
        <div className="add_news_head">
          <div className="diocese_view_navigate_icon_wrapper">
            <div className="diocese_view_navigate_icon">
              <img
                className="navigate_icon"
                alt=""
                src={Arrowleft}
                onClick={() => props.history.push('/dioceses/diocese_news')}
              />
            </div>
          </div>
          <div className="add_news_head_text h3">
            {match.params.id ? 'Edit News' : 'Add News'}
          </div>
          <div className="add_news_btn_container">
            {
              dioceseUser ? null
          : <div className="add_news_select">
              <Select
                selectedItem={state.diocese}
                name="Select Diocese"
                data={dioceseData ? dioceseData : []}
                onChange={handleDioceseChange}
              />
            </div>
            }
            <div className="add_news_btn">
              <Button
                type="primary"
                text={match.params.id ? 'Update' : 'Add'}
                onClick={match.params.id ? handleUpdateNews : handleAddNews}
              />
            </div>
          </div>
        </div>
        <div className="diocese_news_content">
        <div className="news_content_date_wrapper">
          <Input name="Date" onChange={handleOnChange} type="date" inputKey="date" value={moment(state.date).format("YYYY-MM-DD")} />
        </div>
        <div className="diocese_news_content_title_wrapper">
        <TextArea name="Title" onChange={handleOnChange} inputKey="title" value={state.title} maxLength={90} />
        </div>
        <div className="diocese_news_content_heading_wrapper">
          <TextArea name="Sub-heading" onChange={handleOnChange} inputKey="heading" value={state.heading} maxLength={130} />
        </div>
          <AddNewsComponent
            text={state.text}
            thumbnail={state.thumbnail}
            gallery={state.gallery}
            textAreaOnchange={handleInputChange}
            uploadThumbnail={uploadThumbnail}
            uploadGallery={uploadGallery}
            removeThumbnail={removeThumbnail}
            removeGallery={removeGallery}
            type="news"
            loading={state.pdfLoading}
            uploaded={state.pdfUploaded}
            pdfUpload={handleUploadPDF}
          />
        </div>
        <Modal
          open={state.cropModal}
          onClose={() => setState({ cropModal: false })}
          center
          classNames={{
            overlay: 'customOverlay',
            modal: 'modal_container_crop',
            closeButton: 'closeButton',
          }}
          showCloseIcon
        >
          <div className="crop_wrapper">
          <div>
        <input type="file" accept="image/*" onChange={onSelectFile} />
          </div>
          <div className="react_image_crop">

          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
          </div>
          <canvas
            ref={previewCanvasRef}
            className="canvas_crop"
          //   style={{
          //   width: '100%',
          //   height: '100%'
          // }}
          />
          <div className="crop_button_wrapper">

          <Button
            type="primary"
            text="Add thumbnail"
            className="crop_btn"
            onClick={() =>
              generateDownload(previewCanvasRef.current, completedCrop)}
          />
          </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default DioceseAddNews;
