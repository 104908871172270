import React, { useState } from 'react';
import _ from "lodash";
import UploadBox from 'common_components/ui/upload_box/upload_box.ui';
import TitleCard from 'common_components/ui/title_card/title_card.ui';
import InfoCard from 'common_components/ui/info_card/info_card.ui';
import CheckList from 'common_components/ui/checklist/checklist.ui';
import InfoIcon from "../../assets/icons/News.svg";
import CheckedIcon from "../../assets/icons/Tick_White.svg";
import { useSetState } from "../../utils/common.utils";
import Subscribe from "../subscribe/subscribe.component"
import DownloadApp from "../download_app/download_app.component";
import Swiper from "../swiper/swiper.component";
import Phoneimages from "../../assets/images/csi_mobile_app.png";
import AppleIcon from "../../assets/icons/App_Store_Badge.svg"
import GoogleIcon from "../../assets/icons/Google_Play_Store_badge.svg"
import publicationGirl from "../../assets/images/publication_girl.png";
import publicationBird from "../../assets/images/publication_bird.png";
import "./testsunil.component.scss";

export default function Test() {
  const array = ["Pastrol Concerns", "Youth", "Communication", "Mission & Evangelism", "Prakash", "Mukunthan"];
  const publication = [publicationBird, publicationGirl,
    publicationBird, publicationGirl, publicationGirl];
  const [state, setState] = useSetState({ checkList: [] });

  const handleOnChangeList = (selectedData, multiple) => {
    if (multiple === false) {
      setState({ checkList: [selectedData] });
    } else {
      const findIndex = _.findIndex(state.checkList, { index: selectedData.index });
      if (findIndex === -1) {
        setState({ checkList: [...state.checkList, ...[selectedData]] });
      } else {
        const indexes: any[] = [];
        state.checkList.forEach((data) => {
          if (data.index !== selectedData.index) {
            indexes.push(data);
          }
        })
        setState({ checkList: indexes });
      }
      console.log(state.checkList);
    }
  };
  return (
    <div className="test_container">
      <TitleCard title="Communication" deleteAction={() => { console.log("deleted") }} editAction={() => { console.log("edited") }} className="" />
      <InfoCard title="Total No. of News" text="850" icon={InfoIcon} />
      <CheckList data={array} selectedData={state.checkList} multiple icon={CheckedIcon} iconBackground="" onChange={handleOnChangeList} list={state.checkList} multipleList={state.checkList} />
      <DownloadApp description="Download Our App" title="CSI Book of Common Workship" phoneImage={Phoneimages} googleStoreIcon={GoogleIcon} appleStoreIcon={AppleIcon} />
    </div>
  )
}
