import React, { useEffect, useRef } from "react";
import "./navbar.component.scss";
import ReactDOM from 'react-dom';
import { NavLink, useLocation } from 'react-router-dom';
import { throwError, useSetState } from 'utils/common.utils';
import { getAllDioceses, getAllDepartments, globalSearch } from "models/public.model";
import { GET_ALL_DEPARTMENTS, GET_ALL_DIOCESES } from "utils/types.utils";
import { useDispatch, useSelector } from "react-redux";
import Inactive from "assets/icons/Inactive_black.svg";
import Newscard2 from 'components/news_card_2/news_card_2.component';
import NavIcon from "assets/icons/Arrow.svg";
import moment from "moment";
import Button from "components/button/button.component";
import HamberFill from '../../assets/icons/hamburger.svg';
import Search from '../../assets/icons/search_white.svg';
import Popup from '../popup/popup.component';

export default function Navbar(props) {
  const dispatch = useDispatch()
  const departments = useSelector((state: any) => state.department.allDepartments)
  const dioceses = useSelector((state: any) => state.diocese.allDioceses)
  const resources = [{ name: 'Worship Resources', url: "/resources/worship_resource/prayer_calendar" }, { name: 'Publication', url: "/resources/publications_screen" }, { name: 'CSI Life', url: "/resources/csi_life_screen" }, { name: 'Resource Docs', url: "/resources/resource_docs" }];
  const others = [{ name: 'Press Release', url: "/others/press_release" }, { name: 'Statements', url: "/others/statement" }, { name: 'Library & Archives', url: "/others/library_archive" }, { name: 'Govt.Notification Schemes', url: "/others/government_notification_schemes" }];
  const aboutUs = ['About', 'Administration', 'Partners & Networks', 'Contact'];
  const dioceseState = ['Andhra', 'Karnataka', 'Kerala', 'Tamil Nadu', 'Telengana', 'Jaffna'];
  const [state, setState] = useSetState({ pop_up: "",
  tab_name: "",
  hover: false,
  hamber: false,
  diocese: false,
  limit: 3,
  dropdownActive: false,
  page: 1,
  department: false,
  resources: false,
  searchNews: false,
  content: false,
  globalSearch: '',
  globalNews: [],
  heading: "",
  others: false,
  showDropdown: false,
  aboutContent: false,
  subContent: [],
  dioceseContent: "",
  dioceseSubContent: []
   });
  const clickRef = useRef(null);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setState({ pop_up: "", tab_name: "", hover: false, hover_content: "" })
        }
      }
      // Mouse Over
      document.addEventListener("mouseover", handleClickOutside);
    }, [ref]);
  }

  useOutsideAlerter(clickRef);

  const popHover = (key) => {
    setState({ hover_content: key })
  }

  const renderLikeList = (key) => {
    return (
      key !== "home"
      && <div className="tab_lists">
          <Popup type={key} popHover={popHover} />
         </div>
    )
  }

  const handleLeave = (key) => {
    // setState({ pop_up: "", tab_name: "", hover: false });
  }
  const handleHover = (key) => {
    setState({ pop_up: renderLikeList(key), tab_name: key, hover: true });
  }
  const getDepartments = async () => {
    try {
      const res: any = await getAllDepartments()
      dispatch({
        type: GET_ALL_DEPARTMENTS,
        payload: res.data
      })
    } catch (err:any) {
      throwError('Failed to get departments')
    }
  }
  const getDioceses = async () => {
    try {
      const res: any = await getAllDioceses()
      dispatch({
        type: GET_ALL_DIOCESES,
        payload: res.data
      })
    } catch (err:any) {
      throwError('Failed to get dioceses')
    }
  }
  const getSubContent = (content) => {
    const About = [{ name: 'History', url: "/about/history" }, { name: 'Vision', url: "/about/history" }, { name: 'Mission', url: "/about/history" }, { name: 'Logo', url: "/about/history" }];
    const Administration = [{ name: 'Officers', url: "/about/officer" }, { name: 'Working Committee', url: "/about/working_committee" }, { name: 'Executive Committee', url: "/about/executive_committee" }, { name: 'CSITA', url: "/about/csita" }, { name: 'Secretarial Staff', url: "/about/secretarial_staff/Staff%20Under%20General%20Secretary" }];
    const Partners = [{ name: 'Partners', url: "/about/partners_screen" }, { name: 'Networks', url: "/about/networks_screen" }, { name: 'Campaign', url: "/about/campaign_list_screen" }];
    const Contact = [{ name: 'Contact Us', url: "/about/contact_us" }];
    if (content === "About") {
      setState({
        aboutContent: content,
        subContent: About,
        dropdownActive: !state.dropdownActive,
        showDropdown: !state.showDropdown })
    } else if (content === "Administration") {
      setState({
        aboutContent: content,
        subContent: Administration,
        dropdownActive: !state.dropdownActive,
        showDropdown: !state.showDropdown
      })
    } else if (content === "Partners & Networks") {
      setState({
        aboutContent: content,
        subContent: Partners,
        dropdownActive: !state.dropdownActive,
        showDropdown: !state.showDropdown
      })
    } else {
      setState({
        aboutContent: content,
        subContent: Contact,
        dropdownActive: !state.dropdownActive,
        showDropdown: !state.showDropdown
       })
    }
  }

  const getDioceseBasedOnState = (states) => {
    const dioceseStates = dioceses.filter((m) => m.state === states);
    setState({
      dioceseContent: states,
      dioceseSubContent: dioceseStates,
      dropdownActive: true,
      showDropdown: !state.showDropdown })
  }

  const checkActive = (route) => {
    const location = useLocation();
    const url = location.pathname.split('/')
    if (url.includes(route)) {
      return true;
    }
    return false;
  };
  const searchNews = async () => {
    try {
      const data = {
        page: state.page,
        limit: state.limit,
        search: state.globalSearch,
      };
        const res: any = await globalSearch(data);
        const docs = res.data.docs.map((doc) => {
          if (doc.department) {
            doc.department_name = doc.department.name
          } else if (doc.diocese) {
            doc.diocese_name = doc.diocese.name
          }
          return doc
        })
        setState({ globalNews: res.data.docs });
    } catch (error:any) {
      throwError(
        'Failed to search news',
      );
    }
  };

  useEffect(() => {
    getDioceses()
    getDepartments()
    searchNews()
  }, [state.globalSearch, state.page])
  return (
    <div className="navbar_component" style={{ zIndex: 1 }}>
      <div className="navbar_container" ref={clickRef}>
        <div className="navbar_wrapper">
          <div
            className={`tab_link_container ${state.hover_content === "home" && "border_bottom"} ${state.hover && state.tab_name !== "home" && "tab_hover"}`}
            onMouseLeave={() => handleLeave("home")}
            onMouseEnter={() => handleHover("home")}
          >
            <a href="https://csi1947.com/home" className={checkActive('home') ? 'active nav_link' : 'nav_link'}>
              <div className="tab_name">Home</div>
            </a>
          </div>
          <div
            className={`tab_link_container ${state.hover_content === "diocese" && "border_bottom"} ${state.hover && state.tab_name !== "diocese" && "tab_hover"}`}
            onMouseLeave={() => handleLeave("diocese")}
            onMouseEnter={() => handleHover("diocese")}
          >
            <div className={checkActive('diocese') ? 'active nav_link' : 'nav_link'}>
              <div className="tab_name">Diocese</div>
            </div>
          </div>
          <div
            className={`tab_link_container ${state.hover_content === "department" && "border_bottom"} ${state.hover && state.tab_name !== "department" && "tab_hover"}`}
            onMouseLeave={() => handleLeave("department")}
            onMouseEnter={() => handleHover("department")}
          >
            <div className={checkActive('department') ? 'active nav_link' : 'nav_link'}>
              <div className="tab_name">Department</div>
            </div>
          </div>
          <div
            className={`tab_link_container ${state.hover_content === "about_us" && "border_bottom"} ${state.hover && state.tab_name !== "about_us" && "tab_hover"}`}
            onMouseLeave={() => handleLeave("about_us")}
            onMouseEnter={() => handleHover("about_us")}
          >
            <div className={checkActive('about') ? 'active nav_link' : 'nav_link'}>
              <div className="tab_name">About Us</div>
            </div>
          </div>
          <div
            className={`tab_link_container ${state.hover_content === "resources" && "border_bottom"} ${state.hover && state.tab_name !== "resources" && "tab_hover"}`}
            onMouseLeave={() => handleLeave("resources")}
            onMouseEnter={() => handleHover("resources")}
          >
            <div className={checkActive('resources') ? 'active nav_link' : 'nav_link'}>
              <div className="tab_name">Resources</div>
            </div>
          </div>
          <div
            className={`tab_link_container ${state.hover_content === "others" && "border_bottom"} ${state.hover && state.tab_name !== "others" && "tab_hover"}`}
            onMouseLeave={() => handleLeave("others")}
            onMouseEnter={() => handleHover("others")}
          >
            <div className={checkActive('others') ? 'active nav_link' : 'nav_link'}>
              <div className="tab_name">Others</div>
            </div>
          </div>
        </div>
        {state.hover
        && <div className="onhover_pop">
              {state.pop_up}
           </div>}
      </div>
      <div className={state.searchNews ? 'mobile_navbar_container nav_container_color' : 'mobile_navbar_container'}>
        <div className="mobile_navbar_wrapper">
          {state.hamber === false
       && <div className="mobile_navbar_icon_container">
            {state.searchNews !== true && <img src={Search} alt="hamber_fill_icon" className="mobile_hamber_fill_icon" onClick={() => setState({ searchNews: !state.searchNews, globalSearch: '' })} />}
          {state.searchNews
           && <div className="mobile_search_input_wrapper">
                <input
                  name=""
                  type="text"
                  className="search_input_field"
                  value={state.globalSearch}
                  onChange={(e) => setState({ globalSearch: e.target.value })}
                />
              </div>}
            {state.searchNews !== true && <img src={HamberFill} alt="hamber_fill_icon" className="mobile_hamber_search_fill_icon" onClick={() => setState({ hamber: true, diocese: false, department: false, resources: false, others: false, searchNews: false })} />}
            {state.searchNews === true && <img src={Inactive} alt="hamber_fill_icon" className="mobile_hamber_search_fill_close_icon" onClick={() => setState({ searchNews: false })} />}
          </div>}
        </div>
      </div>
        {state.searchNews
        && <div className="mobile_search_wrapper">
            <div className="mobile_search_text">
              <div className="h3 search_text">Recent News</div>
            </div>
            <div className="mobile_search_content">
              {state.globalNews.length > 0 ? state.globalNews.map((news) => {
              return (
              <Newscard2
                title={news.title}
                description={moment(news.created_at).format(
                  'dddd, MMM D, YYYY',
                )}
                name={news.department_name ? news.department_name : news.diocese_name}
                link={news.thumbnail}
                onClick={() => { window.open(`/news_screen/${news.department_name ? 'departments' : 'dioceses'}/${news._id}`) }}
                type="news_card"
                text={news.heading}
              />)
              }) : <div className="h3 no_news_text">No news found</div>}
              <div className="global_search_button_wrapper">
             <Button
               text="Load more"
               onClick={() => setState({ page: state.page + 1 })}
               className="global_search_button search_button"
             />
              </div>
            </div>
           </div>}
      {
            state.hamber
            && <div className="mobile_navbar_content_wrapper">
                 <div className="mobile_navbar_content_close_icon_wrapper">
                   <img src={Inactive} alt="close_icon" className="mobile_navbar_content_close_icon" onClick={() => setState({ hamber: false })} />
                 </div>
                 <div className="mobile_navbar_content">
                   <div className="mobile_navbar_content_title h2" onClick={() => props.history.push('/home')}>Home</div>
                   <div className="mobile_navbar_content_title h2" onClick={() => setState({ diocese: true, hamber: false, heading: "Diocese", resources: false, department: false, others: false, about: false })}>Dioceses</div>
                   <div className="mobile_navbar_content_title h2" onClick={() => setState({ department: true, hamber: false, heading: "Department", resources: false, diocese: false, others: false, about: false })}>Department</div>
                   <div className="mobile_navbar_content_title h2" onClick={() => setState({ about: true, department: false, hamber: false, heading: "About Us", resources: false, diocese: false, others: false })}>About Us</div>
                   <div className="mobile_navbar_content_title h2" onClick={() => setState({ resources: true, hamber: false, heading: "Resources", diocese: false, department: false, others: false, about: false })}>Resources</div>
                   <div className="mobile_navbar_content_title h2" onClick={() => setState({ others: true, diocese: false, department: false, resources: false, other: false, heading: "Others", hamber: false, about: false })}>Others</div>
                 </div>
               </div>
      }
      {
        (state.diocese || state.department || state.resources || state.others || state.about)
        && <div className="mobile_navbar_csi_synod_wrapper">
              <div className="mobile_navbar_csi_synod_header_wrapper">
                  <div className="mobile_navbar_csi_synod_nav_icon_wrapper" onClick={() => setState({ hamber: true, diocese: false, department: false, others: false, about: false, showDropdown: false, dropdownActive: false })}>
                    <img src={NavIcon} alt="nav_icon" className="csi_synod_nav_icon" />
                  </div>
                  <div className="mobile_navbar_csi_synod_title_wrapper h2">{state.heading}</div>
                  <div className="mobile_navbar_csi_synod_close_button" onClick={() => setState({ hamber: false, diocese: false, department: false, others: false, resources: false, about: false })}>
                    <img src={Inactive} alt="nav_icon" className="csi_synod_close_icon" />
                  </div>
              </div>
              <div className="mobile_navbar_csi_synod_content_wrapper">
                { state.diocese && dioceseState && dioceseState.map((diocese, index) =>
                  <div className="nav_drop_expand">
                    <div
                      className="mobile_navbar_csi_synod_content h2"
                      onClick={() => {
                        getDioceseBasedOnState(diocese)
                        setState({ index })
                      }}
                    >
                      {diocese}
                      <img src={NavIcon} alt="drop" className={state.index === index && state.dropdownActive && state.showDropdown ? 'nav_dropdown_active nav_dropdown' : 'nav_dropdown'} onClick={() => { setState({ dioceseSubContent: [], dioceseContent: '' }) }} />
                    </div>
                      {state.dioceseContent === diocese && state.showDropdown && state.dioceseSubContent.map((d) => <div className="mobile_navbar_csi_synod_sub_content h5" onClick={() => window.open(`//${d.sub_domain}.csi1947.com`)}>{d.name}</div>)}
                  </div>
                )}
                { state.department && departments && departments.map((department, index) =>
                  <div>
                    <div className="mobile_navbar_csi_synod_department_content h2" onClick={() => window.open(`//${department.sub_domain}.csi1947.com`)}>{department.name}</div>
                  </div>
                )}
                { state.about && aboutUs && aboutUs.map((aboutus, index) =>
                  <div>
                    <div
                      className="mobile_navbar_csi_synod_content h2"
                      onClick={() => {
                        getSubContent(aboutus)
                        setState({ index, showDropdown: !state.showDropdown })
                      }}
                    >{aboutus}
                    <img src={NavIcon} alt="drop" className={state.index === index && state.dropdownActive && state.showDropdown ? 'nav_dropdown_active nav_dropdown' : 'nav_dropdown'} onClick={() => { setState({ dioceseSubContent: [], dioceseContent: '' }) }} />
                    </div>
                      {state.aboutContent === aboutus && state.showDropdown && state.subContent.map((m) => <div className="mobile_navbar_csi_synod_about_sub_content h5" onClick={() => props.history.push(`${m.url}`)}>{m.name}</div>)}
                  </div>
                )}
                { state.resources && resources && resources.map((resource, index) =>
                  <div>
                    <div className="mobile_navbar_csi_synod_resources_content h2" onClick={() => props.history.push(`${resource.url}`)}>{resource.name}</div>
                  </div>
                )}
                { state.others && others && others.map((other, index) =>
                  <div>
                    <div className="mobile_navbar_csi_synod_others_content h2" onClick={() => props.history.push(`${other.url}`)}>{other.name}</div>
                  </div>
                )}
              </div>
           </div>
      }
    </div>
  )
}
