import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_OFFICERS } from "utils/types.utils";
import _ from "lodash";
import Button from "common_components/ui/button/button.ui";
import Spinner from "common_components/ui/spinner/spinner.ui";
import SearchBar from "common_components/ui/search_bar/search_bar.ui";
import { getAllUser, addUser, getUserById, editUser, deleteUser } from "models/user_management.model";
import { AboutCsiNavData, officersTableHeading, officersBody, csiUserSuggessionData } from "helper/constants.helper";
import PlusIcon from "assets/icons/Plus_White.svg";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import Table from "components/table/table.component";
import EditIcon from "assets/icons/Edit.svg";
import DeleteIcon from "assets/icons/Delete.svg";
import CustomNavBar from "../../components/custom_nav/custom_nav.component";
import "screens/aboutCSI/officers.screen.scss";
import { useSetState } from "../../utils/common.utils";

export default function Officers(props) {
  const dispatch = useDispatch();
  const officers = useSelector((state: any) => state.aboutCsi.office);
  const [state, setState] = useSetState({
    selectedData: "officers",
    edit: false,
    openModal: false,
    active: false,
    checkedList: [],
    isChecked: false,
    confirmModal: false,
    searchValue: "",
    limit: 20,
    search: '',
    page: 1,
    officerId: "",
    loading: true,
    uploaded: false,
  });

  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = state.tableData.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleGetAllOfficers = async ({ limit, page, search }) => {
    try {
      const data = {
        limit,
        search,
        user_sort: true,
        page,
        type: "officers"
      }
      const officer: any = await getAllUser(data);
      if (officer) {
        const doc = officer.data.docs.map((docs) => {
          if (docs.phone && docs.fax) {
          docs.phone_fax = `${docs.phone}/${docs.fax}`
          docs.author = docs.created_by.user_name
          } else if (docs.phone || docs.fax) {
            docs.phone_fax = `${docs.phone}${docs.fax}`
            docs.author = docs.created_by.user_name
          } else {
            docs.phone_fax = ''
            docs.author = docs.created_by.user_name
          }
          return docs;
        });
        officer.data.docs = doc;
        dispatch({
          type: GET_OFFICERS,
          payload: officer.data
        })
        setState({ loading: false });
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data : "Failed to get Officers");
    }
  }

  useEffect(() => {
    handleGetAllOfficers(state);
  }, [])
  useEffect(() => {
    handleGetAllOfficers(state);
  }, [state.search])

  const handleEditOfficers = (id) => {
    props.history.push(`/about_csi/addofficers/${id}`);
  }

  const handleDeleteOfficers = (id) => {
    setState({ confirmModal: true, officerId: id })
  }

  const handleAddOfficers = () => {
    setState({ openModal: false })
  }

  const loadMore = async ({ page, limit, search }) => {
    try {
      const data = {
        limit,
        search,
        user_sort: true,
        page,
        type: "officers"
      }
      const officer: any = await getAllUser(data);
      if (officer) {
        const doc = officer.data.docs.map((docs) => {
          if (docs.phone && docs.fax) {
          docs.phone_fax = `${docs.phone}/${docs.fax}`
          docs.author = docs.created_by.user_name
          } else if (docs.phone || docs.fax) {
            docs.phone_fax = `${docs.phone}${docs.fax}`
            docs.author = docs.created_by.user_name
          } else {
            docs.phone_fax = `${docs.phone}/${docs.fax}`
            docs.author = docs.created_by.user_name
          }
          return docs;
        });
        officer.data.docs = doc;
        officer.data.docs = [...officers.docs, ...officer.data.docs];
        dispatch({
          type: GET_OFFICERS,
          payload: officer.data
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get Officers");
    }
  }

  const searchChange = () => {

  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEditOfficers,
      icon: EditIcon
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleDeleteOfficers,
      icon: DeleteIcon
    }
  ];

  const handleOnChangeInSearch = (e) => {
    setState({ search: e.target.value })
    // try {
    //   const data = {
    //     limit: state.limit,
    //     search: e.target.value,
    //     page: state.page,
    //     type: "officers"
    //   }
    //   const officer: any = await getAllUser(data);
    //   if (officer) {
    //     const doc = officer.data.docs.map((docs) => {
    //       docs.phone_fax = `${docs.phone}/${docs.fax}`
    //       return docs;
    //     });
    //     officer.data.docs = doc;
    //     dispatch({
    //       type: GET_OFFICERS,
    //       payload: officer.data
    //     })
    //     setState({ loading: false });
    //   }
    // } catch (err:any) {
    //   props.throwError("Failed to search Officers");
    // }
  }

  const handleOnDeleteOfficer = async () => {
    try {
      const officer: any = await deleteUser(state.officerId);
      await handleGetAllOfficers({
        page: 1, limit: state.page * state.limit, search: state.search
      });
      setState({ confirmModal: false })
    } catch (err:any) {
      props.throwError("Failed to get Officers");
    }
  }
  const loadMoreInitial = () => {
    setState({ page: state.page + 1 });
    loadMore({
      page: state.page + 1,
      limit: state.limit,
      search: state.search
    });
  };
  return (
    <div className="officers_screen">
      <div className="officers_container">
        <div className="officers_wrapper">
          <div className="officers_header_wrapper">
            <div className="officers_name_wrapper h2">
              Administration
            </div>
            <div className="officers_action_wrapper">
              <div className="officers_search_box_wrapper">
                <SearchBar suggession="" placeholder="Search..." type="secondary" suggessionData={csiUserSuggessionData} onChange={handleOnChangeInSearch} />
              </div>
              <div className="officers_button_wrapper">
                <Button className="officer_navbar_button" type="primary" text="Add Officers" onClick={() => props.history.push('/about_csi/addofficers', { uploaded: false })} icon={PlusIcon} />
              </div>
            </div>
          </div>
          <div className="officers_custom_navbar_wrapper">
            <CustomNavBar
              data={AboutCsiNavData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add User"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search users here..."
              suggession={csiUserSuggessionData}
              buttonOnClick={handleAddOfficers}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="officers_table-wrapper">
            {
              state.loading ? <Spinner />
                : <Table
                    header={officersTableHeading}
                    body={officers ? officers.docs : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    checkedData={state.checkedList}
                    loadMore={loadMoreInitial}
                    actionButton={actionArray}
                    hasMore={officers && officers.hasNextPage ? officers.hasNextPage : ''}
                    error="Officers is not found"
                />
            }
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete Officers?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteOfficer} />
        </div>
      </div>
    </div>
  )
}
