import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import { GET_DEPARTMENT_NEWS, THROW_ERROR, GET_DEPARTMENT, GET_DEPARTMENT_NEWS_DETAILS } from 'utils/types.utils';
import { aimsAndObjectivesTableHeading, aimsAndObjectivesBody, csiUserSuggessionData } from "helper/constants.helper";
import ConfirmModal from "components/confirm_modal/confirm_modal.component";
import Spinner from "common_components/ui/spinner/spinner.ui";
import { getDepartmentById, addDepartment, deleteDepartment, editDepartment, getAllDepartment } from "models/department.model";
import CustomNavBar from "components/custom_nav/custom_nav.component";
import TextArea from "components/text_area/text_area.component";
import { useSetState } from "utils/common.utils";
import _ from "lodash";
import Button from "common_components/ui/button/button.ui";
import Table from "components/table/table.component";
import PlusIcon from "../../assets/icons/Plus_White.svg";
import Delete from "../../assets/icons/Delete.svg";
import Edit from "../../assets/icons/Edit.svg";
import Arrowleft from "../../assets/icons/Arrow.svg";
import "screens/department/department_view.screen.scss";

export default function DepartmentView(props) {
  const dispatch = useDispatch();
  const [state, setState] = useSetState(
    {
      selectedData: "aims_and_objectives",
      edit: false,
      openModal: false,
      checkedList: [],
      isChecked: false,
      added: false,
      confirmModal: false,
      departmentData: [],
      departmentName: "",
      id: "",
      active: false,
      searchValue: "",
      description: "",
      aim: [],
      limit: 20,
      page: 1,
      loading: true
    });
  const { match, location } = props;

  const handleGetDepartment = async () => {
    try {
      const department: any = await getDepartmentById(match.params.id);
      if (department !== undefined
        && department.data !== undefined) {
        department.data.aim = department.data.aim !== undefined ? department.data.aim.replace(/(<([^>]+)>)/gi, "") : '';
        setState({ departmentName: department.data.name, aim: [department.data], loading: false });
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get Aims and Objectives");
    }
  }

  const handleGetAllDepartment = async () => {
    try {
      const data = {
        limit: state.limit,
        page: state.page,
      };
      const department: any = await getAllDepartment(data);
      if (department !== undefined
        && department.data !== undefined && department.data.docs !== undefined) {
        dispatch({
          type: GET_DEPARTMENT,
          payload: department.data
        })
      }
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get Departments");
    }
  }

  const handleAddAimAndObjectives = async () => {
    try {
      const data = {
        aim: state.description
      };
      const aim = await editDepartment(match.params.id, data);
      await handleGetDepartment();
      setState({ openModal: false, added: true })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to add Aims and Objectives");
    }
  }

  useEffect(() => {
    handleGetAllDepartment();
    handleGetDepartment();
  }, []);
  const handleChangeClick = (value) => {
    setState({ selectedData: value })
  };

  const departmentViewData = [{
    navName: "Aims and Objectives",
    navValue: "aims_and_objectives",
    navUrl: `/departments/department_information/aimsandobjectives/${match.params.id}`
  },
  {
    navName: "Personnel",
    navValue: "personnel",
    navUrl: `/departments/department_information/personnels/${match.params.id}`
  }];

  const handleOnClick = (edit) => {
    if (!edit) {
      setState({ openModal: true });
    } else {
      setState({ openModal: true });
      setState({ edit: true });
    }
  }

  const checkAll = () => {
    if (state.isChecked) {
      setState({ isChecked: false, checkedList: [] });
    } else {
      const checkData = aimsAndObjectivesBody.map((ele) => { return ele._id });
      setState({ isChecked: true, checkedList: checkData });
    }
  }

  const checkOne = (id) => {
    if (state.checkedList.indexOf(id) === -1) {
      const mergeData = [...state.checkedList, ...[id]];
      if (state.tableData.length === mergeData.length) {
        setState({ checkedList: mergeData, isChecked: true });
      } else {
        setState({ checkedList: mergeData, isChecked: false });
      }
    } else {
      const checkData = _.pull(state.checkedList, id);
      setState({ checkedList: checkData, isChecked: false });
    }
  }

  const handleEditUser = () => {
    // Api calls
  }

  const handleDeleteUser = () => {
    // Api calls
  }

  const handleAddAimsAndObjectives = () => {
    // Api calls
  }

  const loadMore = () => {
    // ApiCall
  }

  const searchChange = () => { }

  const handleEdit = async () => {
    try {
      setState({ openModal: true, edit: true });
      const aim: any = await getDepartmentById(match.params.id);
      await handleGetDepartment();
        setState({ description: aim.data.aim !== undefined ? aim.data.aim : '', added: true });
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to get Aims and Objectives");
    }
  }

  const handleConfirm = (id) => {
    setState({ confirmModal: true, id })
  }

  const actionArray = [
    {
      type: "secondary",
      text: "Edit",
      onClick: handleEdit,
      icon: Edit
    },
    {
      type: "secondary",
      text: "Delete",
      onClick: handleConfirm,
      icon: Delete
    }
  ]

  const handleOnDeleteView = async () => {
    try {
      const data = {
        aim: ""
      };
      const aim = await editDepartment(match.params.id, data);
      await handleGetDepartment();
      setState({ confirmModal: false, added: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete Aims and Objectives");
    }
  }

  const handleOnChange = (value) => {
    setState({ description: value });
  }

  const handleAddAims = () => {
    setState({ openModal: false })
  }

  const handleUpdateAims = async () => {
    try {
      const data = {
        aim: state.description
      };
      const aim = await editDepartment(match.params.id, data);
      const datas = {
        limit: state.limit,
        page: state.page,
      };
      await handleGetDepartment();
      setState({ openModal: false, added: false })
    } catch (err:any) {
      props.throwError(err.data ? err.data.desc : "Failed to delete Aims and Objectives");
    }
    setState({ openModal: false });
  }

  return (
    <div className="department_view_screen">
      <div className="department_view_container">
        <div className="department_view_wrapper">
          <div className="department_view_header_wrapper">
            <div className="department_view_navigate_icon_wrapper">
              <div className="department_view_navigate_icon">
                <img className="navigate_icon" alt="" src={Arrowleft} onClick={() => props.history.push('/departments/department_information')} />
              </div>
            </div>
            <div className="department_view_header_name_container">
              <div className="department_view_header_name h3">
                {state.departmentName !== undefined ? state.departmentName : "Malabar"}
              </div>
              <div className="department_view_header_button_wrapper">
                {/* {state.added
                ? <Button
                    text="Add Aim and Objectives"
                    icon={PlusIcon}
                    type="primary"
                    onClick={() => setState({ openModal: true, edit: false, description: "" })}
                />
                : ''} */}
              </div>
            </div>
          </div>
          <div className="department_view_navbar_wrapper">
            <CustomNavBar
              data={departmentViewData}
              onTapClick={handleChangeClick}
              selectedItem={state.selectedData}
              buttonText="Add Aim and Objectives"
              buttonType="primary"
              buttonIcon={PlusIcon}
              searchText="Search Text"
              suggession={csiUserSuggessionData}
              buttonOnClick={handleOnClick}
              button={false}
              search={false}
              onSearchChange={searchChange}
            />
          </div>
          <div className="department_view_table_wrapper">
            {
              state.loading === false
                ? <Table
                    header={aimsAndObjectivesTableHeading}
                    body={state.aim ? state.aim : []}
                    isChecked={state.isChecked}
                    checkAll={checkAll}
                    checkOne={checkOne}
                    checkedData={state.checkedList}
                    loadMore={loadMore}
                    actionButton={actionArray}
                    error="No Aims and Objectives is found"
                /> : <Spinner />
            }
          </div>
          <div className="department_view_modal_container">
            <Modal
              open={state.openModal}
              onClose={() => setState({ openModal: false })}
              center
              classNames={{
                overlay: 'customOverlay', modal: 'department_view_customModal', closeButton: "close_button"
              }}
              showCloseIcon
            >
              <div className="department_view_modal_title_wrapper">
                <div className="department_view_modal_title h4">{state.edit !== true ? "Add Aim and Objective" : "Edit Aim and Objective"}</div>
              </div>
              <div className="department_view_modal_line_wrapper"> </div>
              <div className="department_view_modal_text_area_wrapper">
                <TextArea text={state.description} onChange={handleOnChange} />
              </div>
              <div className="department_view_button_wrapper">
                <div className="department_view_button">
                  <Button text={state.edit ? "Update" : "Add"} type="primary" onClick={state.edit ? handleUpdateAims : handleAddAimAndObjectives} />
                </div>
              </div>
            </Modal>
          </div>
          <ConfirmModal openModal={state.confirmModal} description="Are you sure want to delete Aims and Objectives ?" closeModal={() => setState({ confirmModal: false })} deleteConfirm={handleOnDeleteView} />
        </div>
      </div>
    </div>
  )
}
